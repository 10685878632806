import styles from "./index.module.css";
import React from "react";

import Loader from "shared/Loader";

export default function () {
  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <Loader color="#fff" />
      </div>
      <p className={styles.label}>Checking Login Status</p>
    </div>
  );
}
