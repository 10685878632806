import React from "react";
import { Store } from "react-notifications-component";

import Notification from "shared/Notification";

/**
 * @typedef NotificationOptions
 * @type {Object}
 * @property {string} type - Asdf
 * @property {string} title - Asdf
 * @property {string} message - Asdf
 * @property {boolean} autoDismiss - Asdf
 */

/**
 * Create a notification in the bottom right of the screen.
 *
 * @param {NotificationOptions} options - An object with notification options.
 */
export default function createNotification(options) {
  const { type, title, message, autoDismiss = true } = options;

  // Content component is defined in the scope of this function because a 'content' component
  // only receives the ID, which cannot be used to retrieve the content from the notification store.
  function Content({ id }) {
    return (
      <Notification
        type={type}
        title={title}
        message={message}
        dismiss={() => {
          Store.removeNotification(id);
        }}
      />
    );
  }

  Store.addNotification({
    type,
    title,
    message,
    container: "bottom-right",
    content: Content,
    dismiss: autoDismiss
      ? {
          duration: 5000,
          onScreen: false,
          pauseOnHover: true,
          showIcon: true,
        }
      : null,
    slidingEnter: {
      duration: 200,
      timingFunction: "ease-in-out",
    },
    slidingExit: {
      duration: 200,
      timingFunction: "ease-in-out",
    },
  });
}
