import React from "react";
import { Button } from "../../../shared/Button";
import PropTypes from "prop-types";

import styles from "./InviteesListItem.module.css";
import { Link } from "react-router-dom";

/**
 * Interactive component that displays the information about the invitee. If the attribution type is manual then a remove btn will appear to the right.
 */
export function InviteesListItem({ invitee, onRemove }) {
  return (
    <>
      <div className={styles.container}>
        <div className={(styles.item, styles.orderId)}>
          <Link to="/events/view/events">{invitee.orderID}</Link>
        </div>
        <div className={styles.item}>{invitee.name}</div>
        <div className={styles.item}>{invitee.email}</div>
        <div className={styles.item}>{invitee.eventName}</div>
        <div className={styles.item}>{invitee.purchaseDate}</div>
        <div className={styles.item}>{invitee.attribution}</div>
        <div className={styles.item}>
          {invitee.attribution === "Manual" ? (
            <Button
              className={styles.removeBtn}
              size="small"
              children="Remove"
              onClick={() => onRemove(invitee)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

InviteesListItem.propTypes = {
  /**
   * Object to display their info (orderID-String, name-String, email-String, eventName-String, purchaseDate-String, attribution-String)
   */
  invitee: PropTypes.shape({
    orderID: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    eventName: PropTypes.string,
    purchaseDate: PropTypes.string,
    attribution: PropTypes.string,
  }),
  /**
   * Function called to remove the invitee
   */
  onRemove: PropTypes.func,
};
