import { Auth } from "shared/@auth/Auth";
import { useMutation, useQuery } from "react-query";
// import toast from "react-hot-toast";
// import createNotification from "shared/@utils/createNotification";

// export function useOrderInfo(iboNum, eventId) {
//   const { makeAuthedRequest } = Auth.useContainer();

//   const { data, isLoading, refetch } = useQuery(
//     // Query Key for invalidating data
//     ["orderInfo"],
//     () =>
//       makeAuthedRequest(
//         `v1/events/order/info/ibo/${iboNum}/eventId/${eventId}`,
//         {
//           method: "GET",
//         }
//       ).then(async (res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           const body = await res.json();
//           throw new Error(body.message);
//         }
//       }),
//     { staleTime: 0 }
//   );

//   return {
//     data,
//     isLoading,
//     refetch,
//   };
// }

//Scaffolding for OrderInfoGet
export function useOrderInfo(iboNum, eventId) {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, isLoading, refetch } = useQuery(
    // Query Key for invalidating data
    ["orderInfo"],
    () =>
      makeAuthedRequest(
        `v1/events/order/info/ibo/${iboNum}/eventId/${eventId}`,
        {
          method: "GET",
        }
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  return {
    data,
    isLoading,
    refetch,
  };
}

//Scaffolding for OrderAddToCartPost
// export function useAddToCart() {
//   const { makeAuthedRequest } = Auth.useContainer();

//   const addIncident = useMutation((incident) =>
//     makeAuthedRequest(`/security/incidents`, {
//       method: "POST",
//       body: JSON.stringify(incident),
//     }).then(async (res) => {
//       if (res.ok) {
//         return res;
//       } else {
//         const body = await res.json();
//         throw new Error(body.error);
//       }
//     })
//   );

//   const { mutate } = useMutation(addIncident, {
//     onSuccess: () => {
//       createNotification({
//         type: "success",
//         title: "Saved",
//         message: "Tickets Succefully Added to IBO Cart.",
//         autoDismiss: true,
//       });
//     },
//     onError: (err) => {
//       console.error(err);
//       toast.error("Something went wrong!");
//     },
//   });

//   return { addIncident, mutate };
// }
