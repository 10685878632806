import styles from "./index.module.css";
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import { ChevronDown } from "react-ikonate";
import { useClickAway } from "react-use";
import { useLogger } from "shared/@hooks/useLogger";

import { useResizeEnd } from "shared/@hooks/useResizeEnd";

import PropTypes from "prop-types";

/**
 * A tabbed content box to display multiple pieces of info in the same place.
 * It works basically like an HTML `<select>` where the top level has a `value`,
 * the children have `value`s, and the main `value` decides which option is chosen.
 *
 * Optionally, takes an `onTabChange` function that is called with the new `value`
 * whenever the tab selection changes.
 *
 * @example
 * import { TabContainer, Tab } from "shared/TabContainer";
 *
 * function MyComponent() {
 *   return (
 *     <TabContainer>
 *       <Tab title="First Tab" value="tab-one">
 *         <p>This is tab one</p>
 *       </Tab>
 *       <Tab title="Second Tab" value="tab-two">
 *         <p>This is tab one</p>
 *       </Tab>
 *     </TabContainer>
 *   );
 * }
 */
export function TabContainer({
  value,
  onTabChange,
  minHeight,
  className,
  ...props
}) {
  const [selected, setSelected] = useState(value);
  const [requestId, setRequestId] = useState(0);
  const { logTrace } = useLogger({ prefix: "TabContainer" });

  // Make sure children is an array with only truthy items.
  const children = useMemo(
    () =>
      Array.isArray(props.children) ? props.children.filter((x) => !!x) : [],
    [props.children]
  );

  // Update tab selection when it changes externally.
  useEffect(() => {
    setSelected(value || (children[0] && children[0].props.value));
  }, [value]);
  const refresh = useCallback(() => {
    setRequestId(requestId + 1);
  }, [requestId]);
  const selectedTab = useMemo(
    () => children.find((c) => c.props.value === selected),
    [children, selected]
  );

  const [lastVisibleIndex, setLastVisibleIndex] = useState(children.length - 1);
  const tabContainer = useRef(null);
  const tabScroll = useRef(null);

  const calcOverflow = useCallback(() => {
    if (tabContainer.current && tabScroll.current) {
      const containerRect = tabContainer.current.getBoundingClientRect();
      const elements = tabScroll.current.children;
      const overflow = containerRect.width + containerRect.left - 120;

      let lastVisible = lastVisibleIndex || children.length - 1;

      console.log("Width", containerRect.width);

      for (let i = 0; i < elements.length; i++) {
        const rect = elements[i].getBoundingClientRect();

        if (rect.right >= overflow) {
          logTrace(
            `hit overflow (${overflow}px) with tab's right edge (${rect.right}px) at index ${i}`
          );

          lastVisible = i - 1;

          break;
        } else {
          lastVisible = children.length;
        }
      }

      setLastVisibleIndex(lastVisible);
      refresh();
    }
  }, [tabScroll, tabContainer, children, selected, lastVisibleIndex]);

  const win = useRef(window);
  useResizeEnd(win, () => {
    setLastVisibleIndex(children.length - 1);
    calcOverflow();
  });

  useEffect(() => {
    calcOverflow();
  }, []);

  // Figure out which tabs are visible and which clip off the right edge.
  // useLayoutEffect(calcOverflow, [children, selected]);

  // Takes the tab to be selected and returns the function
  // to be called by the tab's onClick event to make the change.
  const setTab = useCallback(
    (tab) => {
      return () => {
        setSelected(tab);
        if (onTabChange) onTabChange(tab);
      };
    },
    [onTabChange]
  );

  return (
    <div className={classNames(styles.container, className)}>
      <div ref={tabContainer} className={styles.tabContainer}>
        <div ref={tabScroll} className={styles.tabScroll}>
          {children.map(
            ({ props }, i) =>
              i <= lastVisibleIndex && (
                <TabSelector
                  key={props.value}
                  title={props.title}
                  selected={selected === props.value}
                  badge={props.badge}
                  onClick={setTab(props.value)}
                />
              )
          )}
        </div>

        {lastVisibleIndex < children.length - 1 && (
          <OverflowMenu
            items={children.slice(lastVisibleIndex + 1)}
            selected={selected}
            onSelected={(value) => {
              setTab(value)();
            }}
          />
        )}
      </div>

      <div className={styles.content} style={{ minHeight }}>
        {selectedTab}
      </div>
    </div>
  );
}

function OverflowMenu({ items, selected, onSelected }) {
  const [isOpen, setIsOpen] = useState(false);

  const container = useRef(null);
  useClickAway(container, () => {
    setIsOpen(false);
  });

  return (
    <div ref={container} className={styles.overflow}>
      <button
        className={styles.overflowButton}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        +{items.length} more
        <div className={styles.overflowIcon}>
          <ChevronDown />
        </div>
      </button>

      {isOpen && (
        <div className={styles.overflowMenu}>
          {items.map(({ props }) => (
            <button
              key={props.value}
              className={classNames(styles.overflowItem, {
                [styles.active]: selected === props.value,
              })}
              onClick={() => {
                onSelected(props.value);
                setIsOpen(false);
              }}
            >
              <span className={styles.overflowItemText}>{props.title}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

/**
 * Defines a tab and its contents. Takes a tab `title`, a `value`,
 * a `badge` boolean and the tab's contents as children.
 *
 * This looks a bit strange because most of the props are not used in
 * the component body itself, but read by the TabContainer to create
 * tab selectors based on this info.
 *
 * @example
 * <Tab title="A Tab" value="tab-1" badge={true}>
 *   <p>You see me when this tab is selected</p>
 * </Tab>
 */
export function Tab({ title, value, badge, children }) {
  return children;
}

function TabSelector({ title, selected, badge, onClick }) {
  return (
    <div className={classNames(styles.tab, { [styles.selected]: selected })}>
      <button
        className={styles.tabButton}
        onClick={onClick}
        data-testid={title}
      >
        {badge && <span className={styles.tabBadge} />}
        <span className={styles.tabTitle}>{title}</span>
      </button>
      <div className={styles.tabMask} />
    </div>
  );
}

TabContainer.propTypes = {
  value: PropTypes.string,
  onTabChange: PropTypes.func,
  minHeight: PropTypes.string,
  className: PropTypes.string,
};

Tab.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  badge: PropTypes.string,
};
