import styles from "./index.module.css";
import React from "react";
import classNames from "classnames";

export function Section({ className, children }) {
  return (
    <section className={classNames(styles.section, className)}>
      {children}
    </section>
  );
}

export function Row({ className, children }) {
  return <div className={classNames(styles.row, className)}>{children}</div>;
}

export function Column({ className, width, children, style = {} }) {
  return (
    <div
      className={classNames(styles.column, className)}
      style={{ flexBasis: width, ...style }}
    >
      {children}
    </div>
  );
}

export function LabelGroup({ title, children }) {
  return (
    <div className={styles.labelGroup}>
      <h4 className={styles.labelGroupTitle}>{title}</h4>
      {children}
    </div>
  );
}
