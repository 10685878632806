import React, { useState } from "react";
import Card from "shared/Card";
import InfoItem from "Events/Components/InfoItem";
import Button from "shared/Button";
import classNames from "classnames";
import PropTypes from "prop-types";

//import cardStyles from "../../V1Components/../V1ComponentsCardStyles.module.css";
import styles from "./SessionsInfoCard.module.css";

/**
 * Interactive component that displays the sessions info card, and allows for editing and changing of information
 */
export function SessionsInfoCard({
  onClick,
  sessions,
  onEdit,
  onManageInvitees,
}) {
  // const [localSessions, changeLocalSessions] = useState(sessions);
  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.cardHeader}>SESSIONS</div>
          {/* <Button
            className={classNames(styles.addBtn, cardStyles.cardDarkButton)}
            size="small"
            children="Add"
            // onClick={() => onClick(localSessions, changeLocalSessions)}
            onClick={() => onClick(sessions)}
          /> */}
        </div>

        <div className={styles.hr}></div>
        {/* {localSessions.map((session, index) => ( */}
        {sessions.map((session, index) => (
          <div key={index}>
            <InfoItem label="Visibility" description={session.visibility} />
            {session.visibility === "Invite Only" ? (
              <>
                <div className={styles.buttonContainer}>
                  {/* <Button
                    className={classNames(
                      styles.editInviteBtn,
                      cardStyles.cardLightButton
                    )}
                    style={{ float: "right" }}
                    size="small"
                    children="Edit"
                    onClick={() => onEdit(session)}
                  /> */}
                  <Button
                    className={styles.manageBtn}
                    children="Manage Invitees"
                    onClick={() => onManageInvitees(session)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles.publicContainer}>
                  {/* <Button
                    className={classNames(
                      styles.editPublicBtn,
                      cardStyles.cardLightButton
                    )}
                    style={{ float: "right" }}
                    size="small"
                    children="Edit"
                    onClick={() => onEdit(session)}
                  /> */}
                </div>
              </>
            )}
            <InfoItem label="Session Name" description={session.name} />
            <InfoItem label="Description" description={session.description} />
            <InfoItem label="Speaker Name" description={session.speaker} />
            <InfoItem label="Location" description={session.location} />
            <InfoItem label="Capacity" description={session.capacity} />
            <InfoItem label="Start Date" description={session.startDate} />
            {/* {session === localSessions[localSessions.length - 1] ? ( */}
            {session === sessions[sessions.length - 1] ? (
              ""
            ) : (
              <div className={styles.hr}></div>
            )}
          </div>
        ))}
      </div>
    </>
  );

  return <Card children={cardContents} />;
}

SessionsInfoCard.propType = {
  /**
   * Function called when the add btn is clicked
   */
  onClick: PropTypes.func,
  /**
   * An array of objects containing each session to be displayed
   * visibility - string, name - string, description - string, speaker - string, location - string, capacity  - string, startDate  - string
   */
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      visibility: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      speaker: PropTypes.string,
      location: PropTypes.string,
      capacity: PropTypes.string,
      startDate: PropTypes.string,
    })
  ),
  /**
   * Function called when one of the edit buttons is clicked for a session
   */
  onEdit: PropTypes.func,
  /**
   * Function called when manage invitees get clicked for a session
   */
  onManageInvitees: PropTypes.func,
};
