import styles from "./PaginationControls.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "../Button";

/**
 * Interactive component that sets up pagination for the page
 */
export function PaginationControls({
  page,
  lastPage,
  onPageChange,
  disableNext,
  disabled,
  color,
}) {
  const pages = [];

  const pagesShown = 3;
  const halfPagesShown = parseInt(pagesShown / 2);
  if (page == 1) {
    for (let i = 1; i <= pagesShown && i <= lastPage; i++) {
      pages.push(i);
    }
  } else if (page < lastPage) {
    for (
      let i = page - halfPagesShown;
      i <= page + halfPagesShown && i <= lastPage;
      i++
    ) {
      if (i >= 1) {
        pages.push(i);
      }
    }
  } else {
    for (let i = page - pagesShown + 1; i <= lastPage; i++) {
      if (i >= 1) {
        pages.push(i);
      }
    }
  }

  return (
    <>
      <div className={styles.paginationContainer}>
        <Button
          disabled={disabled || page <= 1}
          children="Previous"
          className={styles.previousButton}
          style={{ color: color }}
          type="button"
          onClick={() => onPageChange(page - 1)}
        />
        {pages.map((i, index) => (
          <div key={index}>
            <Button
              disabled={disabled}
              children={i}
              className={classNames(
                styles.pageButton,
                page === i ? styles.active : null,
                i == pages[0] && styles.page1
              )}
              style={{ color: color }}
              onClick={() => onPageChange(i)}
            />
          </div>
        ))}
        <Button
          disabled={disabled || disableNext}
          children="Next"
          type="button"
          className={classNames(
            styles.nextButton,
            disableNext ? styles.disableNext : null
          )}
          style={{ color: color }}
          onClick={() => onPageChange(page + 1)}
        />
      </div>
    </>
  );
}

PaginationControls.propTypes = {
  /**
   * Controls current page
   */
  page: PropTypes.number,
  /**
   * Displays the total amount of pages
   */
  lastPage: PropTypes.number,
  /**
   * Function that updates the page
   */
  onPageChange: PropTypes.func,
  /**
   * Disables the next button if true
   */
  disableNext: PropTypes.bool,
  /**
   * Disables all controls if true
   */
  disabled: PropTypes.bool,
};
