import { useLocalStorage } from "react-use";
import { createContainer } from "unstated-next";

function usePageNavState() {
  const [collapsed, setCollapsed] = useLocalStorage("page-nav-collapse", false);

  return { collapsed, setCollapsed };
}

export const PageNavState = createContainer(usePageNavState);
