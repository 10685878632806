import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Modal } from "../../../shared/Modal";
import InputItem from "Events/Components/InputItem";
import Input from "shared/@forms/Input";
import { Select } from "shared/Select/Select";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import Button from "shared/Button";
import Checkbox from "shared/@forms/Checkbox";

import styles from "./EditTicketModal.module.css";

export function EditTicketModal({ isOpen, onCollapse, onClick, ticketInfo }) {
  const [formData, setFormData] = useState(ticketInfo || {});

  // const [startZone, setStartZone] = useState(
  //   ticketInfo?.startZone || ticketInfo?.timeZone
  // );
  // const [endZone, setEndZone] = useState(
  //   ticketInfo?.startZone || ticketInfo?.timeZone
  // );

  const timeZones = ["PST", "MST", "MDT", "CST", "CDT"];

  const [errors, setErrors] = useState({
    name: "",
    purchaseBegin: {
      period: "",
      time: "",
    },
    purchaseEnd: {
      period: "",
      time: "",
    },
  });

  const didMount = useRef(false);

  useEffect(() => {
    if (ticketInfo) {
      setFormData({
        id: ticketInfo.id,
        price: ticketInfo.price,
        name: ticketInfo.name,
        startDate: ticketInfo.startDate,
        endDate: ticketInfo.endDate,
        startTime: convertTo24Hour(ticketInfo.startTime),
        endTime: convertTo24Hour(ticketInfo.endTime),
        timeZone: ticketInfo.timeZone,
        // startZone: ticketInfo.startZone,
        // endZone: ticketInfo.endZone,
      });
    }
  }, [ticketInfo]);

  const handleFormSubmit = () => {
    let tempErrors = {};
    if (formData.name.length === 0) {
      tempErrors = { ...tempErrors, name: "Please enter a name" };
    } else {
      tempErrors = { ...tempErrors, name: "" };
    }

    // if (formData.purchaseBegin.period.length === 0) {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseBegin: {
    //       ...tempErrors.purchaseBegin,
    //       period: "Please enter a beginning time period",
    //     },
    //   };
    // } else {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseBegin: {
    //       ...tempErrors.purchaseBegin,
    //       period: "",
    //     },
    //   };
    // }

    // if (formData.purchaseBegin.time.length === 0) {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseBegin: {
    //       ...tempErrors.purchaseBegin,
    //       time: "Please enter a beginning time",
    //     },
    //   };
    // } else {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseBegin: {
    //       ...tempErrors.purchaseBegin,
    //       time: "",
    //     },
    //   };
    // }

    // if (formData.purchaseEnd.period.length === 0) {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseEnd: {
    //       ...tempErrors.purchaseEnd,
    //       period: "Please enter an ending time period",
    //     },
    //   };
    // } else {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseEnd: {
    //       ...tempErrors.purchaseEnd,
    //       period: "",
    //     },
    //   };
    // }

    // if (formData.purchaseEnd.time.length === 0) {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseEnd: {
    //       ...tempErrors.purchaseEnd,
    //       time: "Please enter an ending time period",
    //     },
    //   };
    // } else {
    //   tempErrors = {
    //     ...tempErrors,
    //     purchaseEnd: {
    //       ...tempErrors.purchaseEnd,
    //       time: "",
    //     },
    //   };
    // }
    // setErrors(tempErrors);
    if (
      objectIsEmpty(tempErrors.purchaseBegin) &&
      objectIsEmpty(tempErrors.purchaseEnd) &&
      tempErrors.name === ""
    ) {
      onClick(formData);
    }
  };

  const objectIsEmpty = (obj) =>
    Object.values(obj).every((value) => value === "");

  /* 
  useEffect(() => {
    if (didMount.current) {
      if (
        objectIsEmpty(errors.purchaseBegin) &&
        objectIsEmpty(errors.purchaseEnd) &&
        errors.name === ""
      ) {
        onClick(formData);
      }
    } else {
      didMount.current = true;
    }
  }, [errors]); */

  // const getTimeWithoutAMPM = (time) => {
  //   if (!time) return "";
  //   return time.split(" ")[0];
  // };

  const convertTo24Hour = (time12h) => {
    if (!time12h || typeof time12h !== "string") {
      return "";
    }
    const [time, modifier] = time12h.split(" ");
    if (!time || !modifier) {
      return "";
    }

    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }

    if (modifier.toLowerCase() === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      title="EDIT TICKET"
      className={styles.modal}
      closeBtn="none"
      onClose={onCollapse}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <span className={styles.label}>Ticket ID</span>
          <span>{formData.id}</span>
        </div>

        <div className={styles.row}>
          <span className={styles.label}>Ticket Price</span>
          <span>{` $${formData.price} USD`}</span>
        </div>
        <div
          className={classNames(
            styles.inputItem,
            errors.name ? styles.inputError : ""
          )}
        >
          <InputItem
            label="Ticket Name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />

          {errors.name && (
            <>
              <span className={styles.label} />
              <span className={styles.error}>{errors.name}</span>
            </>
          )}
        </div>

        <div className={styles.dateRow}>
          <div className={styles.row}>
            <span className={styles.label}>Start Date</span>
            <span className={styles.datePicker}>
              <DatePicker
                dateString={formData.startDate || ""}
                setDate={(sDate) =>
                  setFormData({ ...formData, startDate: sDate })
                }
              />
            </span>
            <span className={styles.timePicker}>
              <TimePicker
                timeString={formData.startTime}
                onChange={(sTime) =>
                  setFormData({ ...formData, startTime: sTime })
                }
              />
            </span>
            <span className={styles.timezone}>
              <Select
                // placeholder={formData.startZone || "CST"}
                placeholder={formData.timeZone || "CST"}
                options={timeZones}
                // onChange={(timezone) =>
                //   setFormData({ ...formData, startZone: sZone })
                // }

                onChange={(timezone) =>
                  setFormData({ ...formData, timeZone: timezone })
                }
              />
            </span>
          </div>

          <div className={classNames(styles.row)}>
            <span className={styles.label}>End Date</span>
            <span className={styles.datePicker}>
              <DatePicker
                dateString={formData.endDate || ""}
                setDate={(eDate) =>
                  setFormData({ ...formData, endDate: eDate })
                }
              />
            </span>
            <span className={styles.timePicker}>
              <TimePicker
                timeString={formData.endTime}
                onChange={(eTime) =>
                  setFormData({ ...formData, endTime: eTime })
                }
              />
            </span>
            <span className={styles.timezone}>
              <Select
                // placeholder={formData.endZone || "CST"}
                placeholder={formData.timeZone || "CST"}
                options={timeZones}
                // onChange={(eZone) =>
                //   setFormData({ ...formData, endZone: eZone })
                // }
                onChange={(timezone) =>
                  setFormData({ ...formData, timeZone: timezone })
                }
              />
            </span>
          </div>
        </div>
      </div>

      <div className={styles.divider} />
      <div className={styles.footer}>
        <Button className={styles.submitButton} onClick={onClick}>
          SAVE
        </Button>
      </div>
    </Modal>
  );
}

EditTicketModal.propTypes = {
  /**Getter to determine if modal is displayed */
  isOpen: PropTypes.bool,
  /**Callback function for when the modal is closed */
  onCollapse: PropTypes.func,
  /**Function called when the save btn is clicked */
  onClick: PropTypes.func,
  /**Object which contains ticket info passed in to the modal */
  ticketInfo: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    price: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    timezone: PropTypes.string,
  }),
};

EditTicketModal.defaultProps = {
  ticketInfo: {
    id: "",
    price: "",
    name: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    timezone: "",
  },
};

{
  /* Purchase Date */
}
{
  /* <span className={styles.label}>Purchase Begin</span>
        <span
          className={classNames(
            styles.input,
            errors.purchaseBegin.period ? styles.inputError : ""
          )}
        >
          <Input
            placeholder="#"
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseBegin: {
                  ...formData.purchaseBegin,
                  period: e.target.value,
                },
              })
            }
            value={formData.purchaseBegin.period || ""}
          />
        </span>
        <span className={styles.dropDown}>
          <Select
            options={["Days", "Weeks", "Months"]}
            placeholder={formData.purchaseBegin.length || "Days"}
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseBegin: {
                  ...formData.purchaseBegin,
                  length: e,
                },
              })
            }
          />
        </span>
        <span>Before the event at</span>
        <span
          className={classNames(
            styles.timePicker,
            errors.purchaseBegin.time ? styles.inputError : ""
          )}
        >
          <TimePicker
            timeString={formData.purchaseBegin.time || ""}
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseBegin: {
                  ...formData.purchaseBegin,
                  time: e,
                },
              })
            }
          />
        </span>
        <span className={classNames(styles.dropDown, styles.timezone)}>
          <Select
            options={["PST", "MST", "CST", "EST"]}
            placeholder={formData.purchaseBegin.timezone || "CST"}
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseBegin: {
                  ...formData.purchaseBegin,
                  timezone: e,
                },
              })
            }
          />
        </span>
        {!objectIsEmpty(errors.purchaseBegin) ? (
          <>
            <span className={styles.label} />
            <span className={styles.error}>{errors.purchaseBegin.period}</span>
            <span className={styles.timeError}>
              {errors.purchaseBegin.time}
            </span>
          </>
        ) : (
          ""
        )}
        <span className={styles.label}>Purchase End</span>
        <span
          className={classNames(
            styles.input,
            errors.purchaseBegin.time ? styles.inputError : ""
          )}
        >
          <Input
            placeholder="#"
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseEnd: {
                  ...formData.purchaseEnd,
                  period: e.target.value,
                },
              })
            }
            value={formData.purchaseEnd.period || ""}
          />
        </span>
        <span className={styles.dropDown}>
          <Select
            options={["Days", "Weeks", "Months"]}
            placeholder={formData.purchaseEnd.length || "Days"}
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseEnd: {
                  ...formData.purchaseEnd,
                  length: e,
                },
              })
            }
          />
        </span>
        <span className={styles.beforeEventLabel}>Before the event at</span>
        <span
          className={classNames(
            styles.timePicker,
            errors.purchaseBegin.time ? styles.inputError : ""
          )}
        >
          <TimePicker
            timeString={formData.purchaseEnd.time || ""}
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseEnd: {
                  ...formData.purchaseEnd,
                  time: e,
                },
              })
            }
          />
        </span>
        <span className={classNames(styles.dropDown, styles.timezone)}>
          <Select
            options={["PST", "MST", "CST", "EST"]}
            placeholder={formData.purchaseEnd.timezone || "CST"}
            onChange={(e) =>
              setFormData({
                ...formData,
                purchaseEnd: {
                  ...formData.purchaseEnd,
                  timezone: e,
                },
              })
            }
          />
        </span>
        {!objectIsEmpty(errors.purchaseEnd) ? (
          <>
            <span className={styles.label} />
            <span className={styles.error}>{errors.purchaseEnd.period}</span>
            <span className={styles.timeError}>{errors.purchaseEnd.time}</span>
          </>
        ) : (
          ""
        )} */
}
