import { Auth } from "shared/@auth/Auth";
import { QUERY_KEYS } from "shared/@utils/queryKeys";
import { useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

export function useCreateEvent(fetchRulesets) {
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, refetch } = useQuery(
    ["rulesets"],
    () =>
      makeAuthedRequest(`v1/events/rulesets/all`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: fetchRulesets,
    }
  );

  const createEvent = useCallback(
    async (event) => {
      return makeAuthedRequest(`v1/events/event/add`, {
        method: "POST",
        body: JSON.stringify(event),
      }).then(async (req) => {
        if (req.ok) {
          // Invalidate all event-related queries
          queryClient.invalidateQueries(QUERY_KEYS.events("25"));
          queryClient.invalidateQueries(QUERY_KEYS.events("50"));
          queryClient.invalidateQueries(QUERY_KEYS.events("all"));

          return req.json();
        } else {
          const json = await req.json();
          throw new Error(json.message);
        }
      });
    },
    [makeAuthedRequest, queryClient]
  );

  const createNewEvent = useMutation(
    async (newEvent) => {
      const response = await makeAuthedRequest("v1/events/rulesets/new-event", {
        method: "POST",
        body: JSON.stringify(newEvent),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }

      return response.json();
    },
    {
      onSuccess: () => {
        // Invalidate and refetch queries here
        queryClient.invalidateQueries(["rulesets"]);
      },
      onError: (error) => {
        console.error("Error creating new event:", error);
      },
    }
  );

  return { data, refetch, isError, isLoading, createEvent, createNewEvent };
}
