import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { DATE_FORMAT, TIME_FORMAT } from "shared/globals";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const defaultFormat = `${DATE_FORMAT} ${TIME_FORMAT} z`;

/**
 * Formats a date with a custom Day.js/Moment-compatible format string.
 * Falls back to the global default with timezone if a format string isn't provided.
 *
 * @example formatDate(date, "YY-MM-DD")
 *
 * @param {Date} date - JS data object.
 * @param {string?} format - Format string.
 */
export default function (date, format = defaultFormat) {
  return dayjs(date).format(format);
}
