import styles from "./ManageInviteesPage.module.css";
import Header from "shared/Header";
import SessionViewInfoCard from "Events/Components/SessionViewInfoCard";
import Button from "shared/Button";
import InviteesListCard from "Events/Components/InviteesListCard";
import { useHistory } from "react-router-dom";

const inviteeList = [
  {
    orderID: "2022-123451",
    name: "One and Last Name",
    email: "add@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "01/01/2022",
    attribution: "LOS - Puryear Emeraldship",
  },
  {
    orderID: "2022-123452",
    name: "Two and Last Name",
    email: "dreamstream+qa.12663.1753.17@wwdb.com",
    eventName: "Family Reunion",
    purchaseDate: "02/10/2000",
    attribution: "Manual",
  },

  {
    orderID: "2022-123453",
    name: "Three and Last Name",
    email: "two@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "03/02/2018",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123454",
    name: "Four and Last Name",
    email: "youremail@address.com",
    eventName: "Second Meeting",
    purchaseDate: "04/10/2022",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123455",
    name: "Five and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "05/22/2022",
    attribution: "LOS - Puryear Emeraldship",
  },
  {
    orderID: "2022-123456",
    name: "First and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "06/10/2000",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123457",
    name: "First and Last Name",
    email: "your@address.com",
    eventName: "Family",
    purchaseDate: "07/22/2022",
    attribution: "LOS - Puryear Emeraldship",
  },
  {
    orderID: "2022-123458",
    name: "Last and Last Name",
    email: "add@address.com",
    eventName: "Second Meeting",
    purchaseDate: "08/25/2010",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123459",
    name: "Your and Last Name",
    email: "your@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "02/20/2022",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123462",
    name: "Liza and Last Name",
    email: "liza@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "12/10/2021",
    attribution: "LOS - Puryear Emeraldship",
  },
  {
    orderID: "2022-123463",
    name: "Jason and Last Name",
    email: "jasonemail@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "01/16/2010",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123456",
    name: "First and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "0/00/0000",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123456",
    name: "First and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "0/00/0000",
    attribution: "LOS - Puryear Emeraldship",
  },
  {
    orderID: "2022-123456",
    name: "First and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "0/00/0000",
    attribution: "LOS - Duncan Diamondship",
  },
  {
    orderID: "2022-123456",
    name: "First and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "0/00/0000",
    attribution: "Manual",
  },
  {
    orderID: "2022-123456",
    name: "First and Last Name",
    email: "email@address.com",
    eventName: "Spring Leadership 2022",
    purchaseDate: "0/00/0000",
    attribution: "Manual",
  },
];

export function ManageInviteesPage() {
  let history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.pageHeader}>
          {/* <h1 className={styles.title}>MANAGE INVITEES</h1> */}
          <Header
            title="MANAGE INVITEES"
            onClick={() => history.goBack()}
            children={
              <>
                <Button
                  className={styles.saveButton}
                  children="SAVE"
                  onClick={() => alert("Save Button Clicked")}
                />
              </>
            }
          />
        </div>
        <div className={styles.infoCard}>
          <SessionViewInfoCard
            session={{
              capacity: "capacity",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
              location: "location",
              name: "name",
              speakerName: "speakerName",
              startDate: "startDate",
              visibility: "visibility",
            }}
          />
        </div>

        <div className={styles.inviteesCard}>
          <InviteesListCard
            invitees={inviteeList}
            tags={["LOS - Duncan Diamondship", "LOS - Puryear Emeraldship"]}
            onIboLookup={() => alert("Look up IBO")}
            onLosLookup={() => alert("Look Up LOS")}
          />
        </div>

        <Button
          className={styles.saveButtonBottom}
          children="SAVE"
          onClick={() => alert("Save Button Clicked")}
        />
      </div>
    </>
  );
}
