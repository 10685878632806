import styles from "./index.module.css";
import React, { useState, useCallback } from "react";
import { useMount, useInterval } from "react-use";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loader from "shared/Loader";

/*
const allMessages = [
  "Reticulating splines...",
  "Flipping booleans on divs...",
  "Waking up hamsters...",
  "Increasing horsepower...",
  "Training neural networks...",
  "Feeding code monkeys...",
  "Scaling up...",
  "Scaling down...",
  "Scaling horizontally...",
  "Timing out...",
  "Gaining sentience...",
  "Recycling runtimes...",
  "Defragmenting hard drives...",
  "Expiring caches...",
  "Migrating databases...",
  "Turning off and on again...",
];
*/


const allMessages = [
  "Onward and Upward! ~Doug Reichel",
  "Hard times are the battleground to be the best. ~Kulia & Maiko Tuitupou",
  "Everything should be made as SIMPLE as possible, but not simpler. ~Albert Einstein",
  "Create with the heart; build with the mind. ~Criss Jami",
  "Anything is possible when you have the right people there to support you. ~Misty Copeland",
  "Build Your Life!"
];


export default function () {
  const [index, setIndex] = useState(0);
  const [messages, setMessages] = useState(null);

  useMount(() => {
    setMessages(shuffle(allMessages));
  });

  const nextMessage = useCallback(() => {
    if (index + 1 >= messages.length) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }, [index, messages]);

  useInterval(nextMessage, 3000);

  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <Loader color="#fff" />
      </div>
      <p className={styles.label}>Loading Account Information</p>

      <TransitionGroup className={styles.subLabel}>
        {messages && (
          <CSSTransition
            key={messages[index]}
            timeout={240}
            classNames={{ ...styles }}
          >
            <span className={styles.subLabelText}>{messages[index]}</span>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
