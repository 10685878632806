import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "shared/Modal";
import ModalActionBar from "shared/ModalActionBar";
import styles from "./Button.module.css";

// Size enum
export const ButtonSize = {
  normal: "normal", // default
  small: "small",
  tiny: "tiny",
};

// Purpose enum
export const ButtonPurpose = {
  generic: "generic", // default
  delete: "delete",
  save: "save",
};

const BasePropTypes = {
  /**
   * Size setting for the button. Use normal usually, small in headers, tiny in tables.
   */
  size: PropTypes.oneOf([ButtonSize.normal, ButtonSize.small, ButtonSize.tiny]),

  /**
   * Purpose determines color. Green for save, red for delete and grey for generic.
   */
  purpose: PropTypes.oneOf([
    ButtonPurpose.generic,
    ButtonPurpose.save,
    ButtonPurpose.delete,
  ]),

  /**
   * Class name to be passed on directly to the component.
   */
  className: PropTypes.string,

  /**
   * Optional icon to display inside the button.
   */
  icon: PropTypes.node,

  /**
   * Prevents clicks when true.
   */
  disabled: PropTypes.bool,

  /**
   * Function to call when the button is clicked.
   */
  onClick: PropTypes.func,
};

/**
 * A general purpose button component.
 */
export function Button({
  className,
  icon,
  children,
  disabled,
  size = ButtonSize.normal,
  purpose = ButtonPurpose.generic,
  visible = true,
  ...props
}) {
  return (
    <button
      style={{visibility: visible ? "visible" : "hidden"}}
      className={classNames(styles.button, className, {
        [styles.small]: size === ButtonSize.small,
        [styles.tiny]: size === ButtonSize.tiny,
        [styles.delete]: purpose === ButtonPurpose.delete,
        [styles.save]: purpose === ButtonPurpose.save,
      })}
      {...props}
      disabled={disabled}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </button>
  );
}

Button.propTypes = {
  ...BasePropTypes,
};

/**
 * A button that requires two clicks to confirm. Same as the standard <Button>,
 * but also takes an onConfirm handler that will only fire on the second click.
 *
 * @example
 * <ConfirmButton
 *   onClick={() => {
 *    console.log("Clicked once.")
 *   }}
 *   onConfirm={() => {
 *     console.log("Clicked twice.")
 *   }}
 *   confirmText="Confirm Deletion"
 * >
 *   Delete Important Thing
 * </ConfirmButton>
 */
export function ConfirmButton({
  className,
  children,
  size,
  purpose,
  confirmText = "Confirm",
  onClick,
  onConfirm,
  ...props
}) {
  const [isConfirming, setIsConfirming] = useState(false);

  const _onClick = useCallback(
    (e) => {
      setIsConfirming(true);

      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  const _onConfirm = useCallback(
    (e) => {
      if (onConfirm) {
        onConfirm(e);
      }
    },
    [onConfirm]
  );

  const _onCancel = useCallback(() => {
    setIsConfirming(false);
  }, []);

  return (
    <>
      <Button
        {...props}
        disabled={isConfirming ?? props.disabled}
        onClick={_onClick}
      >
        {children}
      </Button>
      <Modal title="Confirm" isOpen={isConfirming} onClose={_onCancel}>
        <p>{confirmText}</p>
        <ModalActionBar>
          <Button onClick={_onCancel}>Cancel</Button>
          <Button onClick={_onConfirm} purpose={purpose}>
            Confirm
          </Button>
        </ModalActionBar>
      </Modal>
    </>
  );
}

/**
 * For those times when you need a link that looks like a button.
 */
export function ButtonLink({
  className,
  icon,
  children,
  size,
  purpose,
  ...props
}) {
  return (
    <Link
      className={classNames(styles.button, styles.buttonLink, className, {
        [styles.disabled]: props.disabled,
        [styles.small]: size === ButtonSize.small,
        [styles.tiny]: size === ButtonSize.tiny,
        [styles.delete]: purpose === ButtonPurpose.delete,
        [styles.save]: purpose === ButtonPurpose.save,
      })}
      {...props}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </Link>
  );
}
