/**
 * Sort an array of objects ascending by a field.
 */
export function ascendingBy(key) {
  return function (a, b) {
    if (a[key] <= b[key]) {
      return -1;
    } else if (a[key] >= b[key]) {
      return +1;
    } else {
      return 0;
    }
  };
}

/**
 * Sort an array of objects descending by a field.
 */
export function descendingBy(key) {
  return function (a, b) {
    if (a[key] <= b[key]) {
      return +1;
    } else if (a[key] >= b[key]) {
      return -1;
    } else {
      return 0;
    }
  };
}
