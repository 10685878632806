import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "shared/Card";
import { Select } from "shared/Select/Select";
import AddButton from "Events/Components/AddButton";
import VenueInfoCard from "Events/Components/VenueInfoCard";
import Checkbox from "shared/@forms/Checkbox";

import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import styles from "./VenueForm.module.css";
import CreateNewVenueModal from "Events/Components/CreateNewVenueModal";
import EditVenueModal from "Events/Components/EditVenueModal";

//Area to fill out information about the venue with a map displaying the venue location
export function VenueForm({
  state,
  setState,
  city,
  setCity,
  venue,
  setVenue,
  addNewVenue,
}) {
  const tempVenues = [
    {
      name: "venue 1",
      address1: "1234 Address Way #123",
      city: "Montgomery",
      state: "Alabama",
      zip: "Zip",
      capacity: "capacity",
      rooms: [
        "Ballroom A - Capacity: 500",
        "Ballroom B - Capacity: 750",
        "Conference Room C - Capacity: 35",
      ],
      venueURL: "URL",
      contactName: "contactName",
      phone: "phone",
      email: "email",
    },
    {
      name: "zebra pit",
      address1: "1234 Address Way #123",
      city: "Anchorage",
      state: "Alaska",
      zip: "Zip",
      capacity: "capacity",
      rooms: [
        "Ballroom A - Capacity: 500",
        "Ballroom B - Capacity: 750",
        "Conference Room C - Capacity: 35",
      ],
      venueURL: "URL",
      contactName: "contactName",
      phone: "phone",
      email: "email",
    },
    {
      name: "ballroom",
      address1: "1234 Address Way #123",
      city: "Phoenix",
      state: "Arizona",
      zip: "Zip",
      capacity: "capacity",
      rooms: [
        "Ballroom A - Capacity: 500",
        "Ballroom B - Capacity: 750",
        "Conference Room C - Capacity: 35",
      ],
      venueURL: "URL",
      contactName: "contactName",
      phone: "phone",
      email: "email",
    },
    {
      name: "venue 3",
      address1: "1234 Address Way #123",
      city: "Albany",
      state: "New York",
      zip: "Zip",
      capacity: "capacity",
      rooms: [
        "Ballroom A - Capacity: 500",
        "Ballroom B - Capacity: 750",
        "Conference Room C - Capacity: 35",
      ],
      venueURL: "URL",
      contactName: "contactName",
      phone: "phone",
      email: "email",
    },
    {
      name: "venue with a space in the city name",
      address1: "1234 Address Way #123",
      city: "Scottsdale",
      state: "Arizona",
      zip: "Zip",
      capacity: "capacity",
      rooms: [
        "Ballroom A - Capacity: 500",
        "Ballroom B - Capacity: 750",
        "Conference Room C - Capacity: 35",
      ],
      venueURL: "URL",
      contactName: "contactName",
      phone: "phone",
      email: "email",
    },
    {
      name: "venue with a space in the state name",
      address1: "1234 Address Way #123",
      city: "New York",
      state: "New York",
      zip: "Zip",
      capacity: "capacity",
      rooms: [
        "Ballroom A - Capacity: 500",
        "Ballroom B - Capacity: 750",
        "Conference Room C - Capacity: 35",
      ],
      venueURL: "URL",
      contactName: "contactName",
      phone: "phone",
      email: "email",
    },
  ];

  const [venueNames, setVenueNames] = useState([]);
  const [venues, setVenues] = useState(tempVenues);
  const [selectedVenue, setSelectedVenue] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [openNewVenueModal, setOpenNewVenueModal] = useState(false);
  const [openEditVenueModal, setOpenEditVenueModal] = useState(false);
  const [noVenue, setNoVenue] = useState(false);

  //Get names for the select every time venues updates
  useEffect(() => {
    setVenueNames(venues.map((venue) => venue.name).sort());
    //Get unique states and add them to the filter dropdown

    const uniqueStates = tempVenues
      .map((venue) => venue.state)
      .filter((value, index, self) => self.indexOf(value) === index);

    setStates(["All", ...uniqueStates]);
  }, [venues]);

  //Filter by state
  useEffect(() => {
    if (state === "All") {
      setVenues(tempVenues);
      const uniqueCities = tempVenues
        .map((venue) => venue.city)
        .filter((value, index, self) => self.indexOf(value) === index);
      setCities(["All", ...uniqueCities]);
    } else if (state.length > 0) {
      const venuesWithState = tempVenues.filter(
        (venue) => venue.state == state
      );
      setVenues(venuesWithState);
      const uniqueCities = venuesWithState
        .map((venue) => venue.city)
        .filter((value, index, self) => self.indexOf(value) === index);
      setCities(["All", ...uniqueCities]);
    }
    //clear the city dropdown if they select a new state
    if (city) {
      setCity("");
    }
  }, [state]);

  //Filter by city
  useEffect(() => {
    if (city === "All" && state !== "All") {
      setVenues(tempVenues.filter((venue) => venue.state === state));
    } else if (city.length > 0) {
      setVenues(tempVenues.filter((venue) => venue.city === city));
    } else if (state === "All") {
      setVenues(tempVenues);
    }
  }, [city]);

  const handleNewVenue = () => {
    setOpenNewVenueModal(true);
    addNewVenue();
  };

  const handleNoVenue = () => {
    if (noVenue) {
      setNoVenue(false);
    } else {
      setNoVenue(true);
      setVenue("");
      setState("");
      setCity("");
      setSelectedVenue("");
      setOpenNewVenueModal(false);
    }
  };
  const handleSetVenue = (selection) => {
    setSelectedVenue(venues.filter((venue) => venue.name === selection)[0]);
    setVenue(selection);
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.title}>VENUE</div>
          <hr className={styles.hr} />

          <>
            <div className={styles.row}>
              <div className={styles.leftCol}>
                <span className={styles.label}>No Event Venue</span>
                <span className={styles.checkbox}>
                  <Checkbox onChange={handleNoVenue} defaultChecked={noVenue} />
                </span>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.leftCol}>
                <span className={styles.label}>Filter</span>
                <span className={styles.filterDropdown}>
                  <Select
                    placeholder={state || "State"}
                    options={states}
                    onChange={setState}
                    disabled={noVenue}
                    className={noVenue ? styles.disabled : ""}
                  />
                </span>
                <span className={styles.filterDropdown}>
                  <Select
                    placeholder={city || "City"}
                    options={cities}
                    onChange={setCity}
                    disabled={state === "All" || !state || noVenue}
                    className={noVenue ? styles.disabled : ""}
                  />
                </span>
                <span className={styles.text}>(Optional)</span>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.leftCol}>
                <span className={styles.label}>Venue *</span>
                <span className={styles.venueDropdown}>
                  <Select
                    placeholder={venue || "Select"}
                    onChange={handleSetVenue}
                    options={venueNames}
                    disabled={noVenue}
                    className={noVenue ? styles.disabled : ""}
                  />
                </span>
                <span
                  className={noVenue ? styles.disabledAdd : styles.button}
                  onClick={handleNewVenue}
                >
                  <AddButton
                    description="Add New Venue"
                    color="#9AB5BB"
                    disabled={noVenue}
                  />
                </span>
              </div>

              <CreateNewVenueModal
                isOpen={openNewVenueModal}
                onCollapse={() => {
                  setOpenNewVenueModal(false);
                }}
                onClick={() => {}}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.leftCol}>
                <span className={styles.label} />
                {venue && !noVenue && (
                  <div className={styles.infoCard}>
                    <VenueInfoCard
                      venueInfo={selectedVenue}
                      onEdit={() => {
                        setOpenEditVenueModal(true);
                      }}
                    />
                  </div>
                )}
              </div>

              <EditVenueModal
                isOpen={openEditVenueModal}
                onCollapse={() => {
                  setOpenEditVenueModal(false);
                }}
                onClick={() => {}}
                onDelete={() => {}}
                venue={{
                  name: "venue 1",
                  address1: "1234 Address Way #123",
                  city: "Montgomery",
                  state: "Alabama",
                  zip: "99999",
                  capacity: "500",
                  rooms: [
                    "Ballroom A - Capacity: 500",
                    "Ballroom B - Capacity: 750",
                    "Conference Room C - Capacity: 35",
                  ],
                  venueURL: "www.url.com",
                  contactName: "contactName",
                  phone: "phone",
                  email: "email",
                }}
              />
            </div>
          </>
        </section>
      }
    />
  );
}
VenueForm.propTypes = {
  //Value of state select
  state: PropTypes.string,
  //sets the state select
  setState: PropTypes.func,
  //value of city select
  city: PropTypes.string,
  //sets the city select
  setCity: PropTypes.func,
  //value of venue select
  venue: PropTypes.string,
  //sets the venue select
  setVenue: PropTypes.func,
  //Boolean that decides if there is no event venue
  noVenue: PropTypes.bool,
  //sets noVenue to true/false
  setNoVenue: PropTypes.func,
  //Function called when add new venue is clicked
  addNewVenue: PropTypes.func,
};
