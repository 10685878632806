import styles from "./index.module.css";
import classNames from "classnames";
import { useCallback } from "react";
import { ChevronUp } from "react-ikonate";

/**
 * Enum of sort values. These are the values onChange will receive.
 *
 * @example
 * TableSortValue.ascending
 * TableSortValue.descending
 * TableSortValue.none
 */
export const TableSortValue = {
  ascending: "asc",
  descending: "desc",
  none: null,
};

/**
 * Clickable header to change table sort order. Toggles between asc, desc and off.
 *
 * Actual sorting logic is not the domain of this component. Use onChange to implement
 * your own sorting logic while using the component as the toggle.
 *
 * @example
 * <Table>
 *   <thead>
 *     <tr>
 *       <th>
 *         <TableSortHeader
 *           value={valueTrackedByYou}
 *           onChange={(newValue) => {
 *             switch (newValue) {
 *               case "asc":
 *                 // update state to sort ascending
 *                 break;
 *               case "desc":
 *                 // update state to sort descending
 *                 break;
 *               default:
 *                 // disable sorting/set to default
 *                 break;
 *             }
 *           }}
 *         >
 *           Name
 *         </TableSortHeader>
 *       </th>
 *       // ... other components ... //
 *     </tr>
 *   </thead>
 *  <tbody>
 *   // ... other components ... //
 *  </tbody>
 * </Table>
 */
export default function TableSortHeader({ value, onChange, children }) {
  const getNextValue = useCallback(() => {
    switch (value) {
      case TableSortValue.none:
        return TableSortValue.ascending;
      case TableSortValue.ascending:
        return TableSortValue.descending;
      case TableSortValue.descending:
        return TableSortValue.ascending;
    }
  }, [value]);

  return (
    <button
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();

        const next = getNextValue();
        if (onChange) {
          onChange(next);
        }
      }}
    >
      <div className={styles.children}>{children}</div>

      <div className={styles.indicator}>
        {value === TableSortValue.ascending && (
          <ChevronUp key="icon" className={styles.icon} />
        )}
        {value === TableSortValue.descending && (
          <ChevronUp
            key="icon"
            className={classNames(styles.icon, styles.reverse)}
          />
        )}
      </div>
    </button>
  );
}
