import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth } from "shared/@auth/Auth";
import toast from "react-hot-toast";
import createNotification from "shared/@utils/createNotification";

export function useEditEventsPage({ eventId }) {
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, refetch } = useQuery(
    ["event", eventId],
    () =>
      makeAuthedRequest(`v1/events/id/${eventId}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  const eventEditPut = useMutation(
    (eventObject) =>
      makeAuthedRequest(`v1/events/event/update/${eventId}`, {
        method: "PUT",
        body: JSON.stringify(eventObject),
      }),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["event", eventId]);
        createNotification({
          type: "success",
          title: "Saved",
          message: "Successfully Updated",
          autoDismiss: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast.error("Something went wrong!");
      },
    }
  );

  const eventPublish = useMutation(["publishEventsPut"], () =>
    makeAuthedRequest(`v1/events/event/publish`, {
      method: "PUT",
      body: JSON.stringify(eventId),
    }).then(async (res) => {
      if (res.ok) {
        return;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  return {
    data,
    isLoading,
    refetch,
    eventEditPut,
    eventPublish,
  };
}
