import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./InviteesListCard.module.css";
import classNames from "classnames";
import { InviteesListItem } from "Events/Components/InviteesListItem/InviteesListItem";
import PaginationControls from "shared/PaginationControls";
import { Tag } from "Events/Components/Tag/Tag";
import TableSortItem from "shared/TableSortItem";
import Card from "shared/Card";

/**
 * Provides a list of people and their info with pagination on the bottom. There is also the ability to remove anyone with an attribution type of manual. There are buttons on the top right that open modals to lookup LOS and IBO’s (These modals will be added once they are created in the design).
 */
export function InviteesListCard({ invitees, tags, onLosLookup, onIboLookup }) {
  const [currentPage, setCurrentPage] = useState(1);

  const [orderIdSortOrder, setOrderIdSortOrder] = useState("desc");
  const [nameSortOrder, setNameSortOrder] = useState("desc");
  const [emailSortOrder, setEmailSortOrder] = useState("desc");
  const [eventNameSortOrder, setEventNameSortOrder] = useState("desc");
  const [purchaseDateSortOrder, setPurchaseDateSortOrder] = useState("desc");
  const [attributionSortOrder, setAttributionSortOrder] = useState("desc");

  const [sortedInvitees, setSortedInvitees] = useState(invitees);
  const [paginatedInvitees, setPaginatedInvitees] = useState(invitees);

  useEffect(() => {
    setSortedInvitees(invitees);
  }, [invitees]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let resultsPerPage = 10;

  const lastPage = Math.ceil(invitees.length / resultsPerPage);

  function sort(prop, sortOrder) {
    if (sortOrder === "asc") {
      switch (prop) {
        case "orderID":
          setSortedInvitees(
            invitees.sort((a, b) => a.orderID.localeCompare(b.orderID))
          );
          break;
        case "name":
          setSortedInvitees(
            invitees.sort((a, b) => a.name.localeCompare(b.name))
          );
          break;
        case "email":
          setSortedInvitees(
            invitees.sort((a, b) => a.email.localeCompare(b.email))
          );
          break;
        case "eventName":
          setSortedInvitees(
            invitees.sort((a, b) => a.eventName.localeCompare(b.eventName))
          );
          break;
        case "purchaseDate":
          setSortedInvitees(
            invitees.sort(
              (a, b) => Date.parse(a.purchaseDate) - Date.parse(b.purchaseDate)
            )
          );
          break;
        case "attribution":
          setSortedInvitees(
            invitees.sort((a, b) => a.attribution.localeCompare(b.attribution))
          );
          break;
        default:
          setSortedInvitees(invitees);
      }
    } else {
      switch (prop) {
        case "orderID":
          setSortedInvitees(
            invitees.sort((a, b) => b.orderID.localeCompare(a.orderID))
          );
          break;
        case "name":
          setSortedInvitees(
            invitees.sort((a, b) => b.name.localeCompare(a.name))
          );
          break;
        case "email":
          setSortedInvitees(
            invitees.sort((a, b) => b.email.localeCompare(a.email))
          );
          break;
        case "eventName":
          setSortedInvitees(
            invitees.sort((a, b) => b.eventName.localeCompare(a.eventName))
          );
          break;
        case "purchaseDate":
          setSortedInvitees(
            invitees.sort(
              (a, b) => Date.parse(b.purchaseDate) - Date.parse(a.purchaseDate)
            )
          );
          break;
        case "attribution":
          setSortedInvitees(
            invitees.sort((a, b) => b.attribution.localeCompare(a.attribution))
          );
          break;
        default:
          setSortedInvitees(invitees);
      }
    }
  }

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * resultsPerPage;
    const lastPageIndex = firstPageIndex + resultsPerPage;
    if (sortedInvitees.length)
      setPaginatedInvitees(sortedInvitees.slice(firstPageIndex, lastPageIndex));
  }, [
    currentPage,
    resultsPerPage,
    sortedInvitees,
    orderIdSortOrder,
    nameSortOrder,
    emailSortOrder,
    eventNameSortOrder,
    purchaseDateSortOrder,
    attributionSortOrder,
  ]);

  return (
    <>
      <Card>
        <div className={styles.container}>
          <div>
            <button
              className={classNames(styles.button, styles.deleteButton)}
              onClick={onIboLookup}
            >
              IBO LOOKUP
            </button>
            <button className={styles.button} onClick={onLosLookup}>
              LOS LOOKUP
            </button>
          </div>
          <div className={styles.bold}>ASSIGNED</div>
          <div className={styles.tags}>
            {tags.map((item) => (
              <Tag
                title={item}
                isBtnLight={false}
                onClick={() => alert("removeTag called: " + item)}
              />
            ))}
          </div>
          <div className={styles.header}>
            <div className={styles.tableSortItem}>
              <TableSortItem
                value={orderIdSortOrder}
                title="Order ID"
                onChange={() => {
                  setOrderIdSortOrder(
                    orderIdSortOrder === "asc" ? "desc" : "asc"
                  );
                  sort("orderID", orderIdSortOrder);
                }}
              />
            </div>

            <div className={styles.tableSortItem}>
              <TableSortItem
                value={nameSortOrder}
                title="Name"
                onChange={() => {
                  setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
                  sort("name", nameSortOrder);
                }}
              />
            </div>
            <div className={styles.tableSortItem}>
              <TableSortItem
                value={emailSortOrder}
                title="Email"
                onChange={() => {
                  setEmailSortOrder(emailSortOrder === "asc" ? "desc" : "asc");
                  sort("email", emailSortOrder);
                }}
              />
            </div>

            <div className={styles.tableSortItem}>
              <TableSortItem
                value={eventNameSortOrder}
                title="Event Name"
                onChange={() => {
                  setEventNameSortOrder(
                    eventNameSortOrder === "asc" ? "desc" : "asc"
                  );
                  sort("eventName", eventNameSortOrder);
                }}
              />
            </div>
            <div className={styles.tableSortItem}>
              <TableSortItem
                value={purchaseDateSortOrder}
                title="Purchase Date"
                onChange={() => {
                  setPurchaseDateSortOrder(
                    purchaseDateSortOrder === "asc" ? "desc" : "asc"
                  );
                  sort("purchaseDate", purchaseDateSortOrder);
                }}
              />
            </div>

            <div className={styles.tableSortItem}>
              <TableSortItem
                value={attributionSortOrder}
                title="Attribution"
                onChange={() => {
                  setAttributionSortOrder(
                    attributionSortOrder === "asc" ? "desc" : "asc"
                  );
                  sort("attribution", attributionSortOrder);
                }}
              />
            </div>
          </div>

          {paginatedInvitees.map((item, index) => (
            <div
              key={index}
              className={classNames(
                styles.whiteBG,
                index % 2 == 1 && styles.slightlyDarkerBackground
              )}
            >
              <InviteesListItem
                invitee={{
                  orderID: item.orderID,
                  name: item.name,
                  email: item.email,
                  eventName: item.eventName,
                  purchaseDate: item.purchaseDate,
                  attribution: item.attribution,
                }}
                onRemove={() => alert("orderID: " + item.orderID)}
              />
            </div>
          ))}

          {invitees.length <= resultsPerPage ? null : (
            <div className={styles.paginationControlsContainer}>
              <PaginationControls
                page={currentPage}
                lastPage={lastPage}
                onPageChange={onPageChange}
                disableNext={currentPage === lastPage}
                disabled={false}
                color="#1f3bd8"
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );

  // return <Card children={cardContents} />;
}

InviteesListCard.propTypes = {
  invitees: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.string),
  onLosLookup: PropTypes.func,
  onIboLookup: PropTypes.func,
};
