import React, { useEffect, useState } from "react";
import { Select } from "../../../shared/Select/Select";
import VenuesSearchBar from "../VenuesSearchBar";
import { Button } from "../../../shared/Button/Button";
import styles from "./FilterVenues.module.css";
import PropTypes from "prop-types";

export function FilterVenues({
  onClick,
  locations,
  rulesetOptions,
  filters,
  stateIdToNameMap,
}) {
  const [currentState, changeCurrentState] = useState("All");
  const [showClearAllButton, setShowClearAllButton] = useState(false);

  const [filterData, changeFilterData] = useState({
    state: "All",
    city: "All",
    search: "",
    ruleset: "All",
  });

  useEffect(() => {
    if (filters) {
      const stateName = stateIdToNameMap[filters.stateId] || "All";
      const newFilters = {
        state: stateName,
        city: filters.city === "all" ? "All" : filters.city,
        search: filters.search === "none" ? "" : filters.search,
        ruleset: filters.ruleset === "all" ? "All" : filters.ruleset,
      };

      changeFilterData(newFilters);
      changeCurrentState(newFilters.state);
    }
  }, [filters, stateIdToNameMap]);

  useEffect(() => {
    const appliedFiltersCount = Object.values(filterData).filter(
      (value) => value !== "All" && value !== "" 
    ).length;

    setShowClearAllButton(appliedFiltersCount > 1); 
  }, [filterData]);

  const handleStateSelection = (selection) => {
    changeCurrentState(selection);
    changeFilterData((prev) => ({ ...prev, state: selection, city: "All" }));
  };

  const handleCitySelection = (selection) => {
    changeFilterData((prev) => ({ ...prev, city: selection }));
  };

  const handleSearchInput = (value) => {
    if (value !== filterData.search) {
      changeFilterData((prev) => ({ ...prev, search: value }));
    }
  };

  const handleRulesetSelection = (selection) => {
    changeFilterData((prev) => ({ ...prev, ruleset: selection }));
  };

  const stateOptions = ["All", ...Object.keys(locations).sort()];
  const cityOptions =
    currentState && currentState !== "All" && locations[currentState]
      ? [...locations[currentState].sort()]
      : ["All"];
  const preparedRulesetOptions = ["All", ...rulesetOptions];

  const handleClearAll = () => {
    changeFilterData({
      state: "All",
      city: "All",
      search: "",
      ruleset: "All",
    });
    onClick({
      state: "all",
      city: "all",
      search: "none",
      ruleset: "all",
    });
    setShowClearAllButton(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.select}>
          <label className={styles.label}>State</label>
          <Select
            options={stateOptions}
            placeholder={filterData.state || "Select"}
            onChange={(option) => handleStateSelection(option)}
            className={styles.select}
          />
        </div>
        <div className={styles.select}>
          <label className={styles.label}>City</label>
          <Select
            options={cityOptions}
            placeholder={filterData.city || "Select"}
            disabled={currentState === "All"}
            onChange={(option) => handleCitySelection(option)}
            className={styles.select}
          />
        </div>
        <div className={styles.select}>
          <label className={styles.label}>Ruleset</label>
          <Select
            options={preparedRulesetOptions}
            placeholder={filterData.ruleset || "Select"}
            onChange={handleRulesetSelection}
            className={styles.select}
          />
        </div>
        <div className={styles.search}>
          <label className={styles.label}>Search</label>
          <VenuesSearchBar
            placeholderText="Click to type"
            initialValue={filterData.search}
            minChar={0}
            searchDelay={300}
            onInput={handleSearchInput}
            className={styles.search}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            children="FILTER"
            onClick={() => onClick(filterData)}
          />
        </div>
      </div>
      {showClearAllButton && (
        <div className={styles.clearButton} onClick={() => handleClearAll()}>
          Clear All Filters
        </div>
      )}
    </div>
  );
}

FilterVenues.propTypes = {
  // function to be called when selecting a dropdown filter item
  onClick: PropTypes.func,
  /**
   * object with keys of states and properties of arrays of cities:
   * Example:
   * {
   *  washington: [spokane, seattle, olympia, vancouver],
   * }
   */
  locations: PropTypes.shape({}),
};
