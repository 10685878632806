import PropTypes from "prop-types";
import styles from "./SpecialNeedsModal.module.css";
import { Button } from "@wwg/ui";
import { useState, useEffect } from "react";
import options from "./options";
import Loader from "shared/Loader";
import { useQueryClient } from "react-query";

/**
 * Interactive component that allows the user to configure ticket options notifying WWG of any accommodations they may need when attending an event.
 */
export const SpecialNeedsModal = ({
  ticket,
  onSave,
  onCancel,
  message,
  messageType,
}) => {
  const [accessibleState, setAccessibleState] = useState(null);
  const [hearingState, setHearingState] = useState(null);
  const [isNursingMother, setIsNursingMother] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [changesMade, setChangesMade] = useState(false); // Track changes made to checkbox states

  useEffect(() => {
    setAccessibleState(getAccessibleInitialState());
    setHearingState(getHearingInitialState());
    setIsNursingMother(!!ticket.adaValues.includes(262144)); // Nursing mother bitwise value
  }, [ticket]);

  useEffect(() => {
    if (message != null && message !== "") {
      setShowLoader(true);
      setShowButtons(false);
      setTimeout(() => {
        setShowLoader(false);
        setShowMessage(true);
        setShowCloseButton(true);
      }, 2000);
    }
  }, [message]);

  function getAccessibleInitialState() {
    const initialState = [];
    if (ticket.adaValues.includes(32768)) {
      initialState.push(options.accessible.seating.id);
    }
    if (ticket.adaValues.includes(131072)) {
      initialState.push(options.accessible.companion.id);
    }
    return initialState;
  }

  function getHearingInitialState() {
    const initialState = [];
    if (ticket.adaValues.includes(4)) {
      initialState.push(options.hearing.device.id);
    }
    if (ticket.adaValues.includes(8)) {
      initialState.push(options.hearing.interpreter.id);
    }
    if (ticket.adaValues.includes(65536)) {
      initialState.push(options.hearing.companion.id);
    }
    return initialState;
  }

  function handleCheckboxClick(id, setState, option) {
    setState((prevState) => {
      const newState = [...prevState];
      const index = newState.indexOf(option);
      if (index > -1) {
        newState.splice(index, 1);
      } else {
        newState.push(option);
      }
      setChangesMade(true);
      return newState;
    });
  }

  function handleAccessibleCheckboxClick(id) {
    setAccessibleState((prevState) => {
      // Toggle logic
      if (prevState[0] === id) {
        return []; // Deselect if already selected
      }
      return [id]; // Select new option
    });
    setChangesMade(true);
  }

  function handleHearingCheckboxClick(id) {
    setHearingState((prevState) => {
      // Toggle logic
      if (prevState[0] === id) {
        return []; // Deselect if already selected
      }
      return [id]; // Select new option
    });
    setChangesMade(true);
  }

  function getOnSaveOptions() {
    const adaValues = [];

    if (accessibleState.length > 0) {
      if (accessibleState[0] === options.accessible.seating.id) {
        adaValues.push(32768); // Accessible seating
      } else if (accessibleState[0] === options.accessible.companion.id) {
        adaValues.push(131072); // Accessible seating companion ticket
      }
    }

    if (hearingState.length > 0) {
      if (hearingState[0] === options.hearing.device.id) {
        adaValues.push(4); // Hearing amplification device
      } else if (hearingState[0] === options.hearing.interpreter.id) {
        adaValues.push(8); // American Sign Language Interpreter
      } else if (hearingState[0] === options.hearing.companion.id) {
        adaValues.push(65536); // Hearing accessible seating companion ticket
      }
    }

    if (isNursingMother) {
      adaValues.push(262144); // Nursing mother
    }

    return adaValues;
  }

  const handleNursingMotherCheckboxClick = () => {
    setIsNursingMother((prevState) => {
      setChangesMade(true);
      return !prevState;
    });
    setChangesMade(true);
  };

  const handleClose = () => {
    onCancel();
  };

  const handleSave = () => {
    setChangesMade(false);
    setShowLoader(true);
    setShowButtons(false);
    onSave({ ...ticket, adaValues: getOnSaveOptions() });
  };

  const handleCancel = () => {
    setShowButtons(false);
    onCancel();
  };
  let messageClass = `${styles.message}`;
  if (messageType === "success") {
    messageClass = `${styles.successMessage}`;
  } else if (messageType === "error") {
    messageClass = `${styles.errorMessage}`;
  }
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.specialNeedsModal}>
        <span className={styles.specialNeedsTitle}>Special Needs</span>
        <p className={styles.specialNeedsDesc}>
          Special needs requests are associated with individual tickets. If you
          have multiple tickets requiring special requests, please ensure you
          mark each ticket with the appropriate response to secure your place in
          the accessible seating sections. We cannot guarantee availability for
          onsite requests.
        </p>
        {showLoader ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <form className={styles.specialNeedsOptions}>
            {/* Accessible seating */}
            <input
              type="checkbox"
              id={options.accessible.seating.id}
              checked={
                accessibleState &&
                accessibleState.length > 0 &&
                accessibleState[0] === options.accessible.seating.id
              }
              onChange={() =>
                handleAccessibleCheckboxClick(options.accessible.seating.id)
              }
              // disabled={showMessage && showCloseButton}
            />
            <label htmlFor={options.accessible.seating.id}>
              I require accessible seating
            </label>
            <span className={styles.specialNeedsOr}>or</span>
            {/* Accessible companion */}
            <input
              type="checkbox"
              id={options.accessible.companion.id}
              checked={
                accessibleState &&
                accessibleState.length > 0 &&
                accessibleState[0] === options.accessible.companion.id
              }
              onChange={() =>
                handleAccessibleCheckboxClick(options.accessible.companion.id)
              }
              // disabled={showMessage && showCloseButton}
            />
            <label htmlFor={options.accessible.companion.id}>
              I require an accessible seating companion ticket
            </label>
            <hr />
            {/* Hearing device */}
            <input
              type="checkbox"
              id={options.hearing.device.id}
              checked={
                hearingState &&
                hearingState.length > 0 &&
                hearingState[0] === options.hearing.device.id
              }
              onChange={() =>
                handleHearingCheckboxClick(options.hearing.device.id)
              }
              // disabled={showMessage && showCloseButton}
            />
            <label htmlFor={options.hearing.device.id}>
              I require use of a hearing amplification device
            </label>
            <span className={styles.specialNeedsOr}>or</span>
            {/* Hearing interpreter */}
            <input
              type="checkbox"
              id={options.hearing.interpreter.id}
              checked={
                hearingState &&
                hearingState.length > 0 &&
                hearingState[0] === options.hearing.interpreter.id
              }
              onChange={() =>
                handleHearingCheckboxClick(options.hearing.interpreter.id)
              }
              // disabled={showMessage && showCloseButton}
            />
            <label htmlFor={options.hearing.interpreter.id}>
              I require use of an American Sign Language Interpreter (at least 4
              weeks advance notice is required)
            </label>
            <span className={styles.specialNeedsOr}>or</span>
            {/* Hearing companion */}
            <input
              type="checkbox"
              id={options.hearing.companion.id}
              checked={
                hearingState &&
                hearingState.length > 0 &&
                hearingState[0] === options.hearing.companion.id
              }
              onChange={() =>
                handleHearingCheckboxClick(options.hearing.companion.id)
              }
              // disabled={showMessage && showCloseButton}
            />
            <label htmlFor={options.hearing.companion.id}>
              I require a hearing accessible seating companion ticket
            </label>
            <hr />
            {/* Nursing mother option */}
            <input
              type="checkbox"
              id={options.nursingMother.id}
              checked={isNursingMother}
              onChange={handleNursingMotherCheckboxClick}
              // disabled={showMessage && showCloseButton}
            />
            <label htmlFor={options.nursingMother.id}>
              I am a nursing mother
            </label>
          </form>
        )}

        {message && showMessage && !showLoader && !changesMade && (
          <div className={messageClass}>{message}</div>
        )}
        {/* {showCloseButton && (
            <div className={styles.containerCloseButton}>
              <Button
                label="Close"
                className={styles.closeButton}
                onClick={handleClose}
              />
            </div>
          )} */}

        {/* Buttons */}
        <div className={styles.containerSpecialNeedsButtons}>
          {!showLoader && (
            <Button
              label="Save"
              className={styles.onSaveButton}
              onClick={handleSave}
            />
          )}
          <Button
            label={(showCloseButton || showLoader) && !changesMade ? "Close" : "Cancel"}
            className={styles.onCancelButton}
            buttonStyle="hollow"
            onClick={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

SpecialNeedsModal.propTypes = {
  ticket: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
