import PropTypes from "prop-types";
import classNames from "classnames";
import L_Logo from "./L-Logo.png";
import styles from "./EventImage.module.css";

/**
 * View component that displays the event image and border
 */
export function EventImage({ image, ruleset }) {
  const eventBorderColor = (input) => {
    if (input === "Major") {
      return styles.majorBorderColor;
    } else if (input === "Local") {
      return styles.localBorderColor;
    } else if (input === "LOS") {
      return styles.losBorderColor;
    }
  };

  return (
    <>
      <div
        className={classNames(
          styles.eventImageBorder,
          eventBorderColor(ruleset)
        )}
      >
        <div className={styles.whiteBackground}>
          <img
            src={image}
            className={classNames(styles.eventImage, ruleset == "Local" && styles.local)}
            alt="Event Logo"
          ></img>
        </div>
      </div>
    </>
  );
}

EventImage.propTypes = {
  image: PropTypes.string, //Image source string
  ruleset: PropTypes.string, //Dictates background color
};
