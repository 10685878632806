import { Auth } from "shared/@auth/Auth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import createNotification from "shared/@utils/createNotification";

export const useViewEvents = (eventId) => {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, isError, isLoading, refetch } = useQuery(
    ["event", eventId],
    () =>
      makeAuthedRequest(`v1/events/id/${eventId}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  return {
    data,
    refetch,
    isError,
    isLoading,
  };
};
