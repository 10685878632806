import React from "react";
import PropTypes from "prop-types";

import Button from "shared/Button";

import styles from "./TicketGroupItem.module.css";

/**Displays the information about a ticket option. When edit is clicked it will open the edit ticket modal. */
export function TicketGroupItem({ ticket, onEdit, isMajorV1 }) {
  return isMajorV1 ? (
    <div className={styles.ticketGroupItemContainerV1}>
      <span>{ticket.ticketId}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span className={styles.price}>{ticket.cancelPrice}</span>
      {/* <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button> */}
    </div>
  ) : (
    <div className={styles.ticketGroupItemContainer}>
      <span>{ticket.id}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span>{`${ticket.startDate} ${ticket.startTime}`}</span>
      <span>{`${ticket.endDate} ${ticket.endTime}`}</span>
      <span className={styles.timezone}>{ticket.timeZone}</span>
      <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button>
    </div>
  );
}

TicketGroupItem.propTypes = {
  /**Object with the tickets info (id-String, name-String, price-Number, timeZone-String, startDate-String, endDate-String, startTime-String, endTime-String)  */
  ticket: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    timeZone: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  /**Function called to edit the ticket information. Opens the edit ticket modal */
  onEdit: PropTypes.func,
  isMajorV1: PropTypes.bool,
};
