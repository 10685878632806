import React from "react";
import PropTypes from "prop-types";
import { Modal } from "shared/Modal";
import Button from "shared/Button";

import styles from "./EventPublishModal.module.css";

/**
 * Modal that opens after an event is published
 */
export function EventPublishModal({ isOpen, onCollapse, onView, onClick }) {
  return (
    <div className={styles.container}>
      <Modal
        title="PUBLISHING EVENT"
        isOpen={isOpen}
        onClose={onCollapse}
        closeBtn="none"
        className={styles.modal}
        children={
          <>
            <div className={styles.eventSavedModalText}>
              Event is being published.
            </div>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.eventDashboardButton}
                children="Event Dashboard"
                size="small"
                onClick={() => onClick()}
              />
              <Button
                className={styles.viewEventButton}
                children="View Event"
                size="small"
                onClick={() => onView()}
              />
            </div>
          </>
        }
      />
    </div>
  );
}

EventPublishModal.propTypes = {
  /**
   * Getter to determine if modal is displayed
   */
  isOpen: PropTypes.bool,
  /**
   * Callback function for when the modal is closed
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when the view event btn is clicked
   */
  onView: PropTypes.func,
  /**
   * Function called when the event dashboard btn is clicked
   */
  onClick: PropTypes.func,
};
