import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DateRangePicker.module.css";
import chevron from "./chevron.png";
import { useState, useEffect } from "react";

/**
 * Interactive component, lets the user pick a date from a calendar
 */
export function DateRangePicker({
  startDateString,
  endDateString,
  setStartDate,
  setEndDate,
  allowPastDate,
  size,
  allowRangeSelection = true,
}) {
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);

  const [currentMonth, setMonth] = useState(null); // current month the calendar is on
  const [currentYear, setYear] = useState(null);
  const [days, setDays] = useState([]);
  useEffect(() => {
    var d = new Date();
    setMonth(d.getMonth());
    setYear(d.getUTCFullYear());

    if (startDateString) {
      var sd = new Date(startDateString);
      setLocalStartDate(sd);
      setMonth(sd.getMonth());
      setYear(sd.getUTCFullYear());

      if (endDateString) {
        var ed = new Date(endDateString);
        setLocalEndDate(ed);
      }
    }
  }, [startDateString, endDateString]);

  useEffect(() => {
    if (localStartDate || localEndDate) {
      if (localStartDate) setStartDate(formatDate(localStartDate));
      if (localEndDate) setEndDate(formatDate(localEndDate));
    }

    if (currentMonth != null && currentYear != null) {
      setDays(getDays(currentMonth + 1, currentYear));
    }
  }, [localStartDate, localEndDate, currentMonth, currentYear]);
  useEffect(() => {
    if (!startDateString) {
      setLocalStartDate(null);
      setLocalEndDate(null);
      var d = new Date();
      setMonth(d.getMonth());
      setYear(d.getUTCFullYear());
    } else {
      var sd = new Date(startDateString);
      var ed = new Date(endDateString ? endDateString : startDateString);
      setLocalStartDate(sd);
      setLocalEndDate(ed);
      setMonth(sd.getMonth());
      setYear(sd.getUTCFullYear());
    }
  }, []);

  useEffect(() => {
    if (localStartDate && localEndDate) {
      setStartDate(formatDate(localStartDate));
      setEndDate(formatDate(localEndDate));
    }
    if (currentMonth && currentYear) {
      setDays(getDays(currentMonth + 1, currentYear));
    }
  }, [localStartDate, localEndDate]);

  useEffect(() => {
    if (currentMonth != null && currentYear != null) {
      setDays(getDays(currentMonth + 1, currentYear));
    }
  }, [currentMonth, currentYear]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function incrementMonth() {
    var nextMonthDate = new Date(currentMonth + 1 + "/01/" + currentYear);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    setMonth(nextMonthDate.getMonth());
    setYear(nextMonthDate.getUTCFullYear());
  }
  function decrementMonth() {
    var lastMonthDate = new Date(currentMonth + 1 + "/01/" + currentYear);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    setMonth(lastMonthDate.getMonth());
    setYear(lastMonthDate.getUTCFullYear());
  }

  function isDateBeforeToday(date) {
    if (allowPastDate !== true) {
      var todaysDate = new Date();
      if (date.getTime() < todaysDate.getTime()) {
        if (
          date.getYear() == todaysDate.getYear() &&
          date.getMonth() == todaysDate.getMonth() &&
          date.getDate() == todaysDate.getDate()
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
  }

  function isDateBeforeDate(date, date2) {
    if (date.getTime() < date2.getTime()) {
      if (
        date.getYear() == date2.getYear() &&
        date.getMonth() == date2.getMonth() &&
        date.getDate() == date2.getDate()
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  function isDateAfterDate(date, date2) {
    if (date.getTime() > date2.getTime()) {
      if (
        date.getYear() == date2.getYear() &&
        date.getMonth() == date2.getMonth() &&
        date.getDate() == date2.getDate()
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  function getDays(month, year) {
    var sDate = new Date(year, month - 1, 1);

    // Adjust start date to the first day of the week
    while (sDate.getDay() !== 1) {
      sDate.setDate(sDate.getDate() - 1);
    }

    var currentDate = new Date(sDate);
    var d = [];
    for (var i = 0; i < 42; i++) {
      d.push({
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear(),
        dateString: currentDate.toISOString().split("T")[0], // ISO String format for consistency
        grayedOut: currentDate.getMonth() !== month - 1,
        disabled: isDateBeforeToday(currentDate),
        highlighted:
          isSameDay(currentDate, localStartDate) ||
          isSameDay(currentDate, localEndDate),
        rangeHighlight: isInRange(currentDate, localStartDate, localEndDate),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return d;
  }

  function isSameDay(d1, d2) {
    return (
      d1 &&
      d2 &&
      d1.toISOString().split("T")[0] === d2.toISOString().split("T")[0]
    );
  }
  function setDates(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    // Create a date object in the local timezone
    var selectedDate = new Date(year, month - 1, day);

    // Get the timezone offset in minutes and convert it to milliseconds
    const timezoneOffset = selectedDate.getTimezoneOffset() * 60000;

    // Adjust the date by the timezone offset to ensure correct day selection
    selectedDate = new Date(selectedDate.getTime() + timezoneOffset);

    setLocalStartDate((prevStartDate) => {
      // If no start date is selected, or the selected date becomes the new start date
      if (!prevStartDate || (localEndDate && selectedDate < localEndDate)) {
        setLocalEndDate(null);
        return selectedDate;
      }
      // If the selected date is after the start date, set it as the end date
      else if (selectedDate > prevStartDate) {
        setLocalEndDate(selectedDate);
      }
      return prevStartDate;
    });
  }

  function isInRange(date, start, end) {
    return start && end && date > start && date < end;
  }

  function formatDate(date) {
    return (
      monthNames[date.getMonth()] +
      " " +
      date.getDate() +
      ", " +
      date.getUTCFullYear()
    );
  }

  // function setDates(dateString) {
  //   const [year, month, day] = dateString.split("-").map(Number);
  //   var selectedDate = new Date(Date.UTC(year, month - 1, day));

  //   setLocalStartDate((prevStartDate) => {
  //     if (
  //       !prevStartDate ||
  //       selectedDate < prevStartDate ||
  //       (prevStartDate && localEndDate)
  //     ) {
  //       setLocalEndDate(null);
  //       return selectedDate;
  //     } else if (selectedDate >= prevStartDate) {
  //       setLocalEndDate(selectedDate);
  //     }
  //     return prevStartDate;
  //   });
  // }

  function clearDates() {
    setLocalStartDate(null);
    setLocalEndDate(null);

    setStartDate(false);
    setEndDate(false);

    setDays(getDays(currentMonth + 1, currentYear));
  }

  return (
    <div className={classNames(styles.container)}>
      <img
        src={chevron}
        className={classNames(styles.arrow, styles.rightArrow)}
        onClick={() => incrementMonth()}
      />
      <img
        src={chevron}
        className={classNames(styles.arrow, styles.leftArrow)}
        onClick={() => decrementMonth()}
      />

      <div
        className={classNames(
          styles.monthYear,
          size == "small" ? styles.monthYear2 : ""
        )}
      >
        {monthNames[currentMonth] + " " + currentYear}
      </div>

      <div className={classNames(size == "small" ? styles.grid2 : styles.grid)}>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          Mo
        </div>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          Tu
        </div>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          We
        </div>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          Th
        </div>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          Fr
        </div>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          Sa
        </div>
        <div
          className={classNames(
            size == "small" ? styles.gridItem2 : styles.gridItem,
            styles.grayedOut
          )}
        >
          Su
        </div>

        {days.map((day, index) => (
          <div
            key={index}
            className={classNames(
              size == "small" ? styles.gridItem2 : styles.gridItem,
              styles.days,
              day.grayedOut ? styles.grayedOut : "",
              day.disabled ? styles.disabled : "",
              day.highlighted
                ? size == "small"
                  ? styles.highlighted2
                  : styles.highlighted
                : ""
            )}
            onClick={day.disabled ? null : () => setDates(day.dateString)}
          >
            {day.rangeHighlight ? (
              <div
                className={
                  size == "small"
                    ? styles.rangeHighlight2
                    : styles.rangeHighlight
                }
              />
            ) : (
              ""
            )}
            {day.day}
          </div>
        ))}
      </div>

      <button className={styles.button} onClick={() => clearDates()}>
        Clear
      </button>
    </div>
  );
}

DateRangePicker.propTypes = {
  startDateString: PropTypes.string,
  endDateString: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};
