import classNames from "classnames";
import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "./index.module.css";

/**
 * A generic `<input />` element with built in styles.
 */
export default React.forwardRef(function Input(
  { className, isValid, ...props },
  ref
) {
  /**
   * Track if interaction has happened. Use this to avoid
   * marking a field as invalid until the user has interacted.
   */
  const [fresh, setFresh] = useState(true);

  return (
    <input
      ref={ref}
      className={classNames(
        styles.input,
        className,
        fresh ? styles.fresh : null
        // isValid == true ? styles.invalid : null
      )}
      {...props}
      onInput={(e) => {
        if (fresh) {
          setFresh(false);
        }

        if (props.onInput) {
          props.onInput(e);
        }
      }}
    />
  );
});
