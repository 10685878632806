import React from "react";
import styles from "./ADACard.module.css";
import InfoItem from "Events/Components/InfoItem";
import Card from "shared/Card";
import PropTypes from "prop-types";

/**
 * Interactive component that displays the ADA Card
 */
export function ADACard({ onClick, entries }) {
  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.cardHeader}>ADA</div>

          {/* <Button
            className={classNames(styles.editBtn, cardStyles.cardLightButton)}
            size="small"
            children="Edit"
            onClick={() => onClick(entries)}
          /> */}
        </div>

        <div className={styles.hr} />
        {entries.map((entry, index) => {
          return (
            <div key={index}>
              <InfoItem
                label={`ADA Question #${index + 1}`}
                description={entry}
              />
            </div>
          );
        })}
      </div>
    </>
  );

  return <Card children={cardContents} />;
}
ADACard.propTypes = {
  /**
   * Function that handles the edit button
   */
  onClick: PropTypes.func,
  /**
   * Array of questions to display
   */
  entries: PropTypes.arrayOf(PropTypes.string),
};
