import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./RulesetList.module.css";
import RulesetCard from "../RulesetCard";

/**
 * Interactive component. Displays a list of all rulesets with a header and pagination on bottom
 */
export function RulesetList({ rulesets }) {
  useEffect(() => {}, [rulesets]);

  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedRulesets, setPaginatedRulesets] = useState(rulesets);
  const [filteredRulesets, setFilteredRulesets] = useState(rulesets);
  const [selectedEventName, setSelectedEventName] = useState("");
  const [isFromRuleset, setIsFromRuleset] = useState(false);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let resultsPerPage = 3;

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * resultsPerPage;
    const lastPageIndex = firstPageIndex + resultsPerPage;
  }, [currentPage]);

  const handleCreateEvent = (eventName, fromRuleset) => {
    setSelectedEventName(eventName);
    setIsFromRuleset(fromRuleset);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.rulesetListContainer}>
          {rulesets.map((ruleset, index) => (
            <div className={styles.rulesetContainer} key={index}>
              <RulesetCard
                rules={ruleset.rules}
                events={ruleset.events}
                name={ruleset.name}
                id={ruleset._id}
                // onCreateEvent={handleCreateEvent}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

RulesetList.propTypes = { rulesets: PropTypes.array };
