import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Plus } from "react-ikonate";

import styles from "./ImagePicker.module.css";

/**
 * Interactive component that asks the user for access to their device and opens an image uploader
 */
export function ImagePicker({ changeImageItem }) {
  const [uploadedImage, setUploadedImage] = useState(null);

  const inputRef = useRef(null);

  const handleOpenFileBrowser = () => {
    inputRef.current.click();
  };

  const handleImageUpload = (event) => {
    // console.log(event.target.files[0]);
    validateUpload(event.target.files[0]);
  };

  const validateUpload = (file) => {
    if (
      file.type == "image/png" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif"
    ) {
      setUploadedImage(file);
    }
  };

  useEffect(() => {
    changeImageItem(uploadedImage);
  }, [uploadedImage, changeImageItem]);

  return (
    <div className={styles.imagePickerContainer}>
      {uploadedImage ? (
        <>
          <label className={styles.imagePickerLabel}>Uploaded Photo</label>
          <div className={styles.imageName}>{uploadedImage.name}</div>
        </>
      ) : (
        <>
          <label className={styles.imagePickerLabel} htmlFor="imgPicker">
            Upload Photo
          </label>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            id="imgPicker"
            name="imgPicker"
            accept="image/*"
            onChange={(event) => {
              handleImageUpload(event);
            }}
          />

          <button
            className={styles.imagePickerUploadButton}
            type="file"
            id="imgPicker"
            name="imgPicker"
            accept="image/*"
            onClick={() => {
              handleOpenFileBrowser();
            }}
          >
            <Plus className={styles.imagePickerPlus} borderWidth={5} />
          </button>
        </>
      )}
    </div>
  );
}

ImagePicker.propTypes = {
  /**
   * Function that executes the saving of new image to the global variable
   */
  changeImageItem: PropTypes.func,
};
