import React, { useState } from "react";
import { Select } from "../../../shared/Select/Select";
import { SearchBar } from "../../../shared/SearchBar/SearchBar";
import { Button } from "../../../shared/Button/Button";
import styles from "./FilterRuleset.module.css";
import PropTypes from "prop-types";

/**
 * A component that can be used to filter events by rulesets
 * and event name.
 */

export function FilterRuleset({ onClick, rulesetData, eventOptions }) {
  const [currentChoice, setCurrentChoice] = useState("");

  const [filterData, changeFilterData] = useState({
    ruleset: "",
    event: "",
    search: "",
  });

  const handleRulesetSelection = (selection) => {
    changeFilterData({ ...filterData, ruleset: selection, event: "" });
  };

  const handleEventSelection = (selection) => {
    changeFilterData({ ...filterData, event: selection });
  };

  const handleSearchInput = (value) => {
    changeFilterData({ ...filterData, search: value });
  };

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <label className={styles.label}>Ruleset</label>
        <Select
          options={Object.keys(rulesetData)}
          placeholder={filterData.ruleset || "All"}
          onChange={(option) => {
            setCurrentChoice(option);
            handleRulesetSelection(option);
          }}
        />
      </div>
      <div className={styles.select}>
        <label className={styles.label}>Event Name</label>
        <Select
          options={eventOptions}
          // options={["All"]}
          placeholder={filterData.event || "All"}
          onChange={(option) => {
            handleEventSelection(option);
          }}
        />
      </div>
      <div className={styles.search}>
        <SearchBar
          placeholderText="Click to type"
          minChar={0}
          onInput={handleSearchInput}
        />
      </div>
      <div className={styles.button}>
        <Button
          className={styles.updateBtn}
          children="UPDATE"
          onClick={() => onClick(filterData)}
        />
      </div>
    </div>
  );
}

FilterRuleset.propTypes = {
  // function to be called when selecting a dropdown filter item
  onClick: PropTypes.func,

  resultData: PropTypes.shape({}),
};
