import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./TimePicker.module.css";
import TextInput from "shared/@forms/TextInput";
import { Time } from "react-ikonate";
import { Modal } from "shared/Modal";
import { HoursList } from "Events/Components/TimePicker/HoursList/HoursList";
import moment from "moment";

/**
 * Interactive component. Allows the user to type in the time and toggle am/pm
 */
export function TimePicker({ timeString, onChange, onBlur }) {
  const [isOpen, setIsOpen] = useState(false);
  const [localTimeString, setLocalTimeString] = useState("");

  useEffect(() => {
    if (timeString) {
      setLocalTimeString(timeString);
    }
  }, [timeString]);

  const timeStringToArray = (time) => {
    if (!time || typeof time !== "string") {
      return [0, 0];
    }

    const momentTime = moment(time, "HH:mm");

    return [momentTime.hour(), momentTime.minute()];
  };

  const getAMPM = (time) => {
    if (typeof time !== "string" || time === "") return true;
    const [hourStr] = time.split(":");
    const hour = parseInt(hourStr, 10) % 24;

    return !(hour >= 12 && hour < 24);
  };

  const [isAM, setIsAM] = useState(getAMPM(timeString));

  // Update isAM when timeString changes
  useEffect(() => {
    setIsAM(getAMPM(localTimeString));
  }, [localTimeString]);

  const toggleAMPM = () => {
    if (!localTimeString) return;

    const [hour, minute] = timeStringToArray(localTimeString);
    let newHour;

    if (isAM) {
      newHour = hour + 12;
    } else {
      newHour = hour - 12;
    }

    const formattedTime = `${String(newHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;
    onChange(formattedTime);
  };

  //Convert hour format to display 12 hr format(01:00 PM)
  const convertTo12HourFormat = (time) => {
    if (!time || typeof time !== "string") return "";

    const [hour, minute] = time.split(":").map(Number);

    let displayHour = hour % 12;
    if (displayHour === 0) {
      displayHour = 12;
    }

    return `${displayHour}:${String(minute).padStart(2, "0")} `;
  };

  //Handles selection in hourslist
  const handleTimeSelect = (value) => {
    const [selectedHour, selectedMinute] = value;

    setIsAM(selectedHour < 12);

    const formattedTime = `${String(selectedHour).padStart(2, "0")}:${String(
      selectedMinute
    ).padStart(2, "0")}`;

    setLocalTimeString(formattedTime);
    setIsOpen(false);

    // onBlur && onBlur();
    onChange(formattedTime);
  };

  const displayTimeString = convertTo12HourFormat(localTimeString);

  return (
    <>
      <div className={styles.tpContainer}>
        <div className={styles.timeIcon}>
          <Time
            fontSize={12}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>

        <TextInput
          className={styles.tpInput}
          value={displayTimeString}
          placeholder="12:00"
          maxLength="5"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <div className={styles.ampmButtonContainer}>
          <button
            className={styles.amButton}
            style={
              isAM
                ? { backgroundColor: "#F4C579" }
                : { backgroundColor: "transparent" }
            }
            onClick={toggleAMPM}
          >
            AM
          </button>
          <button
            className={styles.pmButton}
            style={
              isAM
                ? { backgroundColor: "transparent" }
                : { backgroundColor: "#9AB5BB" }
            }
            onClick={toggleAMPM}
          >
            PM
          </button>
        </div>

        <Modal
          title="CHOOSE TIME"
          isOpen={isOpen}
          closeBtn=""
          onClose={() => {
            setIsOpen(false);
          }}
          className={styles.modal}
        >
          <div className={styles.popOverMenu}>
            <section className={styles.section}>
              <header>
                <h3>Day</h3>
              </header>
              <HoursList
                value={timeStringToArray(localTimeString)}
                startHour={6}
                onSelect={handleTimeSelect}
              />
            </section>
            <section className={`${styles.section} ${styles.night}`}>
              <header>
                <h3>Night</h3>
              </header>
              <HoursList
                value={timeStringToArray(localTimeString)}
                startHour={18}
                onSelect={handleTimeSelect}
              />
            </section>
          </div>
        </Modal>
      </div>
    </>
  );
}

TimePicker.propTypes = {
  timeString: PropTypes.string, // Current time being displayed
  onChange: PropTypes.func, // Function called once the time changes
};
