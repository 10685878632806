import React from "react";
import classNames from "classnames";
import styles from "./index.module.css";
import { useTimeout } from "react-use";

import Loader from "shared/Loader";

/**
 * @typedef LoadingPageProps
 * @type {Object}
 *
 * @property {string} text - A custom string to display under the loading indicator.
 * @property {number} delay - Milliseconds to wait before fading in the loading indicator (prevents flashing).
 */

/**
 * A full page loader component.
 *
 * @param {LoadingPageProps} props - Options for the loader.
 */
export default function LoadingPage({ text = "Loading", delay = 200 } = {}) {
  const [isVisible] = useTimeout(delay);

  return (
    <div
      className={classNames(styles.container, {
        [styles.visible]: isVisible(),
      })}
    >
      <div className={styles.loader}>
        <Loader />
      </div>
      <p className={styles.label}>{text}</p>
    </div>
  );
}
