import React from "react";
import PropTypes from "prop-types";
import styles from "./InfoItem.module.css";

/**
 * View item used to display information on cards
 */
export function InfoItem({ label, description }) {
  return (
    <>
      <div className={styles.infoItem}>
        <div className={styles.infoItemLabel}>{label}</div>
        <div className={styles.infoItemDescription}>{description}</div>
      </div>
    </>
  );
}

InfoItem.propTypes = {
  label: PropTypes.string, //Info Item Label
  description: PropTypes.string, // Info Item Description
};
