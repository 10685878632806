import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./VisibleToModal.module.css";
import classNames from "classnames";
import { Modal } from "shared/Modal";
import SearchBar from "shared/SearchBar";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
import Button from "shared/Button";

/**
 * Modal that opens when changing the visibility of an event
 */
export function VisibleToModal({ isOpen, onCollapse, onClick }) {
  const LOSmembers = [
    {
      name: "John And Jane Smith",
      iboNum: "1234",
      userName: "smith.john1",
      email: "john.smith@email.com",
      lastScheduled: "01/22/2022",
      pinLevel: "Emerald",
    },
    {
      name: "George Smith",
      iboNum: "5678",
      userName: "smith.george1",
      email: "george.smith@email.com",
      lastScheduled: "01/22/2022",
      pinLevel: "Platinum",
    },

    {
      name: "Barry and Jill Clark",
      iboNum: "2222",
      userName: "clark.barry",
      email: "barry.clark@email.com",
      lastScheduled: "03/22/2022",
      pinLevel: "Emerald",
    },
    {
      name: "Barbara and Tom Gifford",
      iboNum: "3333",
      userName: "gifford.barbara",
      email: "barbara.giffogfsgsgsgsgrd@email.com",
      lastScheduled: "04/22/2022",
      pinLevel: "Diamond",
    },
  ];

  const [sortedLOS, setSortedLOS] = useState(LOSmembers);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [addLOSResultName, setAddLOSResultName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  //Function handles filtering and rendering
  function onSearch(searchText) {
    let term = searchTerm.toString().toLowerCase();
    if (term !== "") {
      const filteredLOS = LOSmembers.filter(function (los) {
        return (
          los.name.toLowerCase().includes(term) ||
          los.iboNum.toString().toLowerCase().includes(term) ||
          los.email.toString().toLowerCase().includes(term)
        );
      });
      setSortedLOS(filteredLOS);

      if (filteredLOS.length > 0) {
        setAddLOSResultName(filteredLOS[0].name);
      } else {
        setAddLOSResultName("");
      }
    } else {
      setSortedLOS(LOSmembers);
      setAddLOSResultName("");
    }
    setHasSearched(true);
  }

  const onClose = () => {
    setSortedLOS([]);
    onCollapse();
  };

  return (
    <>
      <Modal
        title="VISIBLE TO"
        isOpen={isOpen}
        onClose={onClose}
        className={styles.modal}
        children={
          <>
            <div className={styles.searchContainer}>
              <span className={styles.title}>LOS Lookup</span>
              <span className={styles.gray}> (Name, IBO, email, etc)</span>
              <div className={styles.searchBox}>
                <SearchBar
                  placeholderText="Click to type"
                  onInput={(value) => {
                    setSearchTerm(value);
                    if (value === "") {
                      setSortedLOS([]);
                      setHasSearched(false);
                    }
                  }}
                  searchDelay={1}
                  minChar={3}
                />
                <div className={styles.buttonContainer}>
                  <button
                    className={classNames(styles.searchButton)}
                    onClick={onSearch}
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.title}>LOS LOOKUP</div>

            <div className={styles.losContainer}>
              {hasSearched ? (
                <>
                  {sortedLOS.map((los) => (
                    <div key={los.iboNum} className={styles.grid}>
                      {/* <div className={styles.losGrid}> */}
                      <div className={styles.item}>Name:</div>
                      <div className={styles.value}>{los.name}</div>
                      <div className={styles.item}>IBO Number:</div>
                      <div className={styles.value}>{los.iboNum}</div>
                      {/* </div> */}

                      {/* <div className={styles.losGrid}> */}
                      <div className={styles.item}>Username:</div>
                      <div className={styles.value}>{los.userName}</div>
                      <div className={styles.item}>Email:</div>
                      <div className={styles.value}>{los.email}</div>
                      {/* </div> */}
                    </div>
                  ))}

                  <div className={styles.hr} />

                  <div className={classNames(styles.bold, styles.selectTitle)}>
                    SELECT:
                  </div>
                  <div className={styles.radioButtonList}>
                    <RadioButtonList
                      onClick={(value) => setSelectedOption(value)}
                      options={
                        addLOSResultName
                          ? [
                              "Diamondship",
                              "Emeraldship",
                              "Platinumship",
                              addLOSResultName,
                            ]
                          : ["Diamondship", "Emeraldship", "Platinumship"]
                      }
                    />
                  </div>
                </>
              ) : (
                <div className={styles.losBox}>
                  <div className={styles.item}>Name</div>
                  <div className={styles.item}>Username</div>
                  <div className={styles.item}>IBO Number</div>
                  <div className={styles.item}>Email</div>
                </div>
              )}
            </div>

            <div className={styles.addButton}>
              <Button children="ADD" onClick={onClick} />
            </div>
          </>
        }
      />
    </>
  );
}

VisibleToModal.propTypes = {
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
};
