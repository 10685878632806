import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./ViewEventManagePage.module.css";
import Header from "shared/Header";
import Button from "shared/Button";
import EventTypeCard from "Events/V1Components/EventTypeCard";
import { EventInfoCard } from "Events/Components/EventInfoCard/EventInfoCard";
import { PublishingInfoCard } from "Events/Components/PublishingInfoCard/PublishingInfoCard";
import { VenueCard } from "Events/Components/VenueCard/VenueCard";
import { VirtualCard } from "Events/Components/VirtualCard/VirtualCard";
import { ADACard } from "Events/Components/ADACard/ADACard";

import { useHistory } from "react-router-dom";

import { useViewEvents } from "./useViewEvents";
import Loader from "shared/Loader";
import moment from "moment";

export function ViewEventManagePage() {
  let history = useHistory();
  const { eventId } = useParams();

  const { data, isError, isLoading, refetch } = useViewEvents(eventId);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    refetch();
  }, [eventId, refetch]);

  const [eventInfo, setEventInfo] = useState({
    event: {
      name: "",
      ruleset: "",
      location: "",
      jobCode: "",
    },
    marketing: {
      title: "",
      description: "",
      location: "",
      startDate: "",
      endDate: "",
      startTimeZone: "",
      scannerStartDate: "",
      scannerTimeZone: "",
    },
  });

  useEffect(() => {
    data && setEvent(data);
  }, [data]);

  useEffect(() => {
    setEventInfo({
      //event
      event: {
        name:
          event && event.event && event.event.name.value
            ? event.event.name.value
            : "",
        ruleset:
          event && event.event && event.event.ruleset.value
            ? event.event.ruleset.value
            : "",
        location:
          event && event.event && event.event.location.value
            ? event.event.location.value
            : "",
        jobCode:
          event &&
          event.event &&
          event.event.jobCode &&
          event.event.jobCode.value
            ? event.event.jobCode.value
            : "",
      },
      //marketing
      marketing: {
        title:
          event &&
          event.marketing &&
          event.marketing.title &&
          event.marketing.title.value
            ? event.marketing.title.value
            : "",
        description:
          event &&
          event.marketing &&
          event.marketing.description &&
          event.marketing.description.value
            ? event.marketing.description.value
            : "",
        location:
          event &&
          event.marketing &&
          event.marketing.location &&
          event.marketing.location.value
            ? event.marketing.location.value
            : "",
        startDate:
          event &&
          event.marketing &&
          event.marketing.startDate &&
          event.marketing.startDate.value
            ? event.marketing.startDate.value
            : "",
        endDate:
          event &&
          event.marketing &&
          event.marketing.endDate &&
          event.marketing.endDate.value
            ? event.marketing.endDate.value
            : "",
        startTimeZone:
          event &&
          event.marketing &&
          event.marketing.startTimeZone &&
          event.marketing.startTimeZone.value
            ? event.marketing.startTimeZone.value
            : "",
        scannerStartDate:
          event &&
          event.marketing &&
          event.marketing.scannerStartDate &&
          event.marketing.scannerStartDate.value
            ? event.marketing.scannerStartDate.value
            : "",
        scannerTimeZone:
          event &&
          event.marketing &&
          event.marketing.scannerTimeZone &&
          event.marketing.scannerTimeZone.value
            ? event.marketing.scannerTimeZone.value
            : "",
      },
    });
  }, [event]);

  // Dates and time format
  function formatDate(dateString, format) {
    if (!dateString) return "";

    const date = moment(dateString);
    if (!date.isValid()) return "";

    return date.format(format);
  }

  const formattedStartDate = formatDate(
    eventInfo.marketing.startDate,
    "MMM DD, YYYY hh:mm A"
  );
  const formattedEndDate = formatDate(
    eventInfo.marketing.endDate,
    "MMM DD, YYYY hh:mm A"
  );
  const formattedScannerStartDate = formatDate(
    eventInfo.marketing.scannerStartDate,
    "MMM DD, YYYY hh:mm A"
  );

  //Redirect
  const handleManageInvitees = (sessions) => {
    history.push("/events/manage-invitees");
  };

  const handleEditEvent = () => {
    history.push(`/events/edit/${eventId}`);
  };

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.pageHeader}>
          {eventInfo.event.ruleset === "Major v1.0" ? (
            <>
              <Header
                title={eventInfo.marketing.title}
                onClick={() => history.goBack()}
              />
            </>
          ) : (
            <>
              <Header
                title={eventInfo.marketing.title}
                onClick={() => history.goBack()}
                children={
                  <>
                    <div className={styles.headerRight}>
                      <div className={styles.jobCode}>
                        <span className={styles.bold}>Job Code: </span>
                        {eventInfo.event.jobCode}
                      </div>
                    </div>
                  </>
                }
              />
              <p className={styles.eventTime}>
                {formattedStartDate || formattedEndDate ? (
                  <>
                    {formattedStartDate} - {formattedEndDate} (
                    {eventInfo.marketing.startTimeZone})
                  </>
                ) : null}
              </p>
            </>
          )}
        </div>
        <div className={styles.pageContent}>
          {!eventInfo.event.ruleset === "Major v1.0" && (
            <div className={styles.editEventButton}>
              <Button
                onClick={() => handleEditEvent()}
                className={styles.editButton}
              >
                EDIT EVENT
              </Button>
            </div>
          )}

          <div className={styles.cardContainer}>
            <>
              <EventTypeCard
                eventInfo={{
                  event: {
                    name: eventInfo.event.name,
                    ruleset: eventInfo.event.ruleset,
                    location: eventInfo.event.location,
                  },
                }}
              />
            </>
          </div>

          <div className={styles.cardContainer}>
            <EventInfoCard
              eventInfo={{
                name: eventInfo.marketing.title,
                description: eventInfo.marketing.description,
                location: eventInfo.marketing.location,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                timeZone: eventInfo.marketing.startTimeZone,
                scannerStartDate: formattedScannerStartDate,
                scannerTimeZone: eventInfo.marketing.scannerTimeZone,
              }}
              onClick={() => {
                alert("Event Info Edit");
              }}
            />
          </div>
          <div className={styles.cardContainer}>
            <PublishingInfoCard
              publishingInfo={{
                // purchaseBegin: "Apr.18, 2022 6:00 PM MST",
                // purchaseEnd: "Apr.19, 2022 6:00 PM MST",
                visibleOn: "Apr.18, 2022 6:00 PM MST",
                visibleTo: "All IBOs",
                tentative: "Yes",
              }}
            />
          </div>

          {eventInfo.location !== "Onsite" ? (
            <div className={styles.cardContainer}>
              <VenueCard
                onClick={() => {
                  alert("Venue Edit");
                }}
                venueInfo={{
                  address1: "1234 Address Way",
                  city: "City",
                  zip: "Zip Code",
                  capacity: "0000",
                  contactName: "First Last",
                  email: "email@address.com",
                  name: "Event Center Name",
                  phone: "000-000-0000",
                  roomName: "Room Name",
                  venueURL: "www.venueurl.com",
                }}
              />
            </div>
          ) : (
            <div className={styles.cardContainer}>
              <VirtualCard
                virtualInfo={{
                  eventType: "Zoom",
                  link: "www.linkToTheEvent.com",
                }}
                onClick={() => {
                  alert("Virtual Card");
                }}
              />
            </div>
          )}

          {/* <div className={styles.cardContainer}>
            <SessionsInfoCard
              onClick={() => {
                alert("Add Sessions");
              }}
              onEdit={() => {
                alert("Edit Sessions");
              }}
              onManageInvitees={handleManageInvitees}
              sessions={[
                {
                  capacity: "1000",
                  description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                  location: "Name of Room",
                  name: "Session #1 - Friday Afternoon",
                  speaker: "First Last",
                  startDate:
                    "Apr.18, 2022 6:00 PM - 7:00 PM - (TMZ) Name Time Zone",
                  visibility: "Public",
                },
                {
                  capacity: "50",
                  description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                  location: "Name of Room",
                  name: "Breakout Session #1 - Saturday Morning",
                  speaker: "First Last",
                  startDate: "Apr. 19, 2022 10:00 AM - 11:00 AM (TMZ)",
                  visibility: "Invite Only",
                },
              ]}
            />
          </div> */}
          {/* <div className={styles.cardContainer}>
            <TicketGroup
              tickets={ticketList}
              onClick={() => {
                alert("Add Ticketing");
              }}
            />
          </div> */}

          {/* <div className={styles.cardContainer}>
            <ADACard
              onClick={() => {
                alert("Edit ADAs");
              }}
              questions={[
                "Do you need wheelchair access?",
                "Do you need ______?",
                "Do you need ______?",
              ]}
            />
          </div> */}
          {/* 
          <div className={styles.cardContainer}>
            <EventHostsCard
              hosts={[
                {
                  number: "00000000",
                  name: "First Last",
                  userName: "username",
                  phoneNumber: "000-000-0000",
                  email: "email@address.com",
                  accessLevel: "Read Only",
                },
                {
                  number: "00000000",
                  name: "First Last",
                  userName: "username",
                  phoneNumber: "000-000-0000",
                  email: "email@address.com",
                  accessLevel: "Read Only",
                },
              ]}
              onClick={() => {
                alert("Add Event Host");
              }}
            />
          </div> */}
          {/* 
          <div className={styles.cardContainer}>
            <CircumstanceCard
              circumstances="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
              onClick={() => {
                alert("Edit Circumstances");
              }}
            />
          </div> */}
          {/* <div className={styles.cardContainer}>
            <NotesCard
              additionalNotes="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              notesToBePublished="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              onClick={() => {
                alert("Notes Edit");
              }}
            />
          </div> */}
        </div>
      </div>
    </>
  );
}
