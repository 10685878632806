import React from "react";
import styles from "./index.module.css";

/**
 * Action bar holds a set of right aligned buttons at the bottom of a modal.
 */

export default function ModalActionBar({ children, visible = true }) {
	return <div style={{visibility: visible ? "visible" : "hidden"}} className={styles.container}>{children}</div>;
}
