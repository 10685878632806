import React from "react";
import styles from "./EventItem.module.css";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Interactive item.
 * Displays an event's details with an "Add" button.
 */
export function EventItem({
  eventName,
  location,
  startDate,
  endDate,
  onClick,
}) {
  return (
    <div className={styles.flex}>
      <div>
        <p className={classNames(styles.bold, styles.event)}>{eventName}</p>
      </div>
      <p className={styles.location}>{location}</p>
      <p className={styles.date}>
        <span>{startDate}</span>
        {endDate && <span> - {endDate}</span>}
      </p>

      <div className={styles["button-container"]}>
        <button type="button" className={styles.button} onClick={onClick}>
          Add
        </button>
      </div>
    </div>
  );
}

EventItem.propTypes = {
  /**
   * Title of event.
   */
  eventName: PropTypes.string,
  /**
   * Location of event.
   */
  location: PropTypes.string,
  /**
   * Ticket sale start date.
   */
  startDate: PropTypes.string,
  /**
   * Ticket sale end date.
   */
  endDate: PropTypes.string,
  /**
   * Function that fires when the add button is clicked.
   */
  onClick: PropTypes.func,
};
