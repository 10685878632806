/**
 * Environment config is chosen automatically based on the hostname the app is served from.
 * This allows all regional deployments to use the same build with their own options.
 */

import { ConfigEnv } from "@wwg/config-env";

const env = new ConfigEnv()
  /**
   * Local development environment.
   */
  .forDomain("localhost", {
    /**
     * Base URL for WWG.Support API calls.
     */
    apiBase: "https://dev-api.wwg.support",
    // apiBase: "https://api-dev.wwg.support/api",
    // apiBase: "http://localhost:3750/api",
    /**
     * Base URL for Core API (shared API also used by customer-facing apps).
     */
    coreApiUrl: "https://app-core-web-dev.wwdb.com",

    /**
     * URL of WordPress CMS site for auto-login as IBO.
     */
    cmsUrl: "https://apps-dev.wwdb.com",

    /**
     * Title to be displayed in the browser tab and document.
     */
    pageTitle: "WWG Support - Local Dev",

    /**
     * Feature flags for various functionality throughout the app.
     * Not all environments share the same features.
     */
    flags: {
      /**
       * If true, verify address for shipping with the Core API before saving IBO addresses.
       */
      supportsAddressVerification: true,

      /**
       * If true, enable DreamStream functionality.
       */
      supportsDreamStream: true,

      /**
       * If true, display a warning about DreamStream being production-only.
       * This should only be enabled on non-production environments.
       */
      showDreamStreamEnvWarning: true,

      /**
       * If true, display fetch management team information.
       * This is a US-only feature.
       */
      fetchManagementTeamNotification: true,

      shouldShowKateTab: true,
    },

    /**
     * Links to other sites. Displayed under in-app nav items on main nav sidebar.
     */
    externalLinks: [
      { name: "WWG Support - AU", href: "https://au.wwg.support/" },
      { name: "WWG Support - Legacy", href: "https://legacy.wwg.support" },
    ],
  })

  /**
   * US development environment. (new)
   */
  .forDomain("dev-events.wwg.support", {
    apiBase: "https://dev-api.wwg.support",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    cmsUrl: "https://apps-dev.wwdb.com",
    pageTitle: "WWG Support - US Dev",
    flags: {
      supportsAddressVerification: true,
      supportsDreamStream: true,
      showDreamStreamEnvWarning: true,
      shouldShowKateTab: true,
      fetchManagementTeamNotification: true,
    },
    externalLinks: [
      { name: "WWG Support - AU", href: "https://au.wwg.support/" },
      {
        name: "WWG Support - Legacy",
        href: "https://legacy.wwg.support",
      },
    ],
  })

  /**
   * US production environment.
   */
  .forDomain("events.wwg.support", {
    apiBase: "https://api-ca.wwg.support",
    coreApiUrl: "https://app-core-web.wwdb.com",
    cmsUrl: "https://www.wwdb.com",
    pageTitle: "WWG Support - US",
    flags: {
      supportsAddressVerification: true,
      supportsDreamStream: true,
      shouldShowKateTab: true,
      fetchManagementTeamNotification: true,
    },
    externalLinks: [
      { name: "WWG Support - AU", href: "https://au.wwg.support/" },
      { name: "WWG Support - Legacy", href: "https://legacy.wwg.support" },
    ],
  });

export default env.config;
