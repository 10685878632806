import React from "react";
import PropTypes from "prop-types";
import InfoItem from "Events/Components/InfoItem";

import styles from "./HostCardInteractive.module.css";

/**
 * Displays information about a host with the ability to remove
 */
export function HostCardInteractive({ host, onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.hostCardInteractivedoubleInfo}>
        {/* <button
          className={styles.hostCardInteractiveEditButton}
          onClick={onClick}
        >
          Remove
        </button> */}
        <div className={styles.hostCardInteractiveProfilePhoto} />
      </div>

      <InfoItem label="IBO Number" description={host.number} />

      <div className={styles.item}>
        <p className={styles.label}>Name</p>
        <a href="/speaker" className={styles.name}>
          {host.name}
        </a>
      </div>

      <InfoItem label="Username" description={host.userName} />

      <InfoItem label="Phone" description={host.phoneNumber} />

      <InfoItem label="Email" description={host.email} />

      <InfoItem label="Access Level" description={host.accessLevel} />
    </div>
  );
}

HostCardInteractive.propTypes = {
  /**
   * Object with info about the host (number-String, name-String, userName-String, phoneNumber-String, email-String, acessLevel-String)
   */
  host: PropTypes.shape({
    number: PropTypes.string,
    name: PropTypes.string,
    userName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    accessLevel: PropTypes.string,
  }),
  /**
   * Function called when the remove btn is clicked
   */
  onClick: PropTypes.func,
};
