import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import styles from "./NotesV2Form.module.css";
import Card from "shared/Card";
import TextArea from "shared/TextArea";

/**
 * Area to fill out notes.
 */

export function NotesV2Form({ notesData, pageMode, onNotesChange }) {
  const [notesInternal, setNotesInternal] = useState(
    notesData?.event?.notes?.saved || notesData?.event?.notes?.value || ""
  );
  const [notesCalendar, setNotesCalendar] = useState(
    notesData?.marketing?.notes?.saved ||
      notesData?.marketing?.notes?.value ||
      ""
  );

  // When setting the state for displaying the notes:
  useEffect(() => {
    const newNotesInternal =
      notesData?.event?.notes?.saved || notesData?.event?.notes?.value || "";

    const newNotesCalendar =
      notesData?.marketing?.notes?.saved ||
      notesData?.marketing?.notes?.value ||
      "";

    setNotesInternal(decodeURI(newNotesInternal));
    setNotesCalendar(decodeURI(newNotesCalendar));
  }, [notesData]);

  const handleNotesInputChange = (dataType, event) => {
    let newValue = event.target.value;
    // Correctly replace both \n and ↵ with \r\n and assign it back to newValue.
    // newValue = newValue.replace(/\n/g, "\r\n").replace(/↵/g, "\r\n");
    // newValue = newValue.replace(/\r?\n/g, "\r\n");

    // Update the internal state based on the dataType
    if (dataType === "event") {
      setNotesInternal(newValue);
    } else if (dataType === "marketing") {
      setNotesCalendar(newValue);
    }
  };

  const handleNotesInternalBlur = () => {
    const encodedNotesInternal = encodeURI(notesInternal);
    onNotesChange("event", encodedNotesInternal);
  };

  const handleNotesCalendarBlur = () => {
    const encodedNotesCalendar = encodeURI(notesCalendar);
    onNotesChange("marketing", encodedNotesCalendar);
  };

  const renderNotesField = (dataType, label, onChange, onBlur) => {
    let displayValue = dataType === "event" ? notesInternal : notesCalendar;

    // displayValue = displayValue.replace(/↵/g, "\r\n");

    const permission =
      pageMode === "view"
        ? "read"
        : dataType === "event"
        ? notesData?.event?.notes.permission
        : notesData?.marketing?.notes.permission;

    if (permission === "hidden") {
      return null;
    }

    if (permission === "read") {
      const displayValueOnSameLine = displayValue.replace(
        /(\w)(\n)(\d)/g,
        "$1 $3"
      );

      return (
        <div>
          <div className={styles.label}>{label}:</div>
          <div className={styles.text}>{displayValueOnSameLine || "N/A"}</div>
        </div>
      );
    }

    if (permission === "write") {
      return (
        <div className={styles.noteContainer}>
          <div className={styles.label}>{label}:</div>
          <TextArea
            name={dataType}
            value={displayValue}
            placeholder="Click to type"
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      );
    }
  };

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <div className={styles.cardHeader}>NOTES</div>
      </div>
      <hr className={styles.hr} />

      {renderNotesField(
        "event",
        "Additional Information (Internal to WWG Team)",
        (e) => handleNotesInputChange("event", e),
        handleNotesInternalBlur
      )}

      {renderNotesField(
        "marketing",
        "Additional Information (Notes to be displayed in calendar)",
        (e) => handleNotesInputChange("marketing", e),
        handleNotesCalendarBlur
      )}
    </Card>
  );
}

NotesV2Form.propTypes = {
  additionalNotes: PropTypes.string,
  setAdditionalNotes: PropTypes.func,

  notesToBePublished: PropTypes.string,
  setNotesToBePublished: PropTypes.func,
};
