import regionConfig from "./regionConfig";

/**
 * Environment-specific options object.
 */
export const ENVIRONMENT_CONFIG = regionConfig;

/**
 * Date format string in moment/dayjs dialect.
 */
export const DATE_FORMAT = "YYYY[-]MM[-]DD";

/**
 * Time format string in moment/dayjs dialect.
 */
export const TIME_FORMAT = "h[:]mm A";
