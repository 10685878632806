import PropTypes from "prop-types";
import styles from "./EventInfoCard.module.css";
import InfoItem from "Events/Components/InfoItem";

/**
 *
 * Area to display the events info
 *
 */

export function EventInfoCard({ eventInfo, onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.cardHeader}>EVENT INFORMATION</div>

      <div className={styles.hr}></div>

      <div>
        <InfoItem label={"Display Name"} description={eventInfo.name} />
        <InfoItem
          label={"Event Description"}
          description={eventInfo.description}
        />
        <InfoItem label={"Country"} description={eventInfo.country} />
        <InfoItem label={"City"} description={eventInfo.city} />
        <InfoItem label={"State"} description={eventInfo.state} />

        {!eventInfo.ruleset === "Major v1.0" && (
          <>
            <InfoItem
              label={"Date"}
              description={
                eventInfo.startDate && eventInfo.startDate ? (
                  <>{`${eventInfo.startDate} - ${eventInfo.startDate} (${eventInfo.timeZone})`}</>
                ) : null
              }
            />
            <InfoItem
              label={"Doors Open"}
              description={
                eventInfo.scannerStartDate ? (
                  <>
                    {`${eventInfo.scannerStartDate}
       (${eventInfo.scannerTimeZone})`}
                  </>
                ) : null
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

EventInfoCard.propTypes = {
  eventInfo: PropTypes.object,
  onClick: PropTypes.func,
};
