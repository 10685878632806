import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./EventTypeCard.module.css";
import Card from "shared/Card";
import { Select } from "shared/Select/Select";

/**
 * Displays information about the type of event on the create new event page with a button to edit
 */
export function EventTypeCard({ eventInfo, onChange }) {
  const [location, setLocation] = useState(eventInfo?.location?.value || "");

  const handleSelectLocation = (selectedLocation) => {
    setLocation(selectedLocation);
    onChange(selectedLocation);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Function to render location based on permission
  const renderEventLocation = () => {
    const permission = eventInfo.location?.permission;

    if (
      permission === "write" ||
      permission === undefined ||
      !eventInfo.location.hasOwnProperty("permission")
    ) {
      return (
        <Select
          placeholder={
            location || capitalizeFirstLetter(eventInfo?.location.value)
          }
          options={["Onsite", "Virtual"]}
          onChange={handleSelectLocation}
        />
      );
    } else if (permission === "read") {
      return capitalizeFirstLetter(eventInfo.location.value);
    } else if (permission === "hidden") {
      return null;
    }
  };

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <div className={styles.cardHeader}>EVENT TYPE</div>
      </div>
      <hr className={styles.hr} />

      <div className={styles.grid}>
        <div>
          <span className={styles.columns}>Event Name: </span>

          <span className={styles.item}>{eventInfo.name.value}</span>
        </div>
        <div>
          <span className={styles.columns}>Ruleset: </span>

          <span className={styles.item}>{eventInfo.ruleset.value}</span>
        </div>
        <div>
          <span className={styles.columns}>Location:</span>
          <span className={styles.item}>{renderEventLocation()}</span>
        </div>
      </div>
    </Card>
  );
}

EventTypeCard.propTypes = {
  name: PropTypes.string,
  ruleset: PropTypes.string,
  location: PropTypes.string,
  onChange: PropTypes.func,
  showChangeButton: PropTypes.bool,
};
