import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import AddButton from "Events/Components/AddButton";
import InputItem from "Events/Components/InputItem";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import TextArea from "shared/TextArea";
import styles from "./EventInfoForm.module.css";
import classNames from "classnames";
import { Select } from "shared/Select/Select";

/**
 * Interactive component. Form to fill out information about an event.
 */

export function EventInfoForm({ marketingInfo, onAddSpeaker, onBlur }) {
  const timeZones = ["PT", "MST", "MDT", "CST", "CDT"];
  const initialEvent = {
    jobCode: { value: "", saved: "" },
  };

  const initialMarketing = {
    title: { value: "", saved: "" },
    // jobCode: { value: "", saved: "" },
    description: { value: "", saved: "" },
    location: { value: "", saved: "" },
    startDate: { value: "", saved: "" },
    startTimeZone: { value: "", saved: "" },
    endDate: { value: "", saved: "" },
    endTimeZone: { value: "", saved: "" },
    scannerStartDate: { value: "", saved: "" },
    scannerTimeZone: { value: "", saved: "" },
  };

  const [eventData, setEventData] = useState({
    ...initialEvent,
    ...(marketingInfo?.event && marketingInfo.event),
  });

  const [marketingData, setMarketingData] = useState({
    ...initialMarketing,
    ...(marketingInfo?.marketing && marketingInfo.marketing),
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const handleBlur = (dataType, key, value) => {
    if (!isInitialLoad) {
      onBlur(dataType, key, value);
    }
  };

  useEffect(() => {
    if (marketingInfo?.event !== eventData) {
      setEventData(marketingInfo?.event);
    }
    if (marketingInfo?.marketing !== marketingData) {
      setMarketingData(marketingInfo?.marketing);
    }
  }, [marketingInfo?.event, marketingInfo?.marketing]);

  const handleInputChange = (dataType, key, newValue) => {
    if (dataType === "event") {
      setEventData((prevData) => ({
        ...prevData,
        [key]: {
          ...prevData[key],
          saved: newValue,
        },
      }));
    } else if (dataType === "marketing") {
      setMarketingData((prevData) => ({
        ...prevData,
        [key]: {
          ...prevData[key],
          saved: newValue,
        },
      }));
    }
  };

  const handleDateTimeChange = (name, value) => {
    setMarketingData((prevData) => {
      let newValue = name.endsWith("Date") ? extractDate(value) : value;

      let updatedData = {
        ...prevData,
        [name]: {
          ...prevData[name],
          value: newValue,
        },
      };

      onBlur && handleBlur("marketing", name, newValue);

      return updatedData;
    });
  };

  // Date and time
  const extractDate = (isoString) => {
    if (!isoString) return "";

    const date = new Date(isoString);
    if (isNaN(date.getTime())) return "";

    return `${String(date.getUTCMonth() + 1).padStart(2, "0")}/${String(
      date.getUTCDate()
    ).padStart(2, "0")}/${date.getUTCFullYear()}`;
  };

  //Extracting the time from the date
  const extractTime = (isoString) => {
    if (!isoString) return "";

    const date = new Date(isoString);
    if (isNaN(date.getTime())) return "";

    return `${String(date.getUTCHours()).padStart(2, "0")}:${String(
      date.getUTCMinutes()
    ).padStart(2, "0")}`;
  };

  //Extract the time from date and update the time
  const [isTimeInitialized, setIsTimeInitialized] = useState(false);

  useEffect(() => {
    if (!isTimeInitialized) {
      const initialStartTime = extractTime(marketingData?.startDate?.saved);
      const initialEndTime = extractTime(marketingData?.endDate?.saved);

      if (initialStartTime && !marketingData?.startTime?.value) {
        handleDateTimeChange("startTime", initialStartTime);
      }
      if (initialEndTime && !marketingData?.endTime?.value) {
        handleDateTimeChange("endTime", initialEndTime);
      }

      setIsTimeInitialized(true);
    }
  }, [
    marketingData?.startDate?.saved,
    marketingData?.endDate?.saved,
    isTimeInitialized,
  ]);

  const handleTimeZoneChange = (name, newValue) => {
    setMarketingData((prevData) => {
      return {
        ...prevData,
        [name]: {
          ...prevData[name],
          value: newValue,
          saved: newValue,
        },
      };
    });
  };

  return (
    <Card
      children={
        <section>
          <div className={classNames(styles.title)}>EVENT INFORMATION</div>
          <hr className={styles.hr} />
          <div className={classNames(styles["form-container"])}>
            <div
              className={classNames(
                styles["input-container"],
                styles["double"],
                styles.inputItem
              )}
            >
              <InputItem
                label={"Display Name"}
                value={
                  marketingData?.title?.value || marketingData?.title?.saved
                }
                placeholder={"Click to type (IE: Las Vegas Spring Leadership)"}
                onChange={(e) =>
                  handleInputChange("marketing", "title", e.target.value)
                }
                onBlur={() =>
                  handleBlur("marketing", "title", marketingData?.title?.saved)
                }
              />

              <InputItem
                label={"Job code:"}
                value={eventData?.jobCode?.value || eventData?.jobCode?.saved}
                placeholder={"YYMMELOC"}
                onChange={(e) =>
                  handleInputChange("event", "jobCode", e.target.value)
                }
                onBlur={() =>
                  onBlur("event", "jobCode", eventData?.jobCode?.saved)
                }
              />
            </div>
            <div
              className={classNames(
                styles["input-container"],
                styles["event-description"]
              )}
            >
              <TextArea
                label={"Event Description"}
                value={
                  marketingData?.description?.value ||
                  marketingData?.description?.saved ||
                  ""
                }
                placeholder={"Click to type"}
                onChange={(e) =>
                  handleInputChange("marketing", "description", e.target.value)
                }
                onBlur={() =>
                  handleBlur(
                    "marketing",
                    "description",
                    marketingData?.description?.saved
                  )
                }
              />
            </div>
            <div
              className={classNames(
                styles["input-container"],
                styles.inputItem
              )}
            >
              <InputItem
                label={"Marketing Location"}
                value={
                  eventData.location?.saved || eventData?.location?.value || ""
                }
                placeholder={"Click to type"}
                onChange={(e) =>
                  handleInputChange("marketing", "location", e.target.value)
                }
                onBlur={() =>
                  handleBlur(
                    "marketing",
                    "location",
                    marketingData?.location?.saved
                  )
                }
              />
            </div>
            <div className={classNames(styles["input-container"])}>
              <div className={classNames(styles.flex)}>
                <span className={styles.label}>Speaker Name</span>

                <div
                  className={classNames(styles["button-container"])}
                  onClick={onAddSpeaker}
                >
                  <AddButton description={"Add Speaker"} color="#2A3847" />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                styles["input-container"],
                styles.flex,
                styles.dates
              )}
            >
              <div className={classNames(styles.flex, styles["picker-group"])}>
                <span className={styles.label}>Start Date *</span>
                <div className={styles["picker-input"]}>
                  <div className={styles["date-picker"]}>
                    <DatePicker
                      dateString={
                        marketingData?.startDate?.saved ||
                        marketingData?.startDate?.value ||
                        ""
                      }
                      setDate={(value) =>
                        handleDateTimeChange("startDate", value)
                      }
                    />
                  </div>
                  <div className={styles["time-picker"]}>
                    <TimePicker
                      timeString={
                        marketingData?.startTime?.saved ||
                        marketingData?.startTime?.value ||
                        ""
                      }
                      onChange={(value) =>
                        handleDateTimeChange("startTime", value)
                      }
                    />
                  </div>
                  <div className={styles.timezone}>
                    {/* <Select
                      options={timeZones}
                      // placeholder={marketingData?.startTimeZone?.value || ""}
                      // value={
                      //   marketingData?.startTimeZone?.value ||
                      //   marketingData?.startTimeZone?.saved
                      // }

                      onChange={(value) =>
                        handleTimeZoneChange("startTimeZone", value)
                      }
                      disabled={false}
                    /> */}

                    <Select
                      options={timeZones}
                      onChange={(value) =>
                        handleTimeZoneChange("startTimeZone", value)
                      }
                      placeholder={
                        marketingData?.startTimeZone?.value ||
                        marketingData?.startTimeZone?.saved ||
                        "CST"
                      }
                      disable={false}
                    />
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.flex,
                  styles["picker-group"],
                  styles["end-date-section"]
                )}
              >
                <span className={classNames(styles["no-margin"], styles.label)}>
                  End Date *
                </span>
                <div className={styles["picker-input"]}>
                  <div className={classNames(styles["date-picker"])}>
                    <DatePicker
                      dateString={
                        marketingData?.endDate?.saved ||
                        marketingData?.endDate?.value ||
                        ""
                      }
                      setDate={(value) =>
                        handleDateTimeChange("endDate", value)
                      }
                    />
                  </div>
                  <div className={classNames(styles["time-picker"])}>
                    <TimePicker
                      timeString={
                        marketingData?.endTime?.saved ||
                        marketingData?.endTime?.value ||
                        ""
                      }
                      onChange={(value) =>
                        handleDateTimeChange("endTime", value)
                      }
                    />
                  </div>

                  <div className={styles.timezone}>
                    <Select
                      options={timeZones}
                      onChange={(value) =>
                        handleTimeZoneChange("endTimeZone", value)
                      }
                      placeholder={
                        marketingData?.endTimeZone?.value ||
                        marketingData?.endTimeZone?.saved ||
                        "MST"
                      }
                      disable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(styles["input-container"])}>
              <div className={classNames(styles.flex)}>
                <span className={styles.label}>Doors Open</span>

                <div className={styles["picker-input"]}>
                  <div className={classNames(styles["date-picker"])}>
                    <DatePicker
                      dateString={
                        marketingData?.scannerStartDate?.saved ||
                        marketingData?.scannerStartDate?.value ||
                        ""
                      }
                      setDate={(value) =>
                        handleDateTimeChange("scannerStartDate", value)
                      }
                    />
                  </div>
                  <div className={classNames(styles["time-picker"])}>
                    <TimePicker
                      timeString={
                        marketingData?.scannerTime?.saved ||
                        marketingData?.scannerTime?.value ||
                        ""
                      }
                      onChange={(value) =>
                        handleDateTimeChange("scannerTime", value)
                      }
                    />
                  </div>
                  <div className={styles.timezone}>
                    <Select
                      options={timeZones}
                      onChange={(value) =>
                        handleTimeZoneChange("scannerTimeZone", value)
                      }
                      placeholder={
                        marketingData?.scannerTimeZone?.value ||
                        marketingData?.scannerTimeZone?.value ||
                        "MST"
                      }
                      disable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    />
  );
}

EventInfoForm.propTypes = {
  /**
   * Value of display name input box.
   */
  title: PropTypes.string,
  /**
   * Function to set the display name when changed.
   */
  settitle: PropTypes.func,
  /**
   * Value of job code input box.
   */
  jobCode: PropTypes.string,
  /**
   * Function to set the job code when changed.
   */
  setJobCode: PropTypes.func,
  /**
   * Value of description input box.
   */
  description: PropTypes.string,
  /**
   * Function to set the description when changed.
   */
  setDescription: PropTypes.func,
  /**
   * Value of location input box.
   */
  location: PropTypes.string,
  /**
   * Function to set the location when changed.
   */
  setLocation: PropTypes.func,
  /**
   * Value of start date input box.
   */
  startDate: PropTypes.string,
  /**
   * Function to set the start date when changed.
   */
  setStartDate: PropTypes.func,
  /**
   * Value of end date input box.
   */
  endDate: PropTypes.string,
  /**
   * Function to set the end date when changed.
   */
  setEndDate: PropTypes.func,
  /**
   * Value of start time input box.
   */
  startTime: PropTypes.string,
  /**
   * Function to set the start time when changed.
   */
  setStartTime: PropTypes.func,
  /**
   * Value of end time input box.
   */
  endTime: PropTypes.string,
  /**
   * Function to set the end time when changed.
   */
  setEndTime: PropTypes.func,
  /**
   * Value of doors open time input box.
   */
  doorsOpen: PropTypes.string,
  /**
   * Function to set the doors open time when changed.
   */
  setDoorsOpen: PropTypes.func,
  /**
   * Function that fires when 'add speaker' is clicked.
   */
  onAddSpeaker: PropTypes.func,
};
