import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import { Select } from "shared/Select/Select";

import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import styles from "./PublishingInfoForm.module.css";
import AddButton from "Events/Components/AddButton";
import VisibleToModal from "Events/Components/VisibleToModal";
import Checkbox from "shared/@forms/Checkbox";

//Area to fill out the publishing information
export function PublishingInfoForm({
  visibleOnDate,
  setVisibleOnDate,
  visibleOnTime,
  setVisibleOnTime,
  purchaseBeginDate,
  setPurchaseBeginDate,
  purchaseBeginTime,
  setPurchaseBeginTime,
  purchaseEndTime,
  setPurchaseEndTime,
  purchaseEndDate,
  setPurchaseEndDate,
  visibleTo,
  setVisibleTo,
  tentative,
  setTentative,
}) {
  // const [visibleOnCalendar, setVisibleOnCalendar] = useState(false); // default value as needed

  const [localVisibleOnTimeZone, setLocalVisibleOnTimeZone] = useState("CST");
  const [localPurchaseBeginTimeZone, setLocalPurchaseBeginTimeZone] =
    useState("CST");
  const [localPurchaseEndTimeZone, setLocalPurchaseEndTimeZone] =
    useState("CST");

  const timeZones = ["PST", "MST", "MDT", "CST", "CDT"];
  const [openVisibleToModal, setOpenVisibleToModal] = useState(false);

  // const [isAddVisible, setIsAddVisible] = useState(null);
  //Handles visible to modal add button
  const handleAddVisibleTo = () => {
    setVisibleTo("");
    setOpenVisibleToModal(false);
  };

  const handleCloseVisibleModal = () => {
    setVisibleTo("");
    setOpenVisibleToModal(false);
  };

  const handleTentative = () => {
    setTentative(!tentative);
  };
  // const [isCountingTickets, setIsCountingTickets] = useState(false);
  // const handleCountingTicketsChange = () => {
  //   setIsCountingTickets((prevState) => !prevState);
  //   console.log("Counting Tickets Toggled");
  // };
  return (
    <Card
      children={
        <section>
          <div className={styles.title}>PUBLISHING INFORMATION</div>
          <hr className={styles.hr} />

          {/* Existing Fields for other rulesets */}
          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>Visible on Calendar *</span>
              <span className={styles.datePicker}>
                <DatePicker
                  dateString={visibleOnDate}
                  setDate={setVisibleOnDate}
                />
              </span>
              <span className={styles.timePicker}>
                <TimePicker
                  timeString={visibleOnTime}
                  onChange={setVisibleOnTime}
                />
              </span>
              <span className={styles.timezones}>
                <Select
                  placeholder={localVisibleOnTimeZone}
                  options={timeZones}
                  onChange={setLocalVisibleOnTimeZone}
                />
              </span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.visibleToCol}>
              <span className={styles.label}>Visible to *</span>
              <span className={styles.visibleToSelect}>
                <Select
                  placeholder={visibleTo || "All IBOs"}
                  value={visibleTo}
                  options={["All IBOs", "Invite Only"]}
                  onChange={(value) => setVisibleTo(value)}
                />
              </span>
              {visibleTo === "Invite Only" && (
                <span
                  className={styles.visibleAddButton}
                  onClick={() => setOpenVisibleToModal(true)}
                >
                  <AddButton description={"Add"} color={"#2A3847"} />
                </span>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.tentative}>
              <span className={styles.label}>Tentative</span>
              <span className={styles.checkbox}>
                <Checkbox
                  onChange={handleTentative}
                  defaultChecked={tentative}
                />
              </span>
            </div>
          </div>

          {/* <div className={styles.row}>
            <div className={styles.finalCol}>
              <span className={styles.label}>Purchase Begin *</span>
              <span className={styles.datePicker}>
                <DatePicker
                  dateString={purchaseBeginDate}
                  setDate={setPurchaseBeginDate}
                />
              </span>
              <span className={styles.pickers}>
                <TimePicker
                  timeString={purchaseBeginTime}
                  onChange={setPurchaseBeginTime}
                />
              </span>
              <span className={styles.timezones}>
                <Select
                  placeholder={localPurchaseBeginTimeZone}
                  options={timeZones}
                  onChange={setLocalPurchaseBeginTimeZone}
                />
              </span>
            </div>
            <div className={styles.finalCol}>
              <span className={styles.label}>Purchase End *</span>
              <span className={styles.datePicker}>
                <DatePicker
                  dateString={purchaseEndDate}
                  setDate={setPurchaseEndDate}
                />
              </span>
              <span className={styles.pickers}>
                <TimePicker
                  timeString={purchaseEndTime}
                  onChange={setPurchaseEndTime}
                />
              </span>
              <span className={styles.timezones}>
                <Select
                  placeholder={localPurchaseEndTimeZone}
                  options={timeZones}
                  onChange={setLocalPurchaseEndTimeZone}
                />
              </span>
            </div>
          </div> */}

          {/* Visible To Modal */}
          <VisibleToModal
            isOpen={openVisibleToModal}
            onCollapse={handleCloseVisibleModal}
            onClick={handleAddVisibleTo}
          />
        </section>
      }
    />
  );
}

PublishingInfoForm.propTypes = {
  //Value of visible on date
  visibleOnDate: PropTypes.string,
  //Used to change the visible on date
  setVisibleOnDate: PropTypes.func,
  //Value of visible on time
  visibleOnTime: PropTypes.string,
  //used to set the visible on time
  setVisibleOnTime: PropTypes.func,
  //value of purchase begin date
  purchaseBeginDate: PropTypes.string,
  //used to set the purchase begin date
  setPurchaseBeginDate: PropTypes.func,
  //value of the purchase begin time
  purchaseBeginTime: PropTypes.string,
  //used to set the purchase begin time
  setPurchaseBeginTime: PropTypes.func,
  //value of purchase end time
  purchaseEndTime: PropTypes.string,
  //used to set the purchase end time
  setPurchaseEndTime: PropTypes.func,
  //value of the purchase end date
  purchaseEndDate: PropTypes.string,
  //used to set the purchase end date
  setPurchaseEndDate: PropTypes.func,
  //value of visible to select
  visibleTo: PropTypes.string,
  //used to set the value of the visible to select
  setVisibleTo: PropTypes.func,
  rulesetVersion: PropTypes.string.isRequired,
  purchaseCapacity: PropTypes.string,
  setPurchaseCapacity: PropTypes.func,
  platinumCapacity: PropTypes.string,
  setPlatinumCapacity: PropTypes.func,
};
