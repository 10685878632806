import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./VenuesSearchBar.module.css";

/**
 * An interactive Search Bar component with a customizable placeholder text.
 * Logs inputs if minimum character prop is satisfied with an optional delay.
 * Width of Search Bar determined by container width.
 */
export function VenuesSearchBar({
  placeholderText,
  onInput,
  searchDelay = 300,
  minChar = 1,
  initialValue = "",
}) {
  const [input, setInput] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== input) {
      setInput(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (input.length >= minChar) {
      const timer = setTimeout(() => {
        onInput(input);
      }, searchDelay);

      return () => clearTimeout(timer);
    }
  }, [input, minChar, onInput, searchDelay]);

  const clearInput = () => {
    setInput("");
    onInput("");
  };

  return (
    <div className={styles.container}>
      <label htmlFor="search-bar" className={styles.searchLabel} hidden>
        Search
      </label>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          id="search-bar"
          value={input}
          placeholder={placeholderText}
          className={styles.searchBar}
          onChange={(e) => setInput(e.target.value)}
        />
        {input && (
          <button className={styles.clearButton} onClick={clearInput}>
            &times;
          </button>
        )}
      </div>
    </div>
  );
}

VenuesSearchBar.propTypes = {
  // placeholder text
  placeholderText: PropTypes.string,

  // function that fires when user types and minChar/searchDelay is satisfied
  onInput: PropTypes.func,

  // number of ms before onInput is called
  searchDelay: PropTypes.number,

  // minimum characters needed to fire onInput
  minChar: PropTypes.number,

  //initial value
  initialValue: PropTypes.string,
};

VenuesSearchBar.defaultProps = {
  // default delay of 0
  searchDelay: 0,

  // default minChar of 0
  minChar: 0,
};
