import styles from "./CircumstanceCard.module.css";
import PropTypes from "prop-types";
import Card from "shared/Card";

/**
 * Displays info about the security circumstances
 */

export function CircumstanceCard({ circumstances, onClick }) {
  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.cardHeader}>
            SECURITY SPECIAL CIRCUMSTANCES
          </div>

          {/* <Button
            className={classNames(styles.editBtn, cardStyles.cardLightButton)}
            onClick={onClick}
            size="small"
          >
            Edit
          </Button> */}
        </div>
        <div className={styles.hr}></div>

        <div>{circumstances}</div>
      </div>
    </>
  );
  return <Card children={cardContents} />;
}

CircumstanceCard.propTypes = {
  circumstances: PropTypes.string,
  onClick: PropTypes.func,
};
