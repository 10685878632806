import { createRef } from "react";

export default {
  accessible: {
    seating: {
      id: "accessibleSeating",
      ref: createRef("accessibleSeating"),
      bitwise: 32768,
    },
    companion: {
      id: "accessibleCompanion",
      ref: createRef("accessibleCompanion"),
      bitwise: 65536,
    },
  },
  hearing: {
    device: {
      id: "hearingDevice",
      ref: createRef("hearingDevice"),
      bitwise: 4,
    },
    interpreter: {
      id: "requiresInterpreter",
      ref: createRef("requiresInterpreter"),
      bitwise: 8,
    },
    companion: {
      id: "hearingCompanion",
      ref: createRef("hearingCompanion"),
      bitwise: 131072,
    },
  },
  nursingMother: {
    id: "nursingMother",
    bitwise: 262144,
    ref: createRef("nursingMother"),
  },
};
