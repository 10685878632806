import styles from "./DateTimePicker.module.css";
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import dayjs from "dayjs";

import { DatePicker } from "./DatePicker/DatePicker";
import { TimePicker } from "./TimePicker/TimePicker";
import { ZonePicker } from "./ZonePicker/ZonePicker";

/**
 * A combined picker for date and time with support for time zones.
 *
 * NOTE: This was copied from @wwg/ui and has been re-styled to match the design
 *       of the Support app. It still needs a refactor to remove `moment-timezone`
 *       in favor of `dayjs`. It also might need some more styling tweaks because
 *       it was not made to work in this app's color system.
 *       Also remove the mobile stuff because this app is not responsive.
 */
export function DateTimePicker(props) {
  const {
    timeZone,
    zones,
    value,
    required,
    disabled,
    onChange,
    withZonePicker,
  } = props;

  const innerValue = useMemo(() => {
    if (value instanceof Date) {
      return value;
    } else if (typeof value === "string") {
      return new Date(value);
    } else {
      return new Date();
    }
  }, [value]);

  const [date, time] = useMemo(
    () => dateIntoComponents(innerValue, timeZone),
    [innerValue, timeZone]
  );

  // Re-emit onChange when certain props change.
  useEffect(() => {
    onUpdate(date, time);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone, disabled]);

  const onUpdate = (date, time) => {
    const reconstructed = dateFromComponents([date, time], timeZone);

    if (reconstructed && typeof onChange === "function") {
      if (disabled) {
        onChange(null); // Always return null if disabled
      } else if (!dayjs(reconstructed).isSame(props.value)) {
        onChange(reconstructed);
      }
    }
  };

  return (
    <div className={styles.container}>
      {withZonePicker && (
        <div className={styles.picker}>
          <ZonePicker
            value={timeZone}
            zones={zones}
            onChange={(zone) => {
              console.log("changed to zone");
            }}
          />
        </div>
      )}
      <div className={styles.picker}>
        <DatePicker
          value={date}
          required={required}
          disabled={disabled}
          onChange={(values) => {
            onUpdate(values, time);
          }}
        />
      </div>
      <div className={styles.picker}>
        <TimePicker
          value={time}
          required={required}
          disabled={disabled || date == null}
          onChange={(values) => {
            console.log("changed", values);
            onUpdate(date, values);
          }}
        />
      </div>
    </div>
  );
}

DateTimePicker.propTypes = {
  /**
   * The starting date value.
   */
  initialValue: PropTypes.instanceOf(Date),

  /**
   * Don't allow null or invalid dates if true. Allow null dates if false.
   */
  required: PropTypes.bool,

  /**
   * An IANA/Olson time zone designation, e.g. America/New_York
   */
  timeZone: PropTypes.string,

  /**
   * If true, show a time zone picker to allow the user to choose a time zone.
   */
  withZonePicker: PropTypes.bool,

  /**
   * An array of { value: string, label: string }
   */
  zones: PropTypes.arrayOf(PropTypes.string),

  /**
   * A callback to fire when the user changes the value. Passes the picked date as the first param
   * and the selected time zone as the second.
   */
  onChange: PropTypes.func,
};

/**
 * Transforms a JS Date object into an array of numeric pieces.
 *
 * @param {Date} date - A JS Date object.
 */
const dateIntoComponents = (date, timeZone) => {
  if (date == null || !moment(date).isValid()) {
    return [null, null];
  }

  const m = moment(date).tz(timeZone || moment.tz.guess());

  return [
    [m.year(), m.month() + 1, m.date()],
    [m.hours(), m.minutes()],
  ];
};

/**
 * Transforms an array of date components and an array of time components back into a JS Date.
 *
 * @param {number[][]} components - An array of date components, e.g. [[year, month, date], [hours, minutes]]
 */
const dateFromComponents = ([date, time], timeZone) => {
  date = date.map((x) => x);
  time = time.map((x) => x);

  if (time[0] === 24) {
    time[0] = 0;
  }

  const [y, mo, d, h, m] = [...date, ...time].map((c) =>
    c.toString().padStart(2, "0")
  );

  const str = `${y}-${mo}-${d} ${h}:${m}`;
  return moment.tz(str, timeZone || moment.tz.guess()).toDate();
};
