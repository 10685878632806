import { ENVIRONMENT_CONFIG } from "shared/globals";

const apiBase = ENVIRONMENT_CONFIG.apiBase.endsWith("/")
  ? ENVIRONMENT_CONFIG.apiBase
  : ENVIRONMENT_CONFIG.apiBase + "/";

/**
 * Resolves relative URLs against API base URL. Returns absolute URLs as-is.
 *
 * @param {string} path - The path to resolve.
 */
export default function apiPath(path) {
  // Return absolute URLs as-is.
  if (/^https?:\/\//.test(path)) {
    return path;
  }

  // Append paths to the standard base URL.
  if (path.startsWith("/")) {
    path = path.slice(1);
  }

  return apiBase + path;
}
