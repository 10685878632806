import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

//Extract date

// export const extractDate = (isoString) => {
//   if (!isoString || typeof isoString !== "string") return "";

//   return isoString.split("T")[0];
// };

export const extractDate = (isoString) => {
  if (!isoString || typeof isoString !== "string") return "";

  // Use moment or dayjs to parse and convert to local date
  return dayjs(isoString).local().format("MM/DD/YYYY");
};

export const extractTime = (isoString) => {
  if (!isoString || typeof isoString !== "string" || !isoString.includes("T")) {
    return "";
  }

  const formattedTime = dayjs.utc(isoString).format("HH:mm");

  if (formattedTime === "00:00") {
    return "";
  }

  return formattedTime;
};

export const combineDateTime = (date, time) => {
  if (!date) return "";

  if (!time) {
    // return dayjs(date).utc().format();
    return dayjs(date).utc().format();
  }

  const [hours, minutes] = time.split(":");
  const dateTime = dayjs(date)
    .utc()
    .hour(parseInt(hours, 10))
    .minute(parseInt(minutes, 10))
    .second(0);

  return dateTime.toISOString();
};

export function isValidDateOrTime(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

export const formatDate = (isoString) => {
  if (!isoString || typeof isoString !== "string")
    return { date: "", time: "" };

  const moment = dayjs.utc(isoString);

  if (!moment.isValid()) return { date: "", time: "" };

  // Extract and format the date and time parts
  const date = moment.format("MM/DD/YYYY");
  const time = moment.format("HH:mm");

  return { date, time };
};

// Function to extract and format both date and time
export const extractDateTime = (isoString) => {
  if (!isoString || typeof isoString !== "string") return "";

  const moment = dayjs.utc(isoString);

  if (!moment.isValid()) return "";

  return moment.format("MM/DD/YYYY hh:mm A");
};
