import React from "react";
import PropTypes from "prop-types";
import styles from "./CloneModal.module.css";
import { Modal } from "shared/Modal";
import classNames from "classnames";

/**
 * Modal that opens when the user clicks the clone option in the event cards select box.
 */
export function CloneModal({
  eventName,
  ruleset,
  location,
  isOpen,
  onCollapse,
  onClick,
}) {
  return (
    <div className={styles.container}>
      <Modal
        title="CLONE EVENT"
        isOpen={isOpen}
        onClose={onCollapse}
        className={styles.modal}
        children={
          <>
            <div>
              Cloning this event will create a new event with the same
              information pre-filled and editable in the Event components. Do
              you want to continue?
            </div>

            <div className={styles.hr} />

            <div className={styles.grid}>
              <div className={styles.leftSide}>Create Clone Of:</div>
              <div>{eventName}</div>

              <div className={styles.leftSide}>Ruleset:</div>
              <div>{ruleset}</div>

              <div className={styles.leftSide}>Location:</div>
              <div>{location}</div>
            </div>

            <div>
              <button
                className={classNames(styles.button, styles.createButton)}
                onClick={onClick}
              >
                CREATE
              </button>
              <button className={styles.button} onClick={onCollapse}>
                CANCEL
              </button>
            </div>
          </>
        }
      />
    </div>
  );
}

CloneModal.propTypes = {
  eventName: PropTypes.string,
  ruleset: PropTypes.string,
  location: PropTypes.string,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
};
