/**
 * Like array.includes, but with wildcard support for permission lists.
 */
export default function hasPermission(key, permissions = []) {
  const parts = key.trim().split(".");

  outer: for (const perm of permissions) {
    const permParts = perm.split(".");

    for (let i = 0; i < permParts.length; i++) {
      // Last part is a wildcard, so consider the rest of the permission matched.
      if (parts[i] === "*" && i === parts.length - 1) {
        break;
      }

      // Compare sections for wildcard or equality.
      if (parts[i] === "*" || parts[i] === permParts[i]) {
        continue;
      } else {
        // If no match skip to the next permission.
        continue outer;
      }
    }

    // If nothing has failed so far then we have a match.
    return true;
  }

  return false
}
