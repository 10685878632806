import React from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import InfoItem from "Events/Components/InfoItem";

import styles from "./PublishingInfoCard.module.css";
import cardStyles from "../../V1Components/SharedCardStyles.module.css";

export function PublishingInfoCard({ publishingInfo }) {
  const cardContents = (
    <div className={styles.container}>
      <span className={styles.header}>PUBLISHING INFORMATION</span>
      <hr className={styles.hr} />
      <InfoItem
        label="Visible on Calendar"
        description={publishingInfo.visibleOn}
      />

      <InfoItem label="Tentative" description={publishingInfo.tentative} />

      <InfoItem label="Sold Out" description={publishingInfo.soldOut} />

      <InfoItem
        label="Counting Tickets"
        description={publishingInfo.countingTickets}
      />

      <InfoItem
        label="Purchase Capacity"
        description={publishingInfo.purchaseCapacity}
      />
      <InfoItem
        label="Platinum Capacity"
        description={publishingInfo.platinumCapacity}
      />

      {/* <InfoItem label="Visible to" description={publishingInfo.visibleTo} /> */}

      {/* <div className={styles.publishingDiv}>
        <InfoItem
          label="Purchase Begin"
          description={publishingInfo.purchaseBegin}
        />
        <div className={styles.purchaseBegin}>
          <InfoItem
            label="Purchase End"
            description={publishingInfo.purchaseEnd}
          />
        </div>
      </div> */}
    </div>
  );
  return <Card children={cardContents} />;
}

PublishingInfoCard.propTypes = {
  /**
   * Object with the publishing info (visibleOn-String, visibleTo-String, purchaseBegin-String, purchaseEnd-String)
   */
  publishingInfo: PropTypes.shape({
    visibleOn: PropTypes.string,
    visibleTo: PropTypes.string,
    purchaseBegin: PropTypes.string,
    purchaseEnd: PropTypes.string,
  }),
};
