import styles from "./index.module.css";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Standard row of buttons along the top of a tab. A tab's main top-level actions
 * should be accessible from here.
 *
 * @example
 * <TabContainer>
 *   <Tab title="A Tab" value="tab-1">
 *     <TabActionBar>
 *       <Button>Action one</Button>
 *       <Button>Action two</Button>
 *     </TabActionBar>
 *
 *     // Other content...
 *   </Tab>
 * </TabContainer>
 */
export default function TabActionBar({ className, children, ...props }) {
  return (
    <div {...props} className={classNames(styles.container, className)}>
      {children}
    </div>
  );
}

TabActionBar.propTypes = {
  /**
   * Adds different styles to your tabs.
   */
  className: PropTypes.string,
};
