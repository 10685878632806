import { useQuery } from "react-query";
import { Auth } from "shared/@auth/Auth";

export function useManageVenuesPage(params) {
  const { makeAuthedRequest } = Auth.useContainer();

  const fetchVenues = async () => {
    const { ruleset, stateId, city, search, sort, page, limit } = params; // Destructure the params object
    const response = await makeAuthedRequest(
      `v1/events/venues/ruleset/${ruleset}/stateId/${stateId}/city/${city}/search/${search}/sort/${sort}/page/${page}/limit/${limit}`,
      {
        method: "GET",
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      const body = await response.json();
      throw new Error(body.message);
    }
  };

  const { data, status, isLoading, refetch } = useQuery(
    ["venues", params], // Use the params object as the query key
    fetchVenues,
    {
      onError: (error) => {
        console.log("Error fetching venues:", error.message);
      },
    }
  );

  return { data, status, isLoading, refetch };
}
