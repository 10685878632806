import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import Select from "shared/@forms/Select";
import defaultZones from "./default-zones.json";
import PropTypes from "prop-types";

export function ZonePicker(props) {
  const zones = props.zones || defaultZones;

  const [value, setValue] = useState(props.value || zones[0].zones[0].value);
  const timestamp = moment(props.forTime || new Date());

  // Call onChange immediately to set the initial time zone if none was passed.
  useEffect(() => {
    if (props.value !== value && typeof props.onChange === "function") {
      props.onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      value={value}
      onChange={(e) => {
        setValue(e.target.value);

        if (typeof props.onChange === "function") {
          props.onChange(e.target.value);
        }
      }}
    >
      {zones.map((country) => (
        <optgroup label={country.country} key={country.country}>
          {country.zones.map((zone) => (
            <option value={zone.value}>
              {zone.label} ({timestamp.tz(zone.value).zoneAbbr()})
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  );
}

ZonePicker.propTypes = {
  /**
   * The current time zone value in IANA/Olson format: America/New_York
   */
  value: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,

  /**
   * A date to use as the basis for the time zone abbreviation lookup. This can affect whether you get
   * PST or PDT, for example, based on the time of year the provided date is in. Defaults to now.
   */
  forTime: PropTypes.instanceOf(Date),

  /**
   * An alternative source of timezones to override the defaults.
   */
  zones: PropTypes.arrayOf({
    country: PropTypes.string.isRequired,
    zones: PropTypes.arrayOf({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
