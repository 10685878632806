import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ViewRulesetsPage.module.css";
import FilterRuleset from "Events/Components/FilterRuleset";
import RulesetList from "Events/Components/RulesetList";
import Header from "shared/Header";
import { useViewRulesetsPage } from "./useViewRulesetsPage";
import { useCurrentRoute } from "../../../../src/shared/@hooks/useCurrentRoute";
import Loader from "shared/Loader";

/**
 * A page under events page in options to view rulesets.
 */

export function ViewRulesetsPage() {
  let history = useHistory();

  // const { data, status, refetch, isLoading } = useViewRulesetsPage();
  const {
    rulesetData,
    rulesetStatus,
    refetchRulesetData,
    isRulesetDataLoading,
  } = useCurrentRoute();

  const [rulesetState, setRulesetState] = useState(undefined);
  const [selectOptions, setSelectOptions] = useState({
    All: ["All"],
    Major: ["Major"],
    LOS: ["LOS"],
    Local: ["Local"],
  });

  const [eventOptions, setEventOptions] = useState(["All"]);

  useEffect(() => {
    if (rulesetData) {
      setRulesetState(rulesetData);
      let options = ["All"];
      rulesetData.map((item, index) => {
        // console.log(rulesetData);
        item.events.map((event) => {
          return options.push(event.name.toString());
        });
      });
      setEventOptions(options);
    }
  }, [rulesetData]);

  // FILTER
  const handleFiltering = (filter) => {
    let eventNameData = null;
    let searchData = null;
    let eventData = null;

    if (filter.ruleset && filter.ruleset !== "All") {
      eventNameData = rulesetData.filter(function (item) {
        return item.name.toLowerCase().includes(filter.ruleset.toLowerCase());
      });
    } else {
      eventNameData = rulesetData;
    }

    if (filter.search) {
      searchData = eventNameData.filter(function (item) {
        let eventMatch = false;
        item.events.map((event) => {
          {
            if (
              event.name.toLowerCase().includes(filter.search.toLowerCase())
            ) {
              eventMatch = true;
            }
          }
        });
        return (
          item.name.toLowerCase().includes(filter.search.toLowerCase()) ||
          eventMatch
        );
      });
    } else {
      searchData = eventNameData;
    }

    if (filter.event && filter.event !== "All") {
      eventData = searchData.filter(function (item) {
        let eventMatch = false;
        item.events.map((event) => {
          {
            if (event.name.toLowerCase().includes(filter.event.toLowerCase())) {
              eventMatch = true;
            }
          }
        });
        return eventMatch;
      });
    } else {
      eventData = searchData;
    }

    setRulesetState(eventData);

    let options = ["All"];

    eventData.map((item, index) => {
      item.events.map((event) => {
        return options.push(event.name.toString());
      });
    });

    setEventOptions(options);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.title}>
            <Header title={"VIEW RULESETS"} onClick={() => history.goBack()} />
          </div>

          <div className={styles.filterRuleset}>
            <FilterRuleset
              onClick={handleFiltering}
              rulesetData={selectOptions}
              eventOptions={eventOptions}
            />
          </div>

          <div className={styles.rulesetHeader}>
            <div>RULESET</div>
          </div>
        </div>

        <div className={styles.rulesetList}>
          {rulesetState && <RulesetList rulesets={rulesetState} />}
          {isRulesetDataLoading && <Loader />}
        </div>
      </div>
    </>
  );
}

ViewRulesetsPage.propTypes = {};
