import React from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import styles from "./VirtualV2Form.module.css";
import InputItem from "Events/Components/InputItem";
import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import { Select } from "shared/Select/Select";

//Form to fill out information about the virtual event location
export function VirtualV2Form({ type, link, onVirtualChange }) {
  return (
    <Card
      children={
        <section>
          <div className={styles.header}>
            <div className={styles.title}>VIRTUAL</div>
            <hr className={styles.hr} />

            <div className={styles.container}>
              <div className={styles.row}>
                <span className={styles.label}>Virtual Event Type</span>
                <span className={styles.visibleTo}>
                  <Select
                    placeholder={type || "Select"}
                    value={type}
                    options={[
                      "Zoom",
                      "Microsoft Teams",
                      "Dreamstream",
                      "Other",
                    ]}
                    onChange={(value) => onVirtualChange(value, "type")}
                  />
                </span>
              </div>

              <InputItem
                label="Event Link"
                placeholder="Click to type"
                value={link || ""}
                onChange={(e) => onVirtualChange(e.target.value, "link")}
              />
            </div>
          </div>
        </section>
      }
    />
  );
}

VirtualV2Form.propTypes = {
  type: PropTypes.string,
  setType: PropTypes.func,
  link: PropTypes.string,
  setLink: PropTypes.func,
};

VirtualV2Form.defaultProps = {
  type: "",
  link: "",
};
