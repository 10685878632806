import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import { ChevronDown } from "react-ikonate";

export default function OptionSelector({ className, children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key.toLowerCase() === "w") {
      const options = selectRef.current.options;
      for (let i = 0; i < options.length; i++) {
        if (options[i].text.toLowerCase().startsWith("w")) {
          selectRef.current.selectedIndex = i;
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={styles.container} onClick={handleToggleDropdown}>
      <select
        ref={selectRef}
        className={classNames(styles.select, className)}
        {...props}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
      >
        {children}
      </select>
      <div className={styles.icon}>
        <ChevronDown width={24} height={24} />
      </div>
    </div>
  );
}
