import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./ImportList.module.css";
import classNames from "classnames";
import ImportRow from "Events/Components/ImportRow";
import TableSortItem from "shared/TableSortItem";
import { useImportPost } from "Events/Components/ImportModal/useImportEvents";

/**
 * Displays the information of all past Imports with a header and pagination on bottom.
 * Columns can be sorted in alphabetical and reverse alphabetical order by clicking the array next to the column label
 */

export function ImportList({ ImportList, onEdit }) {
  const { importData, importEvent, isImporting, isSuccess } = useImportPost(); // Assuming these states are managed globally

  const [paginatedImportList, setPaginatedImportList] = useState([]);
  const [showEditImport, setShowEditImport] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    displayName: "desc",
    location: "",
    startsOn: "",
  });
  useEffect(() => {
    const sortedByImportName = [...ImportList].sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
    setPaginatedImportList(sortedByImportName);
  }, [ImportList]);

  const sortAndFilterImports = (imports, sortProperty, isAsc) => {
    const sortedImports = [...imports].sort((a, b) => {
      let valA = a[sortProperty];
      let valB = b[sortProperty];

      if (sortProperty === "startsOn") {
        valA = valA ? new Date(valA).getTime() : isAsc ? -Infinity : Infinity;
        valB = valB ? new Date(valB).getTime() : isAsc ? -Infinity : Infinity;
        return isAsc ? valA - valB : valB - valA;
      } else {
        valA = valA ? valA.toString() : "";
        valB = valB ? valB.toString() : "";
        return isAsc ? valA.localeCompare(valB) : valB.localeCompare(valA);
      }
    });

    return sortedImports;
  };

  const handleSortChange = (property) => {
    const isAscending = sortOrder[property] === "desc";
    const newSortOrder = {
      ...sortOrder,
      [property]: isAscending ? "asc" : "desc",
    };

    setSortOrder(newSortOrder);

    const sortedList = sortAndFilterImports(
      paginatedImportList,
      property,
      isAscending
    );
    setPaginatedImportList(sortedList);
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.flexContainer} ${styles.slightlyDarkerBackground}`}
      >
        <div className={`${styles.flexItem} ${styles.nameHeader}`}>
          <TableSortItem
            value={sortOrder.name}
            title="Event Name"
            onChange={() => handleSortChange("displayName")}
          />
        </div>
        <div className={`${styles.flexItem} ${styles.locationHeader}`}>
          <TableSortItem
            value={sortOrder.location}
            title="Location"
            onChange={() => handleSortChange("location")}
          />
        </div>
        <div className={`${styles.flexItem} ${styles.startsOnHeader}`}>
          <TableSortItem
            value={sortOrder.startsOn}
            title="Starts On"
            onChange={() => handleSortChange("startsOn")}
          />
        </div>
      </div>
      <div className={styles.listContainer}>
        {paginatedImportList.map((row, index) => (
          <div
            className={classNames(
              styles.whiteBG,
              index % 2 == 1 && styles.slightlyDarkerBackground
            )}
            key={index}
          >
            <ImportRow
              key={row.id}
              Import={row}
              onImport={importEvent}
              // isImporting={isImporting}
              // isSuccess={isSuccess}
              importData={importData}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

ImportList.propTypes = {
  ImportList: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
};
