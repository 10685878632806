import "./shared/variables.css";
import "./index.css";
import "@wwg/ui/dist/index.css";

import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactModal from "react-modal";
import { LoggerState } from "shared/@hooks/useLogger";
import { NavState } from "@wwg/support-ui/dist/Components/Nav/useNavState";
import { PageNavState } from "shared/Page/usePageNavState";
import App from "./App";
import { AuthProvider } from "shared/@auth/Auth";
import * as serviceWorker from "./serviceWorker";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // default to 5 minute cache for all routes
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const root = document.getElementById("root");

ReactModal.setAppElement(root);

// Bypass loading the entire app if route is /health-check
const currentPath = window.location.pathname;

if (currentPath !== "/health-check") {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <LoggerState.Provider>
          <NavState.Provider>
            <PageNavState.Provider>
              <AuthProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </AuthProvider>
            </PageNavState.Provider>
          </NavState.Provider>
        </LoggerState.Provider>
      </QueryClientProvider>
    </React.StrictMode>,
    root
  );
} else {
  ReactDOM.render(<div>Health Check</div>, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
