import { useEffect } from "react";
import { createContainer } from "unstated-next";

import { Auth } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";
import { useMutation, useQuery, useQueryClient } from "react-query";
import createNotification from "shared/@utils/createNotification";

export function useEvent(eventId) {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger({ prefix: "useEvent" });

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["event", eventId],
    async () => {
      if (eventId == null) {
        return null;
      }

      //WORKING (data: eventID) need to match with useEventSearch(data: EventID)
      return makeAuthedRequest(`v1/events/get/${eventId}`).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      });
    }
  );

  useEffect(() => {
    if (isError) {
      logError(error);
    }
  }, [isError, error]);

  const saveTimeZone = useMutation(
    async (timezone) => {
      return makeAuthedRequest(
        `v1/events/timezone`,

        {
          method: "put",
          body: JSON.stringify(timezone),
        }
      );
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Timezone was saved successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const saveSecurity = useMutation(
    async (security) => {
      if (security.id == 0) {
        return makeAuthedRequest(
          `v1/events/security`,

          {
            method: "post",
            body: JSON.stringify(security),
          }
        );
      } else {
        return makeAuthedRequest(
          `v1/events/security`,

          {
            method: "put",
            body: JSON.stringify(security),
          }
        );
      }
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Security was saved successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const deleteSecurity = useMutation(
    async (eventId) => {
      return makeAuthedRequest(
        `v1/events/security/${eventId}`,

        {
          method: "delete",
        }
      );
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Security was deleted successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const saveSession = useMutation(
    async (session) => {
      if (session.id == 0) {
        return makeAuthedRequest(
          `v1/events/session`,

          {
            method: "post",
            body: JSON.stringify(session),
          }
        );
      } else {
        return makeAuthedRequest(
          `v1/events/session`,

          {
            method: "put",
            body: JSON.stringify(session),
          }
        );
      }
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Session was saved successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const deleteSession = useMutation(
    async (eventId) => {
      return makeAuthedRequest(
        `v1/events/session/${eventId}`,

        {
          method: "delete",
        }
      );
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Session was deleted successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const saveLocation = useMutation(
    async (location) => {
      if (location.id == 0) {
        return makeAuthedRequest(
          `v1/events/location`,

          {
            method: "post",
            body: JSON.stringify(location),
          }
        );
      } else {
        return makeAuthedRequest(
          `v1/events/location`,

          {
            method: "put",
            body: JSON.stringify(location),
          }
        );
      }
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Location was saved successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const deleteLocation = useMutation(
    async (eventId) => {
      return makeAuthedRequest(
        `v1/events/location/${eventId}`,

        {
          method: "delete",
        }
      );
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Location was deleted successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  const saveOnSite = useMutation(
    async (info) => {
      return makeAuthedRequest(`v1/events/onsite`, {
        method: "put",
        body: JSON.stringify(info),
      });
    },
    {
      onSuccess: () => {
        // Just refesh the whole event for now
        queryClient.invalidateQueries(["event", eventId]);

        createNotification({
          type: "success",
          title: "Saved",
          message: "Event Info (onsite) was saved successfully.",
          autoDismiss: true,
        });
      },
    }
  );

  return {
    isLoading,
    isError,
    event: data,
    saveTimeZone,
    saveSecurity,
    deleteSecurity,
    saveSession,
    deleteSession,
    saveLocation,
    deleteLocation,
    saveOnSite,
    error,
    refreshEvent: refetch,
  };
}

export const Event = createContainer(useEvent);
