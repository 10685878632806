import React from "react";
import Input from "shared/@forms/Input";
import { ENVIRONMENT_CONFIG } from "shared/globals";

// Our DB needs a very specific format. This is only compatible with US numbers
// and numbers sharing the same format, e.g. (111) 222-3333
export const phonePattern =
  ENVIRONMENT_CONFIG.phoneNumberPattern ||
  "^\\(?(\\d{3})\\)?[-.\\s]?(\\d{3})[-.\\s]?(\\d{4})$";

export default function PhoneInput({ ...props }) {
  return <Input type="text" pattern={phonePattern} {...props} />;
}
