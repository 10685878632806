import React, { useState, useEffect } from "react";
import { Modal } from "../../../shared/Modal";
import { Select } from "../../../shared/Select/Select";
import SearchBar from "shared/SearchBar";
import SpeakerItem from "Events/Components/SpeakerItem";
import PaginationControls from "shared/PaginationControls";
import Button from "shared/Button";
import TableSortHeader, { TableSortValue } from "shared/TableSortHeader";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./AddSpeakerModal.module.css";
import ConfirmSpeakerModal from "Events/Components/ConfirmSpeakerModal";

/**
 * Modal that displays the speakers and allows you to filter them
 */
export function AddSpeakerModal({ isOpen, onCollapse, onClick }) {
  //State declarations
  const [searchInput, changeSearchInput] = useState("");
  const [dropDownSelection, changeDropDownSelection] = useState("All");
  const [currentPage, changeCurrentPage] = useState(1);
  const [displayedSpeakers, changeDisplayedSpeakers] = useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);

  const [sortStatus, changeSortStatus] = useState({
    name: TableSortValue.ascending,
    ibo: TableSortValue.ascending,
    userName: TableSortValue.ascending,
    email: TableSortValue.ascending,
    lastScheduled: TableSortValue.ascending,
    pinLevel: TableSortValue.ascending,
  });
  const [openConfirModal, setOpenConfirmModal] = useState(false);

  const speakers = [
    {
      name: "John And Jane Smith",
      iboNum: "1234444",
      userName: "smith.john1",
      email: "john.smith@email.com",
      lastScheduled: "01/22/2022",
      pinLevel: "Emerald",
    },
    {
      name: "George Smith",
      iboNum: "5688878",
      userName: "smith.george1",
      email: "george.smith@email.com",
      lastScheduled: "01/22/2022",
      pinLevel: "Platinum",
    },
    {
      name: "Jane Brown",
      iboNum: "1111111",
      userName: "brown.jane",
      email: "jane.brown@email.com",
      lastScheduled: "02/22/2022",
      pinLevel: "Platinum",
    },
    {
      name: "Barry and Jill Clark",
      iboNum: "2222222",
      userName: "clark.barry",
      email: "barry.clark@email.com",
      lastScheduled: "03/22/2022",
      pinLevel: "Emerald",
    },
    {
      name: "Barbara and Tom Gifford",
      iboNum: "3333333",
      userName: "gifford.barbara",
      email: "barbara.gifford@email.com",
      lastScheduled: "04/22/2022",
      pinLevel: "Diamond",
    },
    {
      name: "John And Jane Mendez",
      iboNum: "4444444",
      userName: "smith.john1",
      email: "john.smith@email.com",
      lastScheduled: "01/22/2022",
      pinLevel: "Emerald",
    },
    {
      name: "Janice Brown",
      iboNum: "5555555",
      userName: "brown.jane",
      email: "jane.brown@email.com",
      lastScheduled: "02/22/2022",
      pinLevel: "Diamond",
    },
    {
      name: "Barry and Jill Clark",
      iboNum: "6666666",
      userName: "clark.barry",
      email: "barry.clark@email.com",
      lastScheduled: "03/22/2022",
      pinLevel: "Platinum",
    },
    {
      name: "Barbs and Tom Gifford",
      iboNum: "7777",
      userName: "gifford.barbara",
      email: "barbara.gifford@email.com",
      lastScheduled: "04/22/2022",
      pinLevel: "Diamond",
    },
    {
      name: "Johnny And Jane Smith",
      iboNum: "881288",
      userName: "smith.john1",
      email: "john.smith@email.com",
      lastScheduled: "01/22/2022",
      pinLevel: "Emerald",
    },
    {
      name: "Janeth Brown",
      iboNum: "999999",
      userName: "brown.jane",
      email: "jane.brown@email.com",
      lastScheduled: "02/22/2022",
      pinLevel: "Diamond",
    },
  ];

  const hardcodedIBO = {
    names: ["John Doe", "Jane Smith"],
    userName: "johndoe123",
    iboNum: "5555555",
    email: "john.doe@example.com",
  };

  //Function that handles splitting the array in to seperate pages
  const pagesSplit = (options) => {
    let splitOptions = [];
    for (let i = 0, length = options.length; i < length; i += 5) {
      splitOptions.push(options.slice(i, i + 5));
    }
    return splitOptions;
  };

  //Function to merge filtered results and then re-split them in to pages of 7 again
  const mergeAndSplitOptions = (options) => {
    const merged = pagesSplit(options.reduce((a, b) => a.concat(b), []));
    return merged;
  };

  //Function to reset search options
  const resetSearch = () => changeDisplayedSpeakers(pagesSplit(speakers));

  //Initial split of speakers in to pages
  useEffect(() => {
    changeDisplayedSpeakers(pagesSplit(speakers));
  }, []);

  //Function used for searching and filtering results
  const handleSearch = () => {
    let filtered = [];
    if (searchInput !== "") {
      filtered = speakers.filter((speaker) =>
        speaker.name.toLowerCase("").includes(searchInput)
      );
      if (dropDownSelection === "All") {
        changeCurrentPage(1);
        changeDisplayedSpeakers(mergeAndSplitOptions(filtered));
      } else {
        filtered = filtered.filter((speaker) =>
          speaker.pinLevel.includes(dropDownSelection)
        );
        changeCurrentPage(1);
        changeDisplayedSpeakers(mergeAndSplitOptions(filtered));
      }
    } else if (dropDownSelection !== "All") {
      filtered = speakers.filter((speaker) =>
        speaker.pinLevel.includes(dropDownSelection)
      );
      changeCurrentPage(1);
      changeDisplayedSpeakers(mergeAndSplitOptions(filtered));
    } else {
      //Resets the speakers display if the search bar is cleared
      resetSearch();
    }
  };

  const handleDropDownSelection = (selection) => {
    changeDropDownSelection(selection);
  };

  //Function that handles the selecting and deselecting of speakers
  const handleSpeakerSelection = (selection) => {
    // Check if the speaker is already in the selectedSpeakers array
    const isSelected = selectedSpeakers.some(
      (speaker) => speaker.name === selection.name
    );

    let newSelections;
    if (isSelected) {
      // If already selected, remove from list
      newSelections = selectedSpeakers.filter(
        (speaker) => speaker.name !== selection.name
      );
    } else {
      // Otherwise, add to the list
      newSelections = [...selectedSpeakers, selection];
    }

    setSelectedSpeakers(newSelections);
  };

  //Handle sorting asc and desc
  const handleSort = (field, direction) => {
    const compareFunction = (a, b) => {
      // Check if both values are numbers
      const aIsNumber = !isNaN(a[field]);
      const bIsNumber = !isNaN(b[field]);

      if (aIsNumber && bIsNumber) {
        return direction * (a[field] - b[field]);
      } else {
        const aStr = String(a[field]).toLowerCase();
        const bStr = String(b[field]).toLowerCase();
        return direction * aStr.localeCompare(bStr);
      }
    };

    const sortedSpeakers = displayedSpeakers.map((page) =>
      page.sort(compareFunction)
    );

    changeCurrentPage(1);
    changeDisplayedSpeakers(mergeAndSplitOptions(sortedSpeakers));
  };

  const handlePaging = (page) => {
    changeCurrentPage(page);
  };

  const handleSubmit = () => {
    onClick(selectedSpeakers);
    // onClick(selectedSpeaker);

    setSelectedSpeakers([]);

    setOpenConfirmModal(true);
    onCollapse();
  };

  //Handles add speaker in confirm modal
  const handleAddSpeaker = () => {
    alert("Added speaker!");
    setOpenConfirmModal(false);
    onCollapse();
  };

  const selectOptions = ["All", "Emerald", "Diamond", "Platinum"];

  return (
    <div className={styles.container}>
      <Modal
        title="SELECT SPEAKER"
        onClose={() => onCollapse()}
        isOpen={isOpen}
        className={styles.modal}
        children={
          <>
            <div className={styles.filterContainer}>
              <div className={classNames(styles.flexCol)}>
                <span className={styles.title}>Pin Level</span>
                <div className={styles.pinLevel}>
                  <Select
                    placeholder={dropDownSelection}
                    options={selectOptions}
                    onChange={handleDropDownSelection}
                  />
                </div>
              </div>
              <div className={classNames(styles.flexCol, styles.search)}>
                <SearchBar
                  placeholderText="Click to type"
                  onInput={(event) => changeSearchInput(event.toLowerCase())}
                />
              </div>
              <div className={classNames(styles.flexCol, styles.button)}>
                <Button children="UPDATE" onClick={() => handleSearch()} />
              </div>
            </div>
            <div className={styles.speakersListOutput}>
              <span className={styles.tableTitle}>Speakers</span>
              <div className={styles.hr} />
              <table className={styles.tableContainer}>
                <tr className={styles.tableHeader}>
                  <th className={styles.checkboxHeader} />
                  <th
                    className={classNames(
                      styles.tableSorter,
                      styles.nameHeader
                    )}
                  >
                    <TableSortHeader
                      value={sortStatus.name}
                      onChange={(newValue) => {
                        switch (newValue) {
                          case TableSortValue.ascending:
                            changeSortStatus({
                              ...sortStatus,
                              name: TableSortValue.ascending,
                            });
                            handleSort("name", 1);
                            break;
                          case TableSortValue.descending:
                            changeSortStatus({
                              ...sortStatus,
                              name: TableSortValue.descending,
                            });
                            handleSort("name", -1);
                            break;
                          default:
                            changeSortStatus({
                              ...sortStatus,
                              name: TableSortValue.none,
                            });
                            changeDisplayedSpeakers(pagesSplit(speakers));
                            break;
                        }
                      }}
                      children="Name"
                    />
                  </th>
                  <th
                    className={classNames(styles.tableSorter, styles.iboHeader)}
                  >
                    <TableSortHeader
                      value={sortStatus.ibo}
                      onChange={(newValue) => {
                        switch (newValue) {
                          case TableSortValue.ascending:
                            changeSortStatus({
                              ...sortStatus,
                              ibo: TableSortValue.ascending,
                            });
                            handleSort("iboNum", 1);
                            break;
                          case TableSortValue.descending:
                            changeSortStatus({
                              ...sortStatus,
                              ibo: TableSortValue.descending,
                            });
                            handleSort("iboNum", -1);
                            break;
                          default:
                            changeSortStatus({
                              ...sortStatus,
                              ibo: TableSortValue.none,
                            });
                            changeDisplayedSpeakers(pagesSplit(speakers));
                            break;
                        }
                      }}
                      children="IBO #"
                    />
                  </th>
                  <th
                    className={classNames(
                      styles.tableSorter,
                      styles.usernameHeader
                    )}
                  >
                    <TableSortHeader
                      value={sortStatus.userName}
                      onChange={(newValue) => {
                        switch (newValue) {
                          case TableSortValue.ascending:
                            changeSortStatus({
                              ...sortStatus,
                              userName: TableSortValue.ascending,
                            });
                            handleSort("userName", 1);
                            break;
                          case TableSortValue.descending:
                            changeSortStatus({
                              ...sortStatus,
                              userName: TableSortValue.descending,
                            });
                            handleSort("userName", -1);
                            break;
                          default:
                            changeSortStatus({
                              ...sortStatus,
                              userName: TableSortValue.none,
                            });
                            changeDisplayedSpeakers(pagesSplit(speakers));
                            break;
                        }
                      }}
                      children="Username"
                    />
                  </th>
                  <th
                    className={classNames(
                      styles.tableSorter,
                      styles.emailHeader
                    )}
                  >
                    <TableSortHeader
                      value={sortStatus.email}
                      onChange={(newValue) => {
                        switch (newValue) {
                          case TableSortValue.ascending:
                            changeSortStatus({
                              ...sortStatus,
                              email: TableSortValue.ascending,
                            });
                            handleSort("email", 1);
                            break;
                          case TableSortValue.descending:
                            changeSortStatus({
                              ...sortStatus,
                              email: TableSortValue.descending,
                            });
                            handleSort("email", -1);
                            break;
                          default:
                            changeSortStatus({
                              ...sortStatus,
                              email: TableSortValue.none,
                            });
                            changeDisplayedSpeakers(pagesSplit(speakers));
                            break;
                        }
                      }}
                      children="Email"
                    />
                  </th>
                  <th
                    className={classNames(
                      styles.tableSorter,
                      styles.lastScheduledHeader
                    )}
                  >
                    <TableSortHeader
                      value={sortStatus.lastScheduled}
                      onChange={(newValue) => {
                        switch (newValue) {
                          case TableSortValue.ascending:
                            changeSortStatus({
                              ...sortStatus,
                              lastScheduled: TableSortValue.ascending,
                            });
                            handleSort("lastScheduled", 1);
                            break;
                          case TableSortValue.descending:
                            changeSortStatus({
                              ...sortStatus,
                              lastScheduled: TableSortValue.descending,
                            });
                            handleSort("lastScheduled", -1);
                            break;
                          default:
                            changeSortStatus({
                              ...sortStatus,
                              lastScheduled: TableSortValue.none,
                            });
                            changeDisplayedSpeakers(pagesSplit(speakers));
                            break;
                        }
                      }}
                      children="Last Scheduled"
                    />
                  </th>
                  <th
                    className={classNames(
                      styles.tableSorter,
                      styles.pinLevelHeader
                    )}
                  >
                    <TableSortHeader
                      value={sortStatus.pinLevel}
                      onChange={(newValue) => {
                        switch (newValue) {
                          case TableSortValue.ascending:
                            changeSortStatus({
                              ...sortStatus,
                              pinLevel: TableSortValue.ascending,
                            });
                            handleSort("pinLevel", 1);
                            break;
                          case TableSortValue.descending:
                            changeSortStatus({
                              ...sortStatus,
                              pinLevel: TableSortValue.descending,
                            });
                            handleSort("pinLevel", -1);
                            break;
                          default:
                            changeSortStatus({
                              ...sortStatus,
                              pinLevel: TableSortValue.none,
                            });
                            changeDisplayedSpeakers(pagesSplit(speakers));
                            break;
                        }
                      }}
                      children="Pin Level"
                    />
                  </th>
                </tr>
                {displayedSpeakers.length > 0 &&
                  displayedSpeakers[currentPage - 1].map((speaker, key) => (
                    <SpeakerItem
                      speaker={speaker}
                      key={key}
                      onSelect={() => handleSpeakerSelection(speaker)}
                    />
                  ))}
              </table>
              <div className={styles.pageContainer}>
                <PaginationControls
                  color="#1f3bd8"
                  lastPage={displayedSpeakers.length}
                  onPageChange={(page) => handlePaging(page)}
                  page={currentPage}
                  disableNext={currentPage === displayedSpeakers.length}
                />
              </div>
            </div>
            <div className={styles.addButton}>
              <Button children="ADD" onClick={() => handleSubmit()} />
            </div>
          </>
        }
      />

      <ConfirmSpeakerModal
        IBO={hardcodedIBO}
        selectedSpeakers={selectedSpeakers}
        isOpen={openConfirModal}
        onCollapse={() => setOpenConfirmModal(false)}
        onClick={handleAddSpeaker}
      />
    </div>
  );
}

AddSpeakerModal.propTypes = {
  /**
   * Getter to determine if modal is displayed
   */
  isOpen: PropTypes.bool,
  /**
   * Callback function for when the modal is closed
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when the add btn is clicked (opens the confirm speaker modal)
   */
  onClick: PropTypes.func,
  /**
   * Array of speakers to display
   */
  speakers: PropTypes.arrayOf(PropTypes.shape({})),
};
