import React, { useEffect, useMemo, useState, useRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "./CartItem.module.css";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 } from "uuid";

import Select from "Events/Components/Select";
import Loader from "shared/Loader";
import Checkbox from "shared/@forms/Checkbox";

import SpecialNeedsModal from "Events/Components/SpecialNeedsModal";
import options from "Events/Components/SpecialNeedsModal/options";
import { useOrderInfo } from "./useCartItem";

/**
 * Interactive component.
 * Allows the user to select/purchase tickets for a specific event.
 */
export function CartItem({
  event,
  id,
  iboNumber,
  setCartLoading,
  setTicketWarnings,
  //setCartWarnings,
  setSubtotal,
  // setTax,
  setCreditCards,
  setShowCreditCards,
  setShowButtons,
  setDisplayAddToCart,
  itemTotalPrices,
  setItemTotalPrices,
  doRefetch,
  updateTicketData,
  updateTicketWarnings,
  testTicket,
  setTestTicket,
}) {
  const {
    data: orderInfoData,
    isLoading: orderInfoLoading,
    refetch: orderInfoRefetch,
  } = useOrderInfo(iboNumber, id);

  useEffect(() => {
    if (orderInfoData) {
      setCartLoading(orderInfoLoading);
    }
  }, [orderInfoData]);

  const [isRefetchComplete, setIsRefetchComplete] = useState(false);
  useEffect(() => {
    setCartLoading(true);

    orderInfoRefetch().then(() => {
      setCartLoading(false);
      setIsRefetchComplete(true);
    });
  }, [id, doRefetch]);

  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    updateTicketData(id, tickets);
  }, [tickets]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [warnings, setWarnings] = useState([]);

  const [taxRate, setTaxRate] = useState(0);
  // const [creditCards, setCreditCards] = useState([]);
  const [ticketQuantity, setTicketQuantity] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [prevSubtotal, setPrevSubtotal] = useState(0);
  const [prevTax, setPrevTax] = useState(0);

  useEffect(() => {
    if (tickets.length > 0) {
      const newSubtotal = calculateSubtotal();
      // const newTax = (newSubtotal * taxRate) / 100;

      const subtotalDiff = newSubtotal - prevSubtotal;
      // const taxDiff = newTax - prevTax;

      setSubtotal((prevSubtotal) => prevSubtotal + subtotalDiff);
      // setTax((prevTax) => prevTax + taxDiff);

      setPrevSubtotal(newSubtotal);
      // setPrevTax(newTax);

      setItemTotalPrices((prevItemTotalPrices) =>
        prevItemTotalPrices.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              price: newSubtotal, // Update price with the new subtotal
              taxRate: orderInfoData.taxRate,
            };
          }
          return item;
        })
      );
    }
  }, [tickets, taxRate, id]);

  useEffect(() => {
    if (orderInfoData && isRefetchComplete) {
      setTicketOptions(
        orderInfoData.tickets.map((ticket) => ({
          id: ticket.id,
          name: ticket.name,
          price: ticket.price,
          warning: ticket.warning,
          taxRate: ticket.taxRate || 0,
          ticketId: ticket.ticketId,
        }))
      );

      if (tickets.length < 1) {
        addNewTicketType();
      }

      const warningsWithId = orderInfoData.warnings.map((warning) => ({
        id: null,
        message: warning || "",
        cartItemId: id,
      }));

      warningsWithId.forEach((warning) => {
        updateTicketWarnings(id, warning, "add");
      });

      setIsRefetchComplete(false);

      // setTax(orderInfoData.taxRate);
      setCreditCards((prevCreditCards) => [
        ...prevCreditCards,
        {
          id: id,
          creditCards: orderInfoData.creditCards,
        },
      ]);

      setItemTotalPrices((prev) => [
        ...prev,
        {
          id: id,
          price: 0,
          taxRate: orderInfoData.taxRate,
        },
      ]);
    }
  }, [orderInfoData, isRefetchComplete]);

  const [addTicket, setAddTicket] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accomodations, setAccommodations] = useState([]);
  const [ticketType, setTicketType] = useState("");

  useEffect(() => {
    if (addTicket) {
      setTimeout(() => setAddTicket(false), 500);
    }
  }, [addTicket]);

  const currentKeyRef = useRef();

  const handleOptionChange = (option) => {
    setAccommodations((prev) => {
      if (prev.includes(option)) {
        return prev.filter((opt) => opt !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const addNewTicketType = () => {
    const newTicket = {
      id: v4(),
      name: "",
      quantity: 1,
      price: 0,
      adaValues: [],
      addAccommodation: false,
      ticketId: "",
    };

    setTickets((prevTickets) => [...prevTickets, newTicket]);

    setDisplayAddToCart(id, false);
  };

  const calculatePrice = (ticket) => {
    const price = parseFloat(ticket.price);
    const qty = parseInt(ticket.quantity, 10);

    if (isNaN(price) || isNaN(qty) || qty <= 0) return "0.00";

    const totalPrice = (price * qty).toFixed(2);
    return totalPrice;
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedTickets = tickets.map((ticket, i) =>
      i === index ? { ...ticket, quantity } : ticket
    );

    setTickets(updatedTickets);
  };

  const [modalState, setModal] = useState({ visible: false, children: [] });

  function handleSave(updatedTicket) {
    if (!updatedTicket || typeof updatedTicket !== "object") {
      console.error("Updated ticket is undefined or not an object");
      return;
    }

    const ticketIndex = tickets.findIndex(
      (ticket) => ticket.id === updatedTicket.id
    );
    if (ticketIndex !== -1) {
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === updatedTicket.id
            ? { ...ticket, adaValues: updatedTicket.adaValues }
            : ticket
        )
      );
      setOpenModal(false);
    } else {
      console.error("Ticket not found for update");
    }
  }

  function onModalCloseClick(ticketId) {
    const updatedTickets = tickets.map((ticket) =>
      ticket.id === ticketId ? { ...ticket, addAccommodation: false } : ticket
    );

    setTickets(updatedTickets);
    setOpenModal(false);
  }

  function getAccommodationLabels(adaValues) {
    const labels = [];

    if (adaValues.includes(32768)) {
      labels.push("Accessible Seating");
    }
    if (adaValues.includes(131072)) {
      labels.push("Accessible Seating Companion Ticket");
    }
    if (adaValues.includes(4)) {
      labels.push("Hearing Amplification Device");
    }
    if (adaValues.includes(8)) {
      labels.push("American Sign Language Interpreter");
    }
    if (adaValues.includes(65536)) {
      labels.push("Hearing Accessible Seating Companion Ticket");
    }
    if (adaValues.includes(262144)) {
      labels.push("Nursing Mother");
    }

    return labels;
  }

  const handleTicketChange = (index, updatedTicket) => {
    // Step 1: Update the ticket at the given index
    const updatedTickets = tickets.map((ticket, idx) =>
      idx === index ? updatedTicket : ticket
    );

    // Step 2: Filter out tickets with a quantity of 0
    const filteredTickets = updatedTickets.filter(
      (ticket) => ticket.quantity > 0
    );

    // Step 3: Update the state with the filtered tickets
    return filteredTickets;
  };

  const handleRemoveTicket = (index) => {
    const ticketIdToRemove = tickets[index]?.id;

    setTickets((prevTickets) => prevTickets.filter((_, i) => i !== index));

    updateTicketWarnings(id, {id: tickets[index].ticketId}, "delete");
  };

  const handleAddAccommodation = (id) => {
    setOpenModal(true);
    // setTickets((prevTickets) =>
    //   prevTickets.map((ticket) =>
    //     ticket.id === id ? { ...ticket, addAccommodation: true } : ticket
    //   )
    // );
  };

  const calculateSubtotal = () => {
    return tickets.reduce((total, ticket) => {
      const price = parseFloat(ticket.price) || 0;
      const quantity = parseInt(ticket.quantity, 10) || 0;
      return total + price * quantity;
    }, 0);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.event}>
          <p className={styles.eventName}>{event.eventName}</p>
          <p>{event.location}</p>
          <p>{event.dates}</p>
        </div>
        <div
          className={classNames(
            styles["list-container"],
            tickets.length > 0 ? styles["has-ticket"] : ""
          )}
        >
          <TransitionGroup component="ul">
            {tickets.map((ticket, index) => (
              <CSSTransition
                timeout={300}
                classNames={{
                  enterActive: styles["fade-enter-active"],
                  enter: styles["fade-enter"],
                  exit: styles["fade-exit"],
                  exitActive: styles["fade-exit-active"],
                }}
                key={index}
              >
                <div className={styles["ticket-container"]}>
                  <label htmlFor={`ticket-quantity-${index}`} hidden>
                    Ticket Quantity {index}
                  </label>
                  <div className={classNames(styles["number-input-container"])}>
                    <input
                      className={styles["number-input"]}
                      name={`ticket-quantity-${index}`}
                      type="number"
                      min={1}
                      value={tickets[index].quantity}
                      onClick={(e) => e.target.select()}
                      onChange={(e) =>
                        handleQuantityChange(
                          index,
                          parseInt(e.target.value, 10)
                        )
                      }
                      onKeyDown={({ key }) => (currentKeyRef.current = key)}
                    />
                  </div>
                  <label htmlFor={`ticket-input-${index}`} hidden>
                    Select Ticket Type {index}
                  </label>
                  <div className={styles.tickets}>
                    <Select
                      options={
                        ticketOptions.map((option) => option.name)
                        // .filter(
                        //   (optionName) =>
                        //     !tickets.some((ticket) => ticket.name === optionName)
                        // )
                      }
                      onChange={(selectedTypeName) => {
                        const selectedIndex = ticketOptions.findIndex(
                          (option) => option.name === selectedTypeName
                        );

                        if (selectedIndex === -1) {
                          console.error(
                            "Selected type not found:",
                            selectedTypeName
                          );
                          return;
                        }

                        const selectedPrice = parseFloat(
                          ticketOptions[selectedIndex].price
                        );
                        const selectedWarning =
                          ticketOptions[selectedIndex].warning;
                        const selectedTicketId =
                          ticketOptions[selectedIndex].ticketId;

                        const updatedTicket = {
                          ...tickets[index],
                          name: selectedTypeName,
                          price: selectedPrice,
                          ticketId: selectedTicketId,
                          quantity: tickets[index].quantity
                            ? tickets[index].quantity
                            : 1,
                        };

                        const updatedTickets = tickets.map((ticket, i) =>
                          i === index ? updatedTicket : ticket
                        );

                        setTickets(updatedTickets);
                        //   const ticketIds = updatedTickets.map(
                        //     (ticket) => ticket.ticketId
                        //   );

                        //   // Filter existing warnings based on updated ticket IDs
                        //   const updatedWarnings = prevWarnings.filter(
                        //     (warning) =>
                        //       warning.id === null ||
                        //       ticketIds.includes(warning.id)
                        //   );

                        //   // Check for duplicates and manage the display property
                        //   const messageCartItemIdPair = new Map();

                        //   updatedWarnings.forEach((warning) => {
                        //     const key = `${warning.message}-${warning.cartItemId}`;
                        //     if (!messageCartItemIdPair.has(key)) {
                        //       messageCartItemIdPair.set(key, []);
                        //     }
                        //     messageCartItemIdPair.get(key).push(warning);
                        //   });

                        //   // Determine display status for new and existing warnings
                        //   const warningExists = messageCartItemIdPair.has(
                        //     `${selectedWarning}-${id}`
                        //   );

                        //   if (selectedWarning) {
                        //     const warningsWithSameMessageAndCartItemId =
                        //       messageCartItemIdPair.get(
                        //         `${selectedWarning}-${id}`
                        //       ) || [];

                        //     const shouldDisplay =
                        //       !warningExists ||
                        //       warningsWithSameMessageAndCartItemId.length === 0;

                        //     updatedWarnings.push({
                        //       id: selectedTicketId,
                        //       message: selectedWarning,
                        //       cartItemId: id,
                        //       display: shouldDisplay,
                        //     });
                        //   }

                        //   // Update display property for duplicates
                        //   const updatedWarningsWithDisplay =
                        //     updatedWarnings.map((warning) => {
                        //       const key = `${warning.message}-${warning.cartItemId}`;
                        //       if (messageCartItemIdPair.has(key)) {
                        //         const warnings = messageCartItemIdPair.get(key);
                        //         if (warnings.length > 1) {
                        //           if (!warnings.find((w) => w.display)) {
                        //             warning.display = true;
                        //           }
                        //         }
                        //       }
                        //       return warning;
                        //     });

                        //   return updatedWarningsWithDisplay;
                        // });

                        setTestTicket({
                          cartItemId: id,
                          id: selectedTicketId,
                          message: ticketOptions[selectedIndex].warning,
                        })
                        

                        if (selectedTypeName !== "Select Ticket Type") {
                          setShowCreditCards(true);
                          setShowButtons(true);
                        }

                        const allTicketsSelected = updatedTickets.every(
                          (ticket) =>
                            ticket.name && ticket.name !== "Select Ticket Type"
                        );

                        setDisplayAddToCart(id, allTicketsSelected);
                      }}
                      placeholder={tickets[index]?.name || "Select Ticket Type"}
                      name={`ticket-input-${index}`}
                    />

                    <div className={styles.accommodation}>
                      {ticket.adaValues.length > 0 && (
                        <span className={styles.light}>
                          Accommodation Requested:
                        </span>
                      )}

                      {ticket.adaValues.length > 0 &&
                        getAccommodationLabels(ticket.adaValues).map(
                          (label) => (
                            <div className={styles.list}>
                              <span className={styles.light}>{label}</span>
                            </div>
                          )
                        )}

                      {!ticket.addAccommodation && openModal && (
                        <div className={styles.modalOverlay}>
                          <div className={styles.specialNeedsModal}>
                            {" "}
                            <SpecialNeedsModal
                              ticket={ticket}
                              onCancel={() => onModalCloseClick(ticket.id)}
                              onSave={handleSave}
                            />{" "}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className={classNames(styles["accomodation-checkbox"])}
                    >
                      <label
                        htmlFor={`add-accomodation-checkbox-${index}`}
                        hidden
                      >
                        Add Accommodation
                      </label>
                      <Checkbox
                        className={styles.checkbox}
                        name={`add-accomodation-checkbox-${index}`}
                        checked={ticket.addAccommodation || false}
                        onChange={() => handleAddAccommodation(index)}
                      />

                      <span className={styles.light}>Add Accommodation</span>
                    </div>
                  </div>
                  <div className={styles["price-remove-container"]}>
                    <span
                      className={classNames({
                        [styles.hidden]: !tickets[index].name,
                      })}
                    >
                      $
                      {isNaN(calculatePrice(tickets[index]))
                        ? 0
                        : calculatePrice(tickets[index])}
                    </span>

                    {tickets.length > 1 && (
                      <button
                        className={styles.remove}
                        onClick={() => handleRemoveTicket(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>

          <div
            className={classNames(styles["add-ticket-checkbox"], {
              // [styles.collapse]: tickets.length === ticketOptions.length,
            })}
          >
            <label htmlFor="add-ticket-checkbox" hidden>
              Add Ticket Type
            </label>
            <Checkbox
              className={styles.checkbox}
              name="add-ticket-checkbox"
              checked={addTicket || false}
              onChange={() => {
                if (addTicket === false) {
                  setAddTicket(true);
                  addNewTicketType();
                }
              }}
            />
            <span className={styles.light}>Add Ticket Type</span>
          </div>
        </div>
      </div>
    </>
  );
}

CartItem.propTypes = {
  event: PropTypes.shape({
    eventName: PropTypes.string,
    location: PropTypes.string,
    ruleset: PropTypes.string,
    dates: PropTypes.string,
    ticketsInfo: PropTypes.shape({
      name: PropTypes.string,
      quantity: PropTypes.number,
      adaValues: PropTypes.number,
      type: PropTypes.string,
      price: PropTypes.number,
    }),
  }),
  id: PropTypes.string,
  iboNumber: PropTypes.string,
  onRemove: PropTypes.func,
  getTicketInfo: PropTypes.func,
};
