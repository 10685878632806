import styles from "./index.module.css";
import React from "react";
import PropTypes from "prop-types";

/**
 * Displays an animated loading indicator that sizes itself to its parent container.
 */
export default function Loader({ color, size }) {
  const scale = size ? size / 80 : 1;

  const style = {
    backgroundColor: color || "var(--color-loader, var(--color-text))",
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.wrap}
        style={{ width: `${80 * scale}px`, height: `${40 * scale}px` }}
      >
        <div
          className={styles.ellipsis}
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div style={style}></div>
          <div style={style}></div>
          <div style={style}></div>
          <div style={style}></div>
        </div>
      </div>
    </div>
  );
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
