import { Column, LabelGroup, Row, Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import { useState } from "react";
import { Event } from "Events/Components/useEvent";

import styles from "../index.module.css";
import TextInput from "shared/@forms/TextInput";
import DateTimePicker from "shared/@forms/DateTimePicker";

export default function OnSite({
  eventId,
  scanningGuid,
  checkInStartsOn,
  checkInEndsOn,
  formatDateTime,
  timezone,
  lastDateId,
}) {
  const { saveOnSite } = Event.useContainer();

  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [tempScanningGuid, setTempScanningGuid] = useState(scanningGuid);
  const [tempCheckInStartsOn, setTempCheckInStartsOn] =
    useState(checkInStartsOn);
  const [tempCheckInEndsOn, setTempCheckInEndsOn] = useState(checkInEndsOn);

  function onSave() {
    //TODO: pop message about changing time after saving timezone

    var temp = {
      eventId: eventId,

      scanningGuid: {
        lngId: tempScanningGuid.lngID,
        lngPurchaseTypeId: tempScanningGuid.lngPurchaseTypeID,
        lngEventId: tempScanningGuid.lngEventID,
        strGuid: tempScanningGuid.strGuid,
      },
      checkInStartsOn: {
        dateId: tempCheckInStartsOn.dateID,
        startsOn: tempCheckInStartsOn.startsOn,
      },
      checkInEndsOn: {
        dateId: tempCheckInEndsOn.dateID,
        startsOn: tempCheckInStartsOn.startsOn,
      },
    };

    saveOnSite.mutate(temp);
    setIsEditing(false);
  }

  function onCancel() {
    setTempScanningGuid(scanningGuid);
    setTempCheckInStartsOn(checkInStartsOn);
    setTempCheckInEndsOn(checkInEndsOn);
    setIsEditing(false);
  }

  return (
    <Section>
      <header>
        <h3>General Info</h3>
        <div className={styles.buttonsColumn}>
          {!isEditing && (
            <Button
              size="small"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <>
              <Button
                size="small"
                purpose={ButtonPurpose.save}
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </header>
      <Row>
        <Column>
          <LabelGroup title="Sync ID">
            {!isEditing &&
              (tempScanningGuid ? tempScanningGuid.strGuid : "N/A")}
            {isEditing && (
              <TextInput
                value={tempScanningGuid.strGuid}
                onChange={(e) => {
                  console.log("Sync ID onchanged ", e.target.value);
                  setTempScanningGuid({
                    ...tempScanningGuid,
                    strGuid: e.target.value,
                  });
                }}
              ></TextInput>
            )}
          </LabelGroup>
        </Column>
        <Column>
          <LabelGroup title="Check In - Starts on">
            {!isEditing &&
              tempCheckInStartsOn &&
              formatDateTime(tempCheckInStartsOn.startsOn)}
            {isEditing && (
              <DateTimePicker
                timeZone={timezone}
                value={
                  tempCheckInStartsOn ? tempCheckInStartsOn.startsOn : null
                }
                onChange={(value) => {
                  // console.log("DateTimePicker StartsOn onchanged ", value);
                  setTempCheckInStartsOn({
                    ...tempCheckInStartsOn,
                    startsOn: value,
                  });
                }}
              ></DateTimePicker>
            )}
          </LabelGroup>
        </Column>
        <Column>
          <LabelGroup title="Check In - Ends on">
            {!isEditing &&
              tempCheckInEndsOn &&
              formatDateTime(tempCheckInEndsOn.startsOn)}
            {isEditing && (
              <DateTimePicker
                timeZone={timezone}
                value={tempCheckInEndsOn ? tempCheckInEndsOn.startsOn : null}
                onChange={(value) => {
                  console.log("DateTimePicker EndsOn onchanged ", value);
                  setTempCheckInEndsOn({
                    ...tempCheckInEndsOn,
                    startsOn: value,
                  });
                }}
              ></DateTimePicker>
            )}
          </LabelGroup>
        </Column>
      </Row>
    </Section>
  );
}
