import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./DateV1Form.module.css";
import Card from "shared/Card";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import {
  extractDate,
  extractTime,
  combineDateTime,
  extractDateTime,
} from "shared/@utils/extractCombineDateTime";

import { Select } from "shared/Select/Select";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";

dayjs.extend(utc);
dayjs.extend(timezone);

export function DateV1Form({
  dateInfo,
  location,
  ruleset,
  eventName,
  onDateTimeChange,
  pageMode,
}) {
  const [dateInfoData, setDateInfoData] = useState({
    purchaseStartDate: "",
    purchaseEndDate: "",
    eventStartDate: "",
    eventEndDate: "",
    changeRequestStartDate: "",
    changeRequestEndDate: "",
    eagleStartDate: "",
    eagleStartDateTime: "",
    eagleEndDate: "",
    checkInStartDate: "",
    checkInEndDate: "",
    printReleaseDate: "",
    //local function > regional
    seminarDoorsStartDate: "",
    seminarSessionStartDate: "",
    seminarSessionEndDate: "",
    slmDoorsStartDate: "",
    slmSessionStartDate: "",
    slmSessionEndDate: "",
  });

  const [timeZoneData, setTimeZoneData] = useState({
    purchaseStartTimeZone: "Pacific Time (PT)",
    purchaseEndTimeZone: "Pacific Time (PT)",
    eventStartTimeZone: "Pacific Time (PT)",
    eventEndTimeZone: "Pacific Time (PT)",
    changeRequestStartTimeZone: "Pacific Time (PT)",
    changeRequestEndTimeZone: "Pacific Time (PT)",
    eagleStartTimeZone: "Pacific Time (PT)",
    eagleEndTimeZone: "Pacific Time (PT)",
    checkInStartTimeZone: "Pacific Time (PT)",
    checkInEndTimeZone: "Pacific Time (PT)",
    printReleaseTimeZone: "Pacific Time (PT)",

    //local function > regional
    seminarDoorsStartTimeZone: "Pacific Time (PT)",
    seminarSessionStartTimeZone: "Pacific Time (PT)",
    seminarSessionEndTimeZone: "Pacific Time (PT)",
    slmDoorsStartTimeZone: "Pacific Time (PT)",
    slmSessionStartTimeZone: "Pacific Time (PT)",
    slmSessionEndTimeZone: "Pacific Time (PT)",
  });

  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZoneData);

  const getTimeZoneAbbr = (timeZone) => {
    switch (timeZone) {
      case "America/Los_Angeles":
        return "Pacific Time (PT)";
      case "America/Boise":
        return "Mountain Time (MT)";
      case "America/Chicago":
        return "Central Time (CT)";
      case "America/New_York":
        return "Eastern Time (ET)";
      case "America/Anchorage":
        return "Alaska Time (AKT)";
      case "America/Adak":
        return "Hawaii-Aleutian Time (HAT)";
      case "Pacific/Honolulu":
        return "Hawaii-Honolulu (HST)";
      default:
        return "Pacific Time (PT)";
    }
  };

  const timeZones = [
    "Pacific Time (PT)",
    "Mountain Time (MT)",
    "Central Time (CT)",
    "Eastern Time (ET)",
    "Alaska Time (AKT)",
    "Hawaii-Aleutian Time (HAT)",
    "Hawaii-Honolulu (HST)",
  ];

  useEffect(() => {
    const newDateInfoData = { ...dateInfoData };

    Object.entries(dateInfo?.dates ?? {}).forEach(([key, dateObj]) => {
      const isDateField = key.endsWith("Date");
      const isTimeZoneField = key.endsWith("TimeZone");

      if (isDateField) {
        const dateValue =
          dateObj.saved !== null ? extractDate(dateObj.saved) : null;
        const timeValue =
          dateObj.saved !== null ? extractTime(dateObj.saved) : null;

        const dateKey = key;
        newDateInfoData[dateKey] = dateValue;

        const timeKey = key.replace("Date", "Time");
        newDateInfoData[timeKey] = timeValue;
      } else if (isTimeZoneField) {
        newDateInfoData[key] = dateObj.value || null;
      }
    });

    setDateInfoData(newDateInfoData);
  }, [dateInfo]);

  const [userHasSelectedTimeZone, setUserHasSelectedTimeZone] = useState(false);

  useEffect(() => {
    if (!userHasSelectedTimeZone && dateInfo?.dates) {
      const timezoneInfo =
        dateInfo?.dates[
          Object.keys(dateInfo?.dates ?? {}).find((key) =>
            key.endsWith("TimeZone")
          )
        ];
      const initialTimeZoneValue = timezoneInfo?.saved || timezoneInfo?.value;

      if (initialTimeZoneValue) {
        const initialTimeZoneLabel = getTimeZoneAbbr(initialTimeZoneValue);
        setSelectedTimeZone(initialTimeZoneLabel);
      }
    }
  }, [dateInfo, userHasSelectedTimeZone]);

  const handleDateTimeChange = (key, value, isTime = false) => {
    setDateInfoData((prevData) => {
      let newData = { ...prevData };

      if (isTime) {
        //Update the time, keep the date intact
        const dateKey = key.replace("Time", "Date");
        const existingDate = prevData[dateKey];

        if (existingDate) {
          // Ensure the existing date is correctly formatted
          const formattedDate = moment(existingDate, "MM/DD/YYYY").isValid()
            ? moment(existingDate, "MM/DD/YYYY").format("YYYY-MM-DD")
            : null;

          if (!formattedDate) {
            console.error("Invalid date format, skipping time update.");
            return prevData;
          }

          // Combine date and time, treat selected time as UTC (no timezone shift)
          const combinedDateTime = moment
            .utc(`${formattedDate} ${value}`, "YYYY-MM-DD HH:mm")
            .toISOString();

          onDateTimeChange(key, combinedDateTime, isTime);

          // Only update the time in state, keep the date intact
          newData[`${dateKey}Time`] = value;
        } else {
          console.error("Cannot update time without a valid selected date.");
          return prevData;
        }
      } else {
        const timeKey = key + "Time";
        const existingTime = prevData[timeKey] || "00:00";

        // Ensure the selected date is valid and correct input format ("MM/DD/YYYY")
        const formattedDate = moment(value, "MM/DD/YYYY").isValid()
          ? moment(value, "MM/DD/YYYY").format("YYYY-MM-DD")
          : null;

        if (!formattedDate) {
          console.error("Invalid date format, cannot update.");
          return prevData;
        }

        // Combine the new date with the time (or default "00:00")
        const combinedDateTime = moment
          .utc(`${formattedDate} ${existingTime}`, "YYYY-MM-DD HH:mm")
          .toISOString();

        onDateTimeChange(key, combinedDateTime, isTime);

        newData[key] = value;
        newData[timeKey] = existingTime;
      }

      return newData;
    });
  };

  const handleTimeZoneChange = (fieldKey, selectedOption) => {
    setUserHasSelectedTimeZone(true);

    setTimeZoneData((prevData) => ({
      ...prevData,
      [fieldKey]: selectedOption,
    }));

    if (typeof onDateTimeChange === "function") {
      onDateTimeChange(fieldKey, selectedOption, false, true);
    }
  };

  const renderPickerField = (fieldKey, label) => {
    const datePermission =
      pageMode === "view" ? "read" : dateInfo?.dates[fieldKey]?.permission;

    // Extract saved values or use current state
    const dateTimeValue =
      dateInfo?.dates[fieldKey]?.saved ||
      dateInfo?.dates[fieldKey]?.value ||
      dateInfoData[fieldKey] ||
      "";

    const dateKey = `${fieldKey}-date-${dateInfoData[fieldKey] || "null"}`;
    const timeKey = `${fieldKey}-time-${
      dateInfoData[fieldKey.replace("Date", "Time")] || "null"
    }`;

    if (datePermission === "read") {
      const displayValue = dateTimeValue
        ? extractDateTime(dateTimeValue)
        : "N/A";

      return <span className={styles.dateText}>{displayValue}</span>;
    }

    // Render for "write" permission
    if (datePermission === "write") {
      return (
        <div className={styles.labelAndPickerContainer}>
          <>
            <DatePicker
              key={dateKey}
              dateString={
                dateInfoData[fieldKey]
                  ? dayjs(dateInfoData[fieldKey]).local().format("MM/DD/YYYY")
                  : extractDate(dateTimeValue)
              }
              setDate={(date) => handleDateTimeChange(fieldKey, date, false)}
            />
            <TimePicker
              key={timeKey}
              timeString={extractTime(dateTimeValue)}
              onChange={(time) => {
                handleDateTimeChange(fieldKey, time, true);
              }}
            />
          </>
        </div>
      );
    }

    if (datePermission === "hidden") {
      return null;
    }
  };

  const renderTimeZoneSelect = (fieldKey, label) => {
    const timezoneKey = `${fieldKey}TimeZone`;
    // const timezonePermission =
    //   pageMode === "view" ? "read" : dateInfo?.dates[timezoneKey]?.permission;

    const timezonePermission = "view"
      ? "read"
      : dateInfo?.dates[timezoneKey]?.permission;

    const dateKey = fieldKey + "Date";

    // Determine if the date exists don't show timezone
    // if (pageMode === "view") {
    const dateObj = dateInfo.dates[dateKey];
    const dateExists =
      dateObj && (dateObj.saved !== null || dateObj.value !== null);

    if (!dateExists) {
      return null;
    }
    // }

    const timezoneValue = timeZoneData[timezoneKey] || "Pacific Time (PT)";

    // Render for "read" permission
    if (timezonePermission === "read") {
      return (
        <div className={styles.timezoneText}>
          {getTimeZoneAbbr(dateInfo.dates[timezoneKey]?.value)}
        </div>
      );
    }

    // Render for "write" permission
    if (timezonePermission === "write") {
      return (
        <div className={styles.timezone}>
          <Select
            options={timeZones}
            value={timezoneValue}
            placeholder={timezoneValue}
            onChange={(selectedOption) =>
              handleTimeZoneChange(timezoneKey, selectedOption, false)
            }
            disable={false}
            className={styles.timezone}
          />
        </div>
      );
    }

    if (timezonePermission === "hidden") {
      return null;
    }
  };

  const renderDateTimeSet = (
    dateKey1,
    timeKey1,
    label1,
    dateKey2,
    timeKey2,
    label2
  ) => {
    //Function to split the label
    const splitLabel = (label) => {
      if (label.includes("Starts On")) {
        return [label.replace(" Starts On", ""), "Starts On:"];
      } else if (label.includes("Ends On")) {
        return [label.replace(" Ends On", ""), "Ends On:"];
      }
      return [label];
    };

    const [mainLabel1, suffixLabel1] = splitLabel(label1);
    const [mainLabel2, suffixLabel2] = label2 ? splitLabel(label2) : [];

    return (
      <div className={styles.dateTimeSet}>
        <div className={styles.rowContainer}>
          <div className={styles.labelContainer}>
            <label>
              {/* {label1} */}
              {mainLabel1}
              {pageMode === "edit"}
            </label>
          </div>

          {suffixLabel1 && (
            <div className={styles.suffixLabelContainer}>
              <label>{suffixLabel1}</label>
            </div>
          )}

          <div className={styles.labelAndPickerContainer}>
            <div className={styles.datePickerContainer}>
              {renderPickerField(dateKey1, label1)}
            </div>

            {pageMode === "edit" && (
              <div className={styles.timePickerContainer}>
                {renderPickerField(timeKey1, label1)}
              </div>
            )}

            {dateInfo.dates && (
              <div className={styles.timezoneEdit}>
                {renderTimeZoneSelect(
                  dateKey1.replace("Date", ""),
                  `${label1} Timezone`
                )}
              </div>
            )}
          </div>
        </div>

        {/* Input Fields with Ends on */}
        {dateKey2 && timeKey2 && label2 && (
          <div className={styles.rowContainer}>
            <div className={styles.labelContainer}>
              <label className={styles.hiddenLabel}>
                {/* {label2} */}
                <label>{mainLabel2}</label>
                {pageMode === "edit"}
              </label>
            </div>

            {suffixLabel2 && (
              <div className={styles.suffixLabelContainer}>
                <label>{suffixLabel2}</label>
              </div>
            )}

            <div className={styles.labelAndPickerContainer}>
              <div className={styles.datePickerContainer}>
                {renderPickerField(dateKey2, label2)}
              </div>

              {pageMode === "edit" && (
                <div className={styles.timePickerContainer}>
                  {renderPickerField(timeKey2, label2)}
                </div>
              )}

              <div className={styles.timezoneEdit}>
                {renderTimeZoneSelect(
                  dateKey2.replace("Date", ""),
                  `${label2} Timezone`
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <div className={styles.cardHeader}>DATES</div>
      </div>
      <hr className={styles.hr} />

      {/* Major ruleset with onsite location */}
      <div>
        {ruleset === "Major v1.0" &&
          location === "onsite" &&
          dateTimeSets.major.onsite &&
          dateTimeSets.major.onsite.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* Major ruleset with virtual location */}
      <div>
        {ruleset === "Major v1.0" &&
          location === "virtual" &&
          dateTimeSets.major.virtual &&
          dateTimeSets.major.virtual.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* Local ruleset with "Regional" event name and onsite location */}
      <div>
        {ruleset === "Local v1.0" &&
          eventName === "Regional" &&
          location === "onsite" &&
          dateTimeSets.local.onsite.regional &&
          dateTimeSets.local.onsite.regional.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* Local ruleset with "Regional" event name and virtual location */}
      <div>
        {ruleset === "Local v1.0" &&
          eventName === "Regional" &&
          location === "virtual" &&
          dateTimeSets.local.virtual.regional &&
          dateTimeSets.local.virtual.regional.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* Local ruleset with "SLM" event name and onsite location */}
      <div>
        {ruleset === "Local v1.0" &&
          eventName === "Second Look Meeting" &&
          location === "onsite" &&
          dateTimeSets.local.onsite.secondLookMeeting &&
          dateTimeSets.local.onsite.secondLookMeeting.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* Local ruleset with "SLM" event name and virtual location */}
      <div>
        {ruleset === "Local v1.0" &&
          eventName === "Second Look Meeting" &&
          location === "virtual" &&
          dateTimeSets.local.virtual.secondLookMeeting &&
          dateTimeSets.local.virtual.secondLookMeeting.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* LOS ruleset with onsite location */}
      <div>
        {ruleset === "LOS v1.0" &&
          location === "onsite" &&
          dateTimeSets.los.onsite &&
          dateTimeSets.los.onsite.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>

      {/* LOS ruleset with virtual location */}
      <div>
        {ruleset === "LOS v1.0" &&
          location === "virtual" &&
          dateTimeSets.los.virtual &&
          dateTimeSets.los.virtual.map(
            ([dateKey1, timeKey1, label1, dateKey2, timeKey2, label2]) => (
              <div key={`${dateKey1}-${timeKey1}-${dateKey2}-${timeKey2}`}>
                {renderDateTimeSet(
                  dateKey1,
                  timeKey1,
                  label1,
                  dateKey2,
                  timeKey2,
                  label2
                )}
              </div>
            )
          )}
      </div>
    </Card>
  );
}

DateV1Form.propTypes = {
  ruleset: PropTypes.string,
  location: PropTypes.string,
  eventName: PropTypes.string,
};

const dateTimeSets = {
  major: {
    onsite: [
      [
        "purchaseStartDate",
        "purchaseStartTime",
        "Purchase Starts On",

        "purchaseEndDate",
        "purchaseEndTime",
        "Ends On",
      ],
      [
        "eventStartDate",
        "eventStartTime",
        "Events Starts On",

        "eventEndDate",
        "eventEndTime",
        "Event Ends On",
      ],
      [
        "changeRequestStartDate",
        "changeRequestStartTime",
        "Change Requests Starts On",

        "changeRequestEndDate",
        "changeRequestEndTime",
        "Change Request Ends On",
      ],
      [
        "eagleStartDate",
        "eagleStartTime",
        "Eagle Starts On",

        "eagleEndDate",
        "eagleEndTime",
        "Eagle Ends On",
      ],
      [
        "checkInStartDate",
        "checkInStartTime",
        "Check In Starts On",

        "checkInEndDate",
        "checkInEndTime",
        "Check In Ends On",
      ],
      ["printReleaseDate", "printReleaseTime", "Ticket Print Starts On"],
    ],
    virtual: [
      [
        "purchaseStartDate",
        "purchaseStartTime",
        "Purchase Starts On",

        "purchaseEndDate",
        "purchaseEndTime",
        "Purchase Ends On",
      ],
      ["eventStartDate", "eventStartTime", "Events Starts On"],
      [
        "changeRequestStartDate",
        "changeRequestStartTime",
        "Change Request Starts On",

        "changeRequestEndDate",
        "changeRequestEndTime",
        "Change Request Ends On",
      ],
    ],
  },

  //LOCAL FUNCTION
  local: {
    onsite: {
      regional: [
        [
          "purchaseStartDate",
          "purchaseStartTime",
          "Purchase Starts On",

          "purchaseEndDate",
          "purchaseEndTime",
          "Purchase Ends On",
        ],

        ["eventStartDate", "eventStartTime", "Events Starts On"],
        [
          "seminarDoorsStartDate",
          "seminarDoorsStartTime",
          "Seminar Doors Starts On",
        ],
        [
          "seminarSessionStartDate",
          "seminarSessionStartTime",
          "Seminar Session Starts On",

          "seminarSessionEndDate",
          "seminarSessionEndTime",
          "Seminar Session Ends On",
        ],

        ["slmDoorsStartDate", "slmDoorsStartTime", "SLM Doors Starts On"],
        [
          "slmSessionStartDate",
          "slmSessionStartTime",
          "SLM Session Starts On",

          "slmSessionEndDate",
          "slmSessionEndTime",
          "SLM Session Ends On",
        ],
        [
          "changeRequestEndDate",
          "changeRequestEndTime",
          "Change Request Ends On",
        ],
      ],

      secondLookMeeting: [
        [
          "purchaseStartDate",
          "purchaseStartTime",
          "Purchase Starts On",

          "purchaseEndDate",
          "purchaseEndTime",
          "Purchase Ends On",
        ],

        ["eventStartDate", "eventStartTime", "Events Starts On"],

        ["slmDoorsStartDate", "slmDoorsStartTime", "SLM Doors Starts On"],
        [
          "slmSessionStartDate",
          "slmSessionStartTime",
          "SLM Session Starts On",

          "slmSessionEndDate",
          "slmSessionEndTime",
          "SLM Session Ends On",
        ],
        [
          "changeRequestEndDate",
          "changeRequestEndTime",
          "Change Request Ends On",
        ],
      ],
    },
    virtual: {
      regional: [
        [
          "purchaseStartDate",
          "purchaseStartTime",
          "Purchase Starts On",

          "purchaseEndDate",
          "purchaseEndTime",
          "Purchase Ends On",
        ],

        ["eventStartDate", "eventStartTime", "Events Starts On"],
        [
          "seminarDoorsStartDate",
          "seminarDoorsStartTime",
          "Seminar Doors Starts On",
        ],
        [
          "seminarSessionStartDate",
          "seminarSessionStartTime",
          "Seminar Session Starts On",

          "seminarSessionEndDate",
          "seminarSessionEndTime",
          "Seminar Session Ends On",
        ],
        ["slmDoorsStartDate", "slmDoorsStartTime", "SLM Doors Starts On"],
        [
          "slmSessionStartDate",
          "slmSessionStartTime",
          "SLM Session Starts On",

          "slmSessionEndDate",
          "slmSessionEndTime",
          "SLM Session Ends On",
        ],

        [
          "changeRequestEndDate",
          "changeRequestEndTime",
          "Change Request Ends On",
        ],
      ],
      secondLookMeeting: [
        [
          "purchaseStartDate",
          "purchaseStartTime",
          "Purchase Starts On",

          "purchaseEndDate",
          "purchaseEndTime",
          "Purchase Ends On",
        ],

        ["eventStartDate", "eventStartTime", "Events Starts On"],

        [
          "changeRequestEndDate",
          "changeRequestEndTime",
          "Change Request Ends On",
        ],
      ],
    },
  },

  los: {
    onsite: [
      [
        "purchaseStartDate",
        "purchaseStartTime",
        "Purchase Starts On",

        "purchaseEndDate",
        "purchaseEndTime",
        "Purchase Ends On",
      ],
      [
        "eventStartDate",
        "eventStartTime",
        "Events Starts On",

        "eventEndDate",
        "eventEndTime",
        "Event Ends On",
      ],
      [
        "changeRequestStartDate",
        "changeRequestStartTime",
        "Change Requests Starts On",

        "changeRequestEndDate",
        "changeRequestEndTime",
        "Change Request Ends On",
      ],
      [
        "eagleStartDate",
        "eagleStartTime",
        "Eagle Starts On",

        "eagleEndDate",
        "eagleEndTime",
        "Eagle Ends On",
      ],
      [
        "checkInStartDate",
        "checkInStartTime",
        "Check In Starts On",

        "checkInEndDate",
        "checkInEndTime",
        "Check In Ends On",
      ],
      ["printReleaseDate", "printReleaseTime", "Ticket Print Starts On"],
    ],
    virtual: [
      [
        "purchaseStartDate",
        "purchaseStartTime",
        "Purchase Starts On",

        "purchaseEndDate",
        "purchaseEndTime",
        "Purchase Ends On",
      ],
      ["eventStartDate", "eventStartTime", "Events Starts On"],
      [
        "changeRequestStartDate",
        "changeRequestStartTime",
        "Change Request Starts On",

        "changeRequestEndDate",
        "changeRequestEndTime",
        "Change Request Ends On",
      ],
    ],
  },
};
