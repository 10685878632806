import React from "react";
import PropTypes from "prop-types";
import styles from "./AlertModal.module.css";
import Button from "shared/Button";

/**
 * Alert Modal
 */
export function AlertModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <p className={styles.alert}>
          Please complete the display name before navigating back.
        </p>

        <div className={styles.alertCloseBtn}>
          <Button
            className={styles.closeBtn}
            children={"Close"}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
