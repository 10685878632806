// import styles from "./index.module.css";
import React from "react";
import styles from "./index.module.css";

export default class SectionErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Unhandled error caught", {
      error,
      errorInfo,
    });
    return true;
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.container}>
          <h1>An Error Has Occurred</h1>
          <hr />
          <h2 className={styles.messageLabel}>ERROR MESSAGE</h2>
          <p className={styles.message}>{this.state.error.message}</p>
          <p className={styles.footerText}>
            Full error details have been logged to the browser console.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
