import styles from "./EventOptions.module.css";
import PropTypes from "prop-types";
import { ChevronDown } from "react-ikonate";
import { useState, useEffect, useRef } from "react";

/**
 * A component that displays Event Options Dropdown Button
 */

export function EventOptions({
  onView,
  onEdit,
  onClone,
  onArchive,
  isArchived,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // watches mouse position; close menu if clicked outside of wrapper
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // const handleArchive = () => {
  //   onArchive(id);
  // };

  return (
    <>
      <div ref={wrapperRef} className={styles.container}>
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className={
            !dropdownOpen ? styles["button-closed"] : styles["button-open"]
          }
        >
          Options
          <ChevronDown
            fontSize="1.4em"
            className={!dropdownOpen ? styles.chevron : styles.hidden}
          />
        </button>

        {dropdownOpen && (
          <div className={styles.dropdown}>
            <div className={styles.items} onClick={onView}>
              View
            </div>
            {!isArchived && (
              <>
                <div className={styles.items} onClick={onEdit}>
                  Edit
                </div>
                <hr />
                <div className={styles.items} onClick={onClone}>
                  Clone
                </div>
                <div className={styles.items} onClick={onArchive}>
                  Archive
                </div>
              </>
            )}
            {isArchived && (
              <div className={styles.items} onClick={onClone}>
                Clone
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

EventOptions.propTypes = {
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  onArchive: PropTypes.func,
  isArchived: PropTypes.bool,
};
