import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import InputItem from "Events/Components/InputItem";
import TextArea from "shared/TextArea";
import styles from "./EventV2InfoForm.module.css";
import classNames from "classnames";
import { Select } from "shared/Select/Select";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

/**
 * Interactive component. Form to fill out information about an event.
 */

export function EventV2InfoForm({
  marketingInfo,
  onBlur,
  pageMode,
  onValidationChange,
  triggerValidation,
}) {
  const { data: states, isLoading } = useCurrentRoute();
  const initialEvent = {
    country: { value: "", saved: "" },
    city: { value: "", saved: "" },
    state: { value: "", saved: "" },
  };

  const initialMarketing = {
    title: { value: "", saved: "" },
    description: { value: "", saved: "" },
  };

  const [eventData, setEventData] = useState({
    ...initialEvent,
    ...(marketingInfo?.event && marketingInfo.event),
  });

  const [marketingData, setMarketingData] = useState({
    ...initialMarketing,
    ...(marketingInfo?.marketing && marketingInfo.marketing),
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (marketingInfo?.event !== eventData) {
      setEventData(marketingInfo?.event);
    }
    if (marketingInfo?.marketing !== marketingData) {
      setMarketingData(marketingInfo?.marketing);
    }
  }, [marketingInfo?.event, marketingInfo?.marketing]);

  const handleInputChange = (dataType, key, newValue) => {
    if (dataType === "event") {
      setEventData((prevData) => ({
        ...prevData,
        [key]: {
          ...prevData[key],
          saved: newValue,
        },
      }));
    } else if (dataType === "marketing") {
      setMarketingData((prevData) => ({
        ...prevData,
        [key]: {
          ...prevData[key],
          saved: newValue,
        },
      }));
    }
  };

  useEffect(() => {
    if (triggerValidation) {
      // const isValid = validateFields();
      // onValidationChange(isValid);
      validateFields();
    }
  }, [triggerValidation]);

  const handleBlur = async (dataType, key, value, event) => {
    event?.preventDefault();

    if (!isInitialLoad) {
      const success = await onBlur(dataType, key, value);
      if (success) {
        const fieldValidationResult = validateFields(dataType, key, value);

        if (fieldValidationResult.isValid) {
          setValidationErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[key];
            return newErrors;
          });
        }
        validateFields();
      }
    }
  };

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "ANZ",
    221: "JAMAICA",
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("Select");
  const [stateOptions, setStateOptions] = useState([]);
  const [isCountryChange, setIsCountryChange] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  //Find existing country.saved or default to "US"
  useEffect(() => {
    const defaultCountryId = marketingInfo?.event?.country?.saved;
    const defaultCountryName = countryIdName[defaultCountryId];
    setSelectedCountry(defaultCountryName || "U.S.");
  }, [marketingInfo]);

  //Filter states based on country selected
  useEffect(() => {
    if (states) {
      const countryId = Object.keys(countryIdName).find(
        (key) => countryIdName[key] === selectedCountry
      );
      const filteredStates = states
        .filter((state) => state.countryId.toString() === countryId)
        .filter((state) => state.state !== "N/A")
        .map((state) => `${state.abbreviation} - ${state.state}`);

      setStateOptions(filteredStates);
    }
  }, [states, selectedCountry]);

  //Find existing state.saved else show "Select"
  useEffect(() => {
    const loadInitialState = () => {
      const stateSavedId =
        Number(marketingInfo?.event?.state.saved) ||
        Number(marketingInfo?.event?.state.value);
      if (stateSavedId > 0) {
        const existingState = states?.find(
          (state) => state.stateId === stateSavedId
        );
        if (
          existingState &&
          existingState.countryId.toString() ===
            Object.keys(countryIdName).find(
              (key) => countryIdName[key] === selectedCountry
            )
        ) {
          setSelectedState(
            `${existingState.abbreviation} - ${existingState.state}`
          );
        } else {
          setSelectedState("");
        }
      } else {
        setSelectedState("");
      }
    };

    if (!isInitialLoad) {
      loadInitialState();
    }
  }, [marketingInfo, states, selectedCountry, isInitialLoad]);

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        if (pageMode === "edit") {
          return "Province *";
        } else {
          return "Province";
        }
      case "JAMAICA":
        if (pageMode === "edit") {
          return "Parish *";
        } else {
          return "Parish";
        }
      default:
        if (pageMode === "edit") {
          return "State *";
        } else {
          return "State";
        }
    }
  };

  const handleCountrySelection = (selectedValue) => {
    if (selectedValue !== selectedCountry) {
      setSelectedCountry(selectedValue);
      setSelectedState("");
      setIsCountryChange(true);

      setEventData((prevData) => ({
        ...prevData,
        // city: { ...prevData.city, saved: "", value: "" },
        state: { ...prevData.state, saved: "", value: "" },
      }));

      const countryId = Object.keys(countryIdName).find(
        (key) => countryIdName[key] === selectedValue
      );
      if (countryId) {
        handleBlur("event", "country", countryId);
      }
    }
  };

  //Rendering based on permission
  const renderField = (field, label, elementType, dataType, key) => {
    const fieldValue = typeof field === "object" ? field?.value : field;
    const permission = pageMode === "view" ? "read" : field?.permission;

    const hasError = validationErrors[key];

    // Handle read-only and hidden logic
    if (permission === "hidden") {
      return null;
    }

    if (permission === "read" || pageMode === "view") {
      return (
        <div className={styles.label}>
          <span className={styles.label}>{label}</span>
          <span className={styles.text}> {fieldValue || "N/A"}</span>
        </div>
      );
    } else if (
      (permission === "write" || pageMode === "edit") &&
      label === "City"
    ) {
      return (
        <InputItem
          label={label + (pageMode === "edit" ? " *" : "")}
          value={field?.saved || field?.value || ""}
          placeholder="Click to type"
          onChange={(e) => handleInputChange(dataType, key, e.target.value)}
          onBlur={() => handleBlur(dataType, key, field?.saved)}
          className={hasError ? styles.warningBorder : ""}
        />
      );
    }

    // Handle editable fields (write permission)
    switch (elementType) {
      case "input":
        return (
          <InputItem
            label={label}
            value={field?.saved || field?.value || ""}
            placeholder="Click to type (IE: Las Vegas Spring Leadership)"
            onChange={(e) => handleInputChange(dataType, key, e.target.value)}
            onBlur={() => handleBlur(dataType, key, field?.saved)}
            className={hasError ? styles.warningBorder : ""}
          />
        );

      case "textarea":
        return (
          <TextArea
            label={label}
            value={field?.saved || field?.value || ""}
            placeholder="Click to type"
            onChange={(e) =>
              handleInputChange("marketing", "description", e.target.value)
            }
            onBlur={() => handleBlur("marketing", key, field?.saved)}
          />
        );
    }
  };

  const renderStateSelection = () => {
    const permission =
      pageMode === "view" ? "read" : eventData?.state?.permission;
    const stateValue =
      eventData?.state?.value === 0 || eventData?.state === 0
        ? "N/A"
        : eventData?.state?.value || eventData?.state?.saved;

    const hasStateError = validationErrors["state"];

    //Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = states?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    if (permission === "hidden") {
      return null;
    }

    if (permission === "read" || pageMode === "view") {
      return (
        <span className={styles.stateValue}>
          {getStateNameById(stateValue)}
        </span>
      );
    }

    if (permission === "write") {
      return (
        <Select
          label={
            getLabelForCountry(selectedCountry) + pageMode === "edit" && "*"
          }
          placeholder={selectedState ? selectedState : "Select"}
          value={selectedState}
          options={stateOptions}
          onChange={(value) => {
            const extractState = value.split(" - ")[1];
            setSelectedState(value);

            const foundState = states?.find(
              (state) => state.state === extractState
            );

            if (foundState) {
              const stateId = String(foundState.stateId);

              handleInputChange("event", "state", stateId);
              handleBlur("event", "state", stateId);
            }
          }}
          disabled={isLoading && !selectedCountry}
          className={hasStateError ? styles.warningBorder : ""}
        />
      );
    }
  };

  const renderCountryField = () => {
    const countryPermission =
      pageMode === "view" ? "read" : marketingInfo?.event?.country?.permission;
    const countryValue = marketingInfo?.event?.country?.value;

    if (countryPermission === "write" || countryPermission === undefined) {
      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelection}
            options={["U.S.", "CDN", "ANZ", "JAMAICA"]}
            defaultValue={selectedCountry}
            groupName="eventCountry"
          />
        </span>
      );
    } else if (countryPermission === "read") {
      const countryName = countryIdName[countryValue];
      return <span className={styles.textValue}>{countryName || "N/A"}</span>;
    } else if (countryPermission === "hidden") {
      return null;
    }
  };

  // Function to validate all fields
  const validateFields = () => {
    let errors = {};
    let isValid = true;

    const validateField = (fieldData, fieldName) => {
      // Check if the field is required
      if (fieldData.required && fieldData.saved === null) {
        errors[fieldName] = `This field cannot be empty`;
        isValid = false;
      }
    };

    // Validate marketing data fields
    for (const key in marketingData) {
      if (marketingData.hasOwnProperty(key)) {
        validateField(marketingData[key], key);
      }
    }

    // Validate event data fields
    // for (const key in eventData) {
    //   if (eventData.hasOwnProperty(key)) {
    //     validateField(eventData[key], key);
    //   }
    // }

    // Temporary validate event data fields,  skip 'city' and 'state'
    for (const key in eventData) {
      if (eventData.hasOwnProperty(key) && key !== "city" && key !== "state") {
        validateField(eventData[key], key);
      }
    }

    setValidationErrors(errors);

    return isValid;
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.headerContainer}>
            <div className={classNames(styles.title)}>EVENT INFORMATION</div>
            {/* <div className={styles.errorMessage}>
              {Object.keys(validationErrors).length > 0 ? (
                <p>(Please fill out required fields before navigating away.)</p>
              ) : null}
            </div> */}
          </div>
          <hr className={styles.hr} />
          <div className={classNames(styles["form-container"])}>
            <div
              className={classNames(
                styles["input-container"],
                styles.inputItem
              )}
            >
              {renderField(
                marketingData?.title,
                "Display Name",
                "input",
                "marketing",
                "title"
              )}
            </div>
            <div
              className={classNames(
                styles["input-container"],
                styles["event-description"]
              )}
            >
              {renderField(
                marketingData.description,
                "Event Description",
                "textarea",
                "marketing",
                "description"
              )}
            </div>

            <div className={styles.radioContainer}>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country</span>

                {renderCountryField()}
              </div>
            </div>
            <div
              className={classNames(
                styles["input-container"],
                styles.inputItem
              )}
            >
              {renderField(eventData?.city, "City", "input", "event", "city")}
            </div>

            <div className={styles.row}>
              <div className={styles.stateCol}>
                <span className={styles.label}>
                  {getLabelForCountry(selectedCountry)}
                </span>
                <span
                  className={classNames(
                    !selectedCountry ? styles.disable : styles.select
                  )}
                >
                  {renderStateSelection()}
                </span>
              </div>
            </div>
          </div>
        </section>
      }
    />
  );
}

EventV2InfoForm.propTypes = {
  /**
   * Value of display name input box.
   */
  title: PropTypes.string,
  /**
   * Function to set the display name when changed.
   */
  settitle: PropTypes.func,
  /**
   * Value of job code input box.
   */
  jobCode: PropTypes.string,
  /**
   * Function to set the job code when changed.
   */
  setJobCode: PropTypes.func,
  /**
   * Value of description input box.
   */
  description: PropTypes.string,
  /**
   * Function to set the description when changed.
   */
  setDescription: PropTypes.func,
  /**
   * Value of location input box.
   */
  location: PropTypes.string,
  /**
   * Function to set the location when changed.
   */
  setLocation: PropTypes.func,
  /**
   * Value of start date input box.
   */
  startDate: PropTypes.string,
  /**
   * Function to set the start date when changed.
   */
  setStartDate: PropTypes.func,
  /**
   * Value of end date input box.
   */
  endDate: PropTypes.string,
  /**
   * Function to set the end date when changed.
   */
  setEndDate: PropTypes.func,
  /**
   * Value of start time input box.
   */
  startTime: PropTypes.string,
  /**
   * Function to set the start time when changed.
   */
  setStartTime: PropTypes.func,
  /**
   * Value of end time input box.
   */
  endTime: PropTypes.string,
  /**
   * Function to set the end time when changed.
   */
  setEndTime: PropTypes.func,
  /**
   * Value of doors open time input box.
   */
  doorsOpen: PropTypes.string,
  /**
   * Function to set the doors open time when changed.
   */
  setDoorsOpen: PropTypes.func,
};
