import { useEffect, useLayoutEffect, useRef, useState } from "react";
import mediaTyper from "media-typer";
import contentType from "content-type";
import { Auth } from "./Auth";

/**
 * A hook for making abortable, authenticated fetch requests.
 *
 * @param {string} url - The path or URL to request.
 * @param {Object} fetchOptions - An options object forwarded to `fetch`
 * @param {Object} cache - (optional) A cache reference from RequestCache.useContainer()
 */
export function useAuthedRequest(url, fetchOptions, cache = null) {
  const { makeAuthedRequest } = Auth.useContainer();

  const [state, setState] = useState({
    url: null,
    data: null,
    loading: false,
    error: null,
    controller: null,
  });

  // Cache data when it changes.
  useEffect(() => {
    if (cache && state.data && state.url === url) {
      cache.__cache.current[url] = state.data;
    }
  }, [url, cache, state]);

  const isMounted = useRef(false);

  useLayoutEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Abort the previous request before starting fresh.
    state.controller && state.controller.abort();

    const controller = new AbortController();

    if (url) {
      // Try to grab from cache first.
      if (cache && cache.__cache.current[url]) {
        setState({
          data: cache.__cache.current[url],
          loading: false,
          error: null,
          controller: null,
        });
      } else {
        fetchData(url, fetchOptions, controller, makeAuthedRequest, (state) => {
          if (isMounted.current) {
            setState(state);
          }
        });
      }
    }
  }, [fetchOptions, url]);

  return {
    data: state.data,
    loading: !!state.loading,
    error: state.error,
    abort: () => state.controller && state.controller.abort(),
  };
}

async function fetchData(
  url,
  fetchOptions = {},
  controller,
  makeAuthedRequest,
  setState
) {
  const options = { ...fetchOptions, signal: controller.signal };

  let res = null;
  try {
    setState((oldState) => ({
      url: url,
      data: null,
      loading: oldState.loading + 1,
      error: null,
      controller,
    }));

    res = await makeAuthedRequest(url, options);
    const contentTypeHeader = res.headers.get("content-type");

    let data = null;

    if (contentTypeHeader) {
      if (isJSON(contentTypeHeader)) {
        data = await res.json();
      } else {
        data = await res.text();
      }

      if (res.ok) {
        setState((oldState) => ({
          ...oldState,
          data,
          loading: oldState.loading - 1,
        }));
      } else {
        const error = new Error(data.message || data.responseText);

        setState((oldState) => ({
          ...oldState,
          data: null,
          error,
          loading: oldState.loading - 1,
        }));
      }
    } else {
      if (res.ok) {
        setState((oldState) => ({
          ...oldState,
          loading: oldState.loading - 1,
        }));
      } else {
        const error = new Error(data.responseText);

        setState((oldState) => ({
          ...oldState,
          data: null,
          error,
          loading: oldState.loading - 1,
        }));
      }
    }
  } catch (err) {
    const error = err.name !== "AbortError" ? err : null;

    setState((oldState) => ({
      ...oldState,
      error,
      // Only decrease the loading counter if there is no response
      loading: res ? oldState.loading : oldState.loading - 1,
    }));
  }
}

function isJSON(contentTypeHeader) {
  if (contentTypeHeader) {
    const ct = contentType.parse(contentTypeHeader);
    const mediaType = mediaTyper.parse(ct.type);

    if (mediaType.subtype === "json") {
      return true;
    }

    if (mediaType.suffix === "json") {
      return true;
    }

    if (mediaType.suffix && /\bjson\b/i.test(mediaType.suffix)) {
      return true;
    }

    if (mediaType.subtype && /\bjson\b/i.test(mediaType.subtype)) {
      return true;
    }
  }
  return false;
}
