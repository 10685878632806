import { useEffect, useState } from "react";
import { useAuthedRequest } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";

export function useIBOSearch(term = "", limit = 50) {
  const { logError } = useLogger({ prefix: "useIBOSearch" });
  const [searchResults, setSearchResults] = useState([]);

  const shouldFetchData = term.trim().length >= 3;
  const url = shouldFetchData
    ? `/v1/membership/search?term=${encodeURIComponent(term)}&limit=${limit}`
    : null;

  const { data, loading, error } = useAuthedRequest(url);

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data != null) {
      setSearchResults(data);
    }
  }, [data]);

  return {
    searchResults,
    isLoading: loading,
  };
}
