import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./VenueInfoCard.module.css";
import InputItem from "Events/Components/InputItem";

/**
 *
 * Displays the Venues Information
 *
 */

export function VenueInfoCard({ venueInfo, pageMode }) {
  const [venueData, setVenueData] = useState({
    company: "",
    street1: "",
    street2: "",
    stateAbbr: "",
    city: "",
    zip: "",
    url: "",
    phone: "",
    fax: "",
    email: "",
    maxCapacity: "",
    room: "",
  });

  useEffect(() => {
    const { company, room } = venueInfo;

    const newVenueInfo = {
      company: company.saved?.company || company.value?.company || "",
      street1: company.saved?.street1 || company.value?.street1 || "",
      street2: company.saved?.street2 || company.value?.street2 || "",
      city: company.saved?.city || company.value?.city || "",
      stateAbbr: company.saved?.stateAbbr || company.value?.stateAbbr || "",
      zip: company.saved?.zip || company.value?.zip || "",
      url: company.saved?.url || company.value?.url || "N/A",
      phone: company.saved?.phone || company.value?.phone || "N/A",
      fax: company.saved?.fax || company.value?.fax || "N/A",
      email: company.saved?.email || company.value?.email || "N/A",
      maxCapacity: room.saved?.maxCapacity || room.value?.maxCapacity || "N/A",
      room: room.saved?.room || room.value?.room || "N/A",
    };

    setVenueData(newVenueInfo);
  }, [venueInfo]);

  const formatPhoneNumber = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, "");

    if (digits.length === 10) {
      return `(${digits.substr(0, 3)})-${digits.substr(3, 3)}-${digits.substr(
        6
      )}`;
    }
    return phoneNumber;
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={classNames(styles.item, styles.left)}>Venue Name</div>
        <div className={styles.item}>{venueData.company}</div>

        <div className={classNames(styles.item, styles.left)}>Address</div>
        <div className={classNames(styles.item, styles.address)}>
          <span>
            {[venueData.street1, venueData.street2].filter(Boolean).join(", ")}
          </span>
          <span>
            {[
              venueData.city,
              venueData.stateAbbr && venueData.stateAbbr + " " + venueData.zip,
            ]
              .filter(Boolean)
              .join(", ")}
          </span>
        </div>

        <div className={classNames(styles.item, styles.left)}>Capacity</div>
        <div className={styles.item}>{venueData.maxCapacity}</div>

        <div className={classNames(styles.item, styles.left)}>Room</div>
        <div className={styles.item}>{venueData.room}</div>

        <div className={classNames(styles.item, styles.left)}>Venue URL</div>
        <div className={styles.item}>
          <a href={venueData.url} target="_blank" rel="noopener noreferrer">
            {venueData.url}
          </a>
        </div>

        <div className={classNames(styles.item, styles.left)}>Venue Phone</div>
        <div className={styles.item}>{formatPhoneNumber(venueData.phone)}</div>

        <div className={classNames(styles.item, styles.left)}>Venue Fax</div>
        <div className={styles.item}>{formatPhoneNumber(venueData.fax)}</div>

        <div className={classNames(styles.item, styles.left)}>Venue Email</div>
        <div className={styles.item}>{venueData.email}</div>
      </div>
    </div>
  );
}

VenueInfoCard.propTypes = {
  venueInfo: PropTypes.object,

  onEdit: PropTypes.func,
};

VenueInfoCard.defaultProps = {
  venueInfo: {
    name: "",
    address1: "",
    address2: "",
    rooms: [],
    capacity: "",
    venueUrl: "",
    contactName: "",
    phone: "",
    fax: "",
    email: "",
  },
};
