import styles from "./TimePicker.module.css";
import React, { useState, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Time } from "react-ikonate";
import { Modal } from "shared/Modal";
import TextInput from "shared/@forms/TextInput";
import { HoursList } from "./HoursList/HoursList";

export function TimePicker({ value, onChange, required, disabled }) {
  const label = useMemo(() => valueToLabel(value), [value]);

  const [editValue, setEditValue] = useState(label);
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    setEditValue(label);
  }, [label]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.inputGroup}>
        <TextInput
          value={editValue}
          disabled={disabled}
          onChange={(e) => {
            e.preventDefault();
          }}
          onInput={(e) => {
            setEditValue(e.target.value);

            const value = parseInputTime(e.target.value);

            if (value) {
              onChange(value);
            }
          }}
        />

        <button
          className={styles.toggleButton}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          <Time fontSize={18} />
        </button>
      </div>

      <Modal
        title="Choose Time"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className={styles.popOverMenu}>
          <section className={styles.section}>
            <header>
              <h3>Day</h3>
            </header>
            <HoursList
              value={value}
              startHour={6}
              onSelect={(value) => {
                console.log(value);
                onChange(value);
                setEditValue(valueToLabel(value));
                setIsOpen(false);
              }}
            />
          </section>
          <section className={`${styles.section} ${styles.night}`}>
            <header>
              <h3>Night</h3>
            </header>
            <HoursList
              value={value}
              startHour={18}
              onSelect={(value) => {
                console.log(value);
                onChange(value);
                setEditValue(valueToLabel(value));
                setIsOpen(false);
              }}
            />
          </section>
        </div>
      </Modal>
    </div>
  );
}

TimePicker.propTypes = {
  /**
   * An array of two numbers representing hours and minutes. Hour can be from 0-24 and minute from 0-59.
   */
  value: PropTypes.arrayOf(PropTypes.number).isRequired,

  /**
   * A callback to fire when the user changes the value. Returns the newly selected time in the same [h, m] format.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Set required=true on the HTML input if true. Helpful for use in forms.
   */
  required: PropTypes.bool,
};

/**
 * Extracts an `[hour, minute]` numerical value out of a user-provided time string.
 *
 * @param {string} time - A time string in the format 'HH:MM PM'
 *
 * @returns {[number, number]}
 */
function parseInputTime(time) {
  const matches = time
    .trim()
    .replace(/\./g, "")
    .match(/^(\d+):(\d{2})\s?([AP]M)$/i);

  if (matches) {
    const parts = matches.slice(1, 4);

    let hours = parseInt(parts[0]);
    let minutes = parseInt(parts[1]);

    if (parts[2].toUpperCase() === "PM") {
      if (hours === 12) {
        hours = 0;
      } else {
        hours = hours - 12;
      }

      if (hours < 0) {
        hours += 24;
      }
    }

    if (hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 59) {
      return [hours, minutes];
    }
  }

  return null;
}

/**
 * Converts an `[hour, minute]` value into a formatted string for users.
 *
 * @param {[number, number]} value
 *
 * @returns {string}
 */
function valueToLabel(value) {
  let h = value[0];
  let m = value[1];
  let part = "AM";

  if (h > 11) {
    part = "PM";
    h = h - 12;
  }

  if (h === 0) {
    h = 12;
  }

  return `${h}:${m.toString().padStart(2, "0")} ${part}`;
}
