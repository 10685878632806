import styles from "./index.module.css";
import React from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";
import classNames from "classnames";

/**
 * Just a styled link. Falls back to a non-Reacty link if linking
 * to another domain.
 */
export default function Link({ className, children, ...props }) {
  if (props.to && /^https?:\/\//.test(props.to)) {
    return (
      <a
        className={classNames(styles.link, className)}
        href={props.to}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink className={classNames(styles.link, className)} {...props}>
      {children}
    </ReactRouterLink>
  );
}

Link.propTypes = {
  /*
   * Children: Anything that can be rendered: numbers, strings, elements or an array
   *  (or fragment) containing these types.
   */
  children: PropTypes.node,
  className: PropTypes.string,
};
