import React, { useState, useCallback, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "shared/Button";
import EventTypeCard from "Events/V1Components/EventTypeCard";
import EventInfoForm from "Events/Components/EventInfoForm";
import PublishingInfoForm from "Events/Components/PublishingInfoForm";
import VenueForm from "Events/Components/VenueForm";
import NotesForm from "Events/Components/NotesForm";
import SessionsForm from "Events/Components/SessionsForm";
import TicketingForm from "Events/Components/TicketingForm";
import { useSessionStorage } from "shared/@hooks/useSessionStorage";

import styles from "./EditEventPage.module.css";
import { HostsForm } from "Events/Components/HostsForm/HostsForm";
import DeleteModal from "Events/Components/DeleteModal";
import AddSpeakerModal from "Events/Components/AddSpeakerModal";
import VirtualForm from "Events/Components/VirtualForm";
import UndoChangesModal from "Events/Components/UndoChangesModal";
import { EventPublishModal } from "Events/Components/EventPublishModal/EventPublishModal";
import { useEditEventsPage } from "./useEditEventPage";
import Loader from "shared/Loader";
import _ from "lodash";
import { combineDateTime } from "shared/@utils/extractCombineDateTime";
import formatDate from "shared/@utils/formatDate";

export function EditEventPage() {
  const history = useHistory();

  const { eventId } = useParams();

  const { data, status, isLoading, refetchEvent, eventEditPut, eventPublish } =
    useEditEventsPage({
      id: eventId,
    });

  const [eventData, setEventData] = useState(null);
  const [showSavedModal, changeShowSavedModal] = useState(false);
  const [showRemoveModal, changeShowRemoveModal] = useState(false);
  const [undoChangesModal, setUndoChangesModal] = useState(false);
  const [showAddSpeakerModal, setShowAddSpeakerModal] = useState(false);
  const [removeChangesModal, setRemoveChangesModal] = useState(false);

  const [showUndoButton, setShowUndoButton] = useState(false);
  const [enablePublishButton, setEnablePublishButton] = useState(false);

  //state to hold form data
  const [formData, setFormData] = useSessionStorage("formData", {
    publishingInformation: {
      visibleOnDate: "",
      visibleOnTime: "",
      visibleTo: "All IBOs",
      tentative: false,
    },
    venue: {
      state: "",
      city: "",
      venue: "",
      noVenue: false,
    },

    virtual: {
      type: "",
      link: "",
    },

    sessions: [
      {
        //This is pulled from the API
        type: "",
        //This is pulled from the API
        ticketGroups: ["Spring Leadership", "Guest"],
        sessionName: "",
        description: "",
        speakers: [],
        rooms: [],
        room: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
    ],
    ticketing: [
      {
        name: "Spring Leadership",
        available: 0,
        limit: "Limit tickets per customer (IBO)",
        ticketsPer: "2",
        tickets: [
          {
            id: "SL_STD",
            name: "Standard",
            price: 150,
            timeZone: "MST",
            startDate: "7/30/2022",
            endDate: "8/30/2022",
            startTime: "10:00 AM",
            endTime: "11:59 PM",
          },
          {
            id: "SL_NPM",
            name: "New Premier Member",
            price: 0,
            timeZone: "CST",
            startDate: "6/20/2022",
            endDate: "6/30/2022",
            startTime: "1:00 PM",
            endTime: "12:00 AM",
          },
          {
            id: "SL_Q12",
            name: "Q12 Platinum",
            price: 0,
            timeZone: "CST",
            startDate: "6/30/2022",
            endDate: "8/30/2022",
            startTime: "12:00 AM",
            endTime: "10:45 PM",
          },
          {
            id: "SL_SP",
            name: "Standard Platinum",
            price: 150,
            timeZone: "CST",
            startDate: "6/20/2022",
            endDate: "8/30/2022",
            startTime: "12:00 PM",
            endTime: "11:45 AM",
          },
        ],
        eventStartDate: "9/01/2022",
      },
      {
        name: "Guest - Spring Leadership",
        available: 0,
        limit: "Limit tickets per customer (IBO)",
        ticketsPer: "2",
        tickets: [
          {
            id: "SL_Guest",
            name: "Guest",
            price: 0,
            timeZone: "CST",
            startDate: "6/20/2022",
            endDate: "8/30/2022",
            startTime: "12:00 PM",
            endTime: "11:59 AM",
          },
        ],
        eventStartDate: "9/01/2022",
      },
      {
        name: "Eagle Ticket",
        available: 0,
        limit: "Limit tickets per each user",
        ticketsPer: "2",
        tickets: [
          {
            id: "Eagle",
            name: "Eagle Ticket",
            price: 0,
            timeZone: "CST",
            startDate: "6/20/2022",
            endDate: "8/30/2022",
            startTime: "12:00 PM",
            endTime: "11:59 PM",
          },
        ],
        eventStartDate: "9/01/2022",
      },
      {
        name: "Sunday Service",
        available: 0,
        limit: "Limit tickets per each user",
        ticketsPer: "2",
        tickets: [
          {
            id: "SundaySVC",
            name: "Sunday SVC",
            price: 0,
            timeZone: "CST",
            startDate: "6/20/2022",
            endDate: "8/30/2022",
            startTime: "12:00 PM",
            endTime: "11:59 PM",
          },
        ],
        eventStartDate: "9/01/2022",
      },
    ],
    hosts: [
      {
        iboNum: "00000000",
        name: "First Last",
        username: "username",
        phoneNumber: "000-000-0000",
        email: "email@address.com",
        location: "location",
      },
      {
        iboNum: "12345678",
        name: "Test Name",
        username: "test username",
        phoneNumber: "555-111-5555",
        email: "tester@address.com",
        location: "test location",
      },
    ],
    notes: {
      additionalNotes: "",
      notesToBePublished: "",
    },
  });

  useEffect(() => {
    if (data && !isLoading) {
      setEventData(data);
    }
  }, [data, isLoading]);

  //Check if saved value exists
  const checkSavedValues = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // check nested objects
        if (checkSavedValues(obj[key])) {
          return true;
        }
      } else if (key === "saved" && obj[key] !== null && obj[key] !== "") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (eventData) {
      const savedValuesExist = checkSavedValues(eventData);
      setShowUndoButton(savedValuesExist);

      //Publish button
      const isUnpublished = eventData.event.status.value === "Unpublished";

      const shouldEnablePublish =
        (savedValuesExist && eventData.event.status.value !== "Publishing") ||
        isUnpublished;
      setEnablePublishButton(shouldEnablePublish);
    }
  }, [eventData]);

  const handlePublishingInfoForm = (e, field) => {
    setFormData({
      ...formData,
      publishingInformation: {
        ...formData.publishingInformation,
        [field]: e,
      },
    });
  };

  const toggleTentative = (publishing) => {
    setFormData({
      ...formData,
      publishingInformation: {
        ...formData.publishingInformation,
        tentative: publishing,
      },
    });
  };

  const handleVenueForm = (e, field) => {
    setFormData({
      ...formData,
      venue: {
        ...formData.venue,
        [field]: e,
      },
    });
  };

  const handleVirtualForm = (e, field) => {
    setFormData({
      ...formData,
      virtual: {
        ...formData.virtual,
        [field]: e,
      },
    });
  };

  const handleNotesForm = (e, field) => {
    setFormData({
      ...formData,
      notes: {
        ...formData.notes,
        [field]: e,
      },
    });
  };

  const handleSessionsForm = (updatedSessions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sessions: updatedSessions,
    }));
  };

  const handleTicketingForm = (e) => {
    setFormData({
      ...formData,
      ticketing: [...e],
    });
  };

  const handlePublish = () => {
    changeShowSavedModal(true);

    onPublish();
  };

  const [undoField, setUndoField] = useState(undefined);

  const [original, setOriginal] = useState(undefined);

  const handleUndoButtonClick = (index, field, original) => {
    setRemoveChangesModal(true);
    setUndoChangesModal(false);
    setUndoField(field);
  };

  const formatDateIfValid = (date) => {
    return date ? formatDate(date, "MM/DD/YYYY") : null;
  };

  //Handles undoing the changes in modal
  const removeEditedEvent = () => {
    let updatedData = { ...eventData };

    if (undoField === "location" || undoField === "jobCode") {
      updatedData.event = {
        ...updatedData.event,
        [undoField]: original,
      };
    } else {
      updatedData.eventInformation = {
        ...updatedData.marketing,
        [undoField]: original,
      };
    }

    setRemoveChangesModal(false);
  };

  const handleLocationChange = (name, newLocation) => {
    setEventData((prevState) => ({
      ...prevState,
      event: {
        ...prevState.event,
        [name]: newLocation,
      },
    }));

    const eventObject = {
      event: {
        location: newLocation,
      },
    };

    eventEditPut.mutateAsync(eventObject);
    // console.log("ONBLUR LOCATION OBJECT:", eventObject);
  };

  const handleMarketingOnBlur = _.debounce(async (obj, key, saved) => {
    if (saved == null) {
      console.error("Received null or undefined saved value");
      return;
    }

    setEventData((prevData) => {
      if (_.isEqual(prevData[obj][key], saved)) {
        return prevData;
      }

      // Updating the eventData state
      const updatedData = {
        ...prevData,
        [obj]: {
          ...prevData[obj],
          [key]: {
            ...prevData[obj][key],
            saved,
          },
        },
      };

      const marketingData = updatedData.marketing;

      // Additional logic that was previously in useEffect
      const newStartDate = combineDateTime(
        marketingData?.startDate?.saved,
        marketingData?.startTime?.saved
      );

      const newEndDate = combineDateTime(
        marketingData?.endDate?.saved,
        marketingData?.endTime?.saved
      );

      const eventObject = {
        event: {
          jobCode: updatedData?.event.jobCode?.saved,
        },
        marketing: {
          title: marketingData.title.saved,
          description: marketingData.description.saved,
          location: marketingData.location.saved,
          startDate: newStartDate,
          startTimeZone: marketingData.startTimeZone.saved,
          endDate: newEndDate,
          endTimeZone: marketingData.endTimeZone.saved,
        },
      };

      if (_.isEqual(prevData[obj], eventObject[obj])) {
        return prevData;
      }

      eventEditPut.mutateAsync(eventObject);

      // Return the updated data to be set into the state
      return updatedData;
    });
  }, 300);

  const prevEventDataRef = useRef();

  const onPublish = () => {
    eventPublish.mutateAsync();
  };

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  const handleViewCreatedEvent = () => {
    changeShowSavedModal(false);
    history.push(`view/event/${eventId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <span className={styles.backButton} onClick={() => history.goBack()}>
            {"< Back"}
          </span>
          <h1 className={styles.title}>EDIT EVENT</h1>
        </div>

        <div className={styles.buttonContainer}>
          {showUndoButton && (
            <Button
              className={styles.undoButton}
              children={"UNDO CHANGES"}
              onClick={() => setUndoChangesModal(true)}
            />
          )}

          <Button
            className={
              eventData?.event?.status?.value === "Publishing"
                ? styles.greenButton
                : styles.saveButton
            }
            children={
              eventData?.event?.status?.value === "Publishing"
                ? "PUBLISHING"
                : "PUBLISH"
            }
            onClick={() => handlePublish()}
            disabled={!enablePublishButton || !eventData?.event?.status}
          />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        {eventData && eventData.event && (
          <EventTypeCard
            eventInfo={eventData.event}
            onChange={handleLocationChange}
            isEdit={true}
          />
        )}

        <div className={styles.cardContainer}>
          {eventData && (
            <EventInfoForm
              marketingInfo={eventData}
              onAddSpeaker={() => setShowAddSpeakerModal(true)}
              onBlur={handleMarketingOnBlur}
            />
          )}
        </div>
        <div className={styles.cardContainer}>
          <PublishingInfoForm
            visibleOnDate={formData.publishingInformation.visibleOnDate}
            setVisibleOnDate={(e) =>
              handlePublishingInfoForm(e, "visibleOnDate")
            }
            visibleOnTime={formData.publishingInformation.visibleOnTime}
            setVisibleOnTime={(e) =>
              handlePublishingInfoForm(e, "visibleOnTime")
            }
            visibleTo={formData.publishingInformation.visibleTo}
            setVisibleTo={(e) => handlePublishingInfoForm(e, "visibleTo")}
            tentative={formData.publishingInformation.tentative}
            setTentative={toggleTentative}
          />
        </div>

        {formData.event?.location === "Onsite" ? (
          <div className={styles.cardContainer}>
            <VenueForm
              state={formData.venue.state}
              setState={(e) => handleVenueForm(e, "state")}
              city={formData.venue.city}
              setCity={(e) => handleVenueForm(e, "city")}
              venue={formData.venue.venue}
              setVenue={(e) => handleVenueForm(e, "venue")}
              addNewVenue={() => {}}
            />
          </div>
        ) : (
          <div className={styles.cardContainer}>
            <VirtualForm
              type={formData.virtual?.type}
              setType={(e) => handleVirtualForm(e, "type")}
              link={formData.virtual?.link}
              setLink={(e) => handleVirtualForm(e, "link")}
            />
          </div>
        )}

        <div className={styles.cardContainer}>
          <SessionsForm
            sessions={formData.sessions}
            setSessions={handleSessionsForm}
          />
        </div>
        <div className={styles.cardContainer}>
          <TicketingForm
            ticketGroups={[...formData.ticketing]}
            setTicketGroups={handleTicketingForm}
          />
        </div>
        <div className={styles.cardContainer}>
          <HostsForm />
        </div>
        <div className={styles.cardContainer}>
          <NotesForm
            additionalNotes={formData.notes.additionalNotes}
            notesToBePublished={formData.notes.notesToBePublished}
            setAdditionalNotes={(e) => handleNotesForm(e, "additionalNotes")}
            setNotesToBePublished={(e) =>
              handleNotesForm(e, "notesToBePublished")
            }
          />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Button
          className={
            eventData?.event?.status?.value === "Publishing"
              ? styles.greenButton
              : styles.saveButton
          }
          children={
            eventData?.event?.status?.value === "Publishing"
              ? "PUBLISHING"
              : "PUBLISH"
          }
          onClick={() => handlePublish()}
          disabled={!enablePublishButton || !eventData?.event?.status}
        />
      </div>
      <AddSpeakerModal
        isOpen={showAddSpeakerModal}
        onCollapse={() => setShowAddSpeakerModal(false)}
        onClick={() => {}}
        speakers={eventData?.speakers?.value}
      />

      {eventData && eventData.event && eventData.marketing && (
        <UndoChangesModal
          isOpen={undoChangesModal}
          onCollapse={() => setUndoChangesModal(false)}
          editedEvent={[
            {
              field: "Event Type",
              field_name: "location",
              original_value: "",
              saved_value: eventData.event?.location?.saved,
            },
            {
              field: "Display Name",
              field_name: "title",
              original_value: "",
              saved_value: eventData.marketing.title?.saved,
            },
            {
              field: "Job Code",
              field_name: "jobCode",
              original_value: "",
              saved_value: eventData.event.jobCode?.saved,
            },
            {
              field: "Event Description",
              field_name: "description",
              original_value: "",
              saved_value: eventData.marketing.description?.saved,
            },
            {
              field: "Location",
              field_name: "location",
              original_value: "",
              saved_value: eventData.event.location?.saved,
            },
            {
              field: "Start Date",
              field_name: "startDate",
              // original_value: `${formatDate(
              //   originalData.marketing.startDate,
              //   "MM/DD/YYYY"
              // )}`,
              saved_value: formatDateIfValid(
                eventData.event.dates.eventStartDate?.saved,
                "MM/DD/YYYY"
              ),
            },

            {
              field: "End Date",
              field_name: "endDate",
              // original_value: `${formatDate(
              //   originalData.marketing.endDate,
              //   "MM/DD/YYYY"
              // )}`,
              saved_value: formatDateIfValid(
                eventData.event.dates.eventEndDate?.saved,
                "MM/DD/YYYY"
              ),
            },
          ].filter((change) => change.saved_value !== null)}
          onClick={handleUndoButtonClick}
        />
      )}
      {/* C */}
      <EventPublishModal
        isOpen={showSavedModal}
        onCollapse={() => changeShowSavedModal(false)}
        onClick={() => history.push("/events/events-details")}
        onView={() => handleViewCreatedEvent()}
      />
      <DeleteModal
        header={"REMOVE HOST"}
        description={
          "Confirm that you would like to delete [Venue Name]. This action cannot be undone."
        }
        isOpen={showRemoveModal}
        onCollapse={() => changeShowRemoveModal(false)}
        onClick={() => console.log("Removed")}
        btnName={"REMOVE"}
      />
      <DeleteModal
        header={"REMOVE CHANGES"}
        description={"Are you sure you want to undo the changes you made?"}
        isOpen={removeChangesModal}
        onCollapse={() => setRemoveChangesModal(false)}
        onClick={removeEditedEvent}
        btnName={"REMOVE"}
      />
    </div>
  );
}
