import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Select.module.css";

import { ReactComponent as DownArrow } from "./Down_Arrow.svg";

/**
 * Interactive component.
 * Custom select box.
 */
export function Select({
  options,
  onChange,
  placeholder,
  disabled,
  selectedOption,
  name,
  className,
  textColor,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkClick = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkClick);

    return () => {
      document.removeEventListener("mousedown", checkClick);
    };
  }, [isOpen]);

  return (
    <div className={classNames(styles.container)} ref={ref}>
      <input
        className={classNames(styles["display-none"])}
        type="text"
        name={name}
        value={selectedOption}
        readOnly
      />
      <button
        disabled={disabled}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={classNames(
          styles["dropdown-button"],
          styles.flex,
          styles.border,
          className,
          {
            [styles["open-placeholder"]]: selectedOption === "",
          }
        )}
      >
        <span>{selectedOption === "" ? placeholder : selectedOption}</span>
        {!disabled && <DownArrow className={classNames(styles.arrow)} />}
      </button>
      <ul
        onClick={() => setIsOpen(false)}
        id={classNames(styles["custom-options"])}
        className={classNames(styles.border, {
          [styles.none]: !isOpen,
        })}
        style={{ color: textColor }}
      >
        {options
          .filter((option) => option !== selectedOption)
          .map((option, index) => (
            <li
              value={option}
              onClick={(e) => {
                isOpen && onChange(e.target.innerText);
              }}
              key={index}
            >
              {option}
            </li>
          ))}
      </ul>
    </div>
  );
}

Select.propTypes = {
  /**
   * Array of options to map through.
   */
  options: PropTypes.array,
  /**
   * Function that fires when the user selects a new option.
   */
  onChange: PropTypes.func,
  /**
   * Placeholder for the input.
   */
  placeholder: PropTypes.string,
  /**
   * Boolean that determines if the select box is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Value of the currently selected option.
   */
  selectedOption: PropTypes.string,
  /**
   * Name attribute for the select input.
   */
  name: PropTypes.string,
  /**
   * Determines the text color for the options
   */
  textColor: PropTypes.string,
};

Select.defaultProps = {
  /**
   * Defaults to avoid undefined.
   */
  options: [],
  /**
   * Defaults to default select option.
   */
  selectedOption: "",
};
