import React, { useMemo, useState, useEffect, useRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Modal } from "shared/Modal";
import Loader from "shared/Loader";

import Select from "Events/Components/Select";
import EventItem from "Events/Components/EventItem";
import CartItem from "Events/Components/CartItem";
import styles from "./NewOrderModal.module.css";
import FilterOrderEvent from "Events/Components/FilterOrderEvent";

import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { useEventsData } from "./useNewOrder";

/**
 * Interactive component.
 * Modal that allows a user to select and purchase tickets for multiple events.
 */
export function NewOrderModal({ iboNumber, onClose, refreshIBOInfo }) {
  const { data: states, isLoading: isLoadingStates } = useCurrentRoute();
  const {
    data: eventsData,
    isLoading: eventsDataLoading,
    refetch: eventsDataRefetch,
    clearCartPost,
    addToCartPost,
    createOrderPost,
  } = useEventsData(iboNumber);

  // const [ticketWarnings, setTicketWarnings] = useState([]);
  const [testTicket, setTestTicket] = useState(undefined);
  const [cartWarnings, setCartWarnings] = useState([]);
  const [itemTotalPrices, setItemTotalPrices] = useState([]);

  const [creditCards, setCreditCards] = useState([]);
  const [selectedCreditCard, setSelectedCreditCard] = useState(undefined);
  const [showCreditCards, setShowCreditCards] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [loadingCartItemId, setLoadingCartItemId] = useState(null);
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [showCreateOrderButton, setShowCreateOrderButton] = useState();
  const [orderResponses, setOrderResponses] = useState([]);

  const [events, setEvents] = useState([]);
  const [iboInfo, setIboInfo] = useState([]);

  useEffect(() => {
    if (eventsData && iboInfo) {
      setEvents(eventsData.events);
      setIboInfo(eventsData.iboInfo);
    }
  }, [eventsData, iboInfo]);

  const [stateIdToNameMap, setStateIdToNameMap] = useState({});

  useEffect(() => {
    if (states && !isLoadingStates) {
      const map = states.reduce((acc, state) => {
        acc[state.stateId] = state.abbreviation;
        return acc;
      }, {});
      setStateIdToNameMap(map);
    }
  }, [states, isLoadingStates]);

  function formatDateWithTimeZone(dateString, timeZone, options = {}) {
    const safeTimeZone = timeZone || "UTC";
    const date = new Date(dateString);
    if (isNaN(date)) {
      // Check if date is invalid
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }
    try {
      const formatter = new Intl.DateTimeFormat("en-US", {
        ...options,
        timeZone: safeTimeZone,
      });
      return formatter.format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateString; // Fallback to returning the original dateString on error
    }
  }

  const uniqueEventTypes = useMemo(() => {
    const eventTypeValues = events
      ? events.map((eventData) => eventData?.event?.name?.value).filter(Boolean)
      : [];

    const uniqueEventTypeSet = new Set(eventTypeValues);
    const eventTypeArray = Array.from(uniqueEventTypeSet).map((type) => ({
      label: type,
      value: type,
    }));

    eventTypeArray.unshift({ label: "All", value: "All" });
    return eventTypeArray;
  }, [events]);

  const [cartItems, setCartItems] = useState([]);
  const cartRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cartItemErrors, setCartItemErrors] = useState(
    new Array(cartItems.length).fill(null)
  );
  const [ticketTypeError, setTicketTypeError] = useState(null);

  const isValidTicketType = (selectedType) => {
    return selectedType !== null;
  };

  const noSelectedTicketType = () => {
    // setTicketTypeError("");
    // setCartItemErrors([]);
    // console.log(cartItems);
    // const invalidTicket = cartItems.some((cartItem) =>
    //   cartItem.item.tickets.some(
    //     (ticket) => !isValidTicketType(ticket.type, cartItem.item.types)
    //   )
    // );

    // if (invalidTicket) {
    //   setTicketTypeError(
    //     "Please select a ticket type for all tickets before proceeding."
    //   );
    //   return true;
    // } else {
    setTicketTypeError(null);
    return false;
    // }
  };

  const [isCartEmpty, setIsCartEmpty] = useState(true);

  useEffect(() => {
    setIsCartEmpty(cartItems.length === 0);

    if (cartItems && cartItems.length > 0) {
      const hasMissingTicketId = cartItems.some(
        (cartItem) =>
          !cartItem.item.ticketData ||
          cartItem.item.ticketData.length === 0 ||
          cartItem.item.ticketData.some((ticket) => !ticket.ticketId)
      );

      setShowCreateOrderButton(!hasMissingTicketId);
    } else {
      setShowCreateOrderButton(false);
    }
  }, [cartItems]);

  const handleAdd = (id, event, dateDisplay) => {
    setShowCreateOrderButton(false);
    setCartLoading(true);
    // if (noSelectedTicketType()) return;
    // setCurrentEventId(id);
    setIsCartEmpty(false);

    setLoadingCartItemId(id);

    setCartItems((prevCartItems) => [
      ...prevCartItems,
      {
        item: {
          id,
          eventName: event.name.value,
          // location: event.location.value,
          location:
            event.location.value == "onsite"
              ? `${event.city.value}, ${stateIdToNameMap[event.state.value]}`
              : `${event.city.value}`,
          ruleset: event.ruleset.value,
          dates: dateDisplay,
          displayAddToCart: false,
          ticketData: [],
          ticketWarnings: [],
        },
      },
    ]);
  };

  const updateTicketData = (cartItemId, ticketData) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((cartItem) => {
        if (cartItem.item.id === cartItemId) {
          return {
            ...cartItem,
            item: {
              ...cartItem.item,
              ticketData: ticketData,
            },
          };
        }
        return cartItem;
      })
    );
  };

  const updateTicketWarnings = (cartItemId, warning, addDeleteOrEdit) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((cartItem) => {
        if (cartItem.item.id === cartItemId) {
          let updatedWarnings;
          switch (addDeleteOrEdit) {
            case "add":
              updatedWarnings = [...cartItem.item.ticketWarnings, warning];
              break;
            case "delete":
              const warningIndex = cartItem.item.ticketWarnings.findIndex(
                (w) => w.id === warning.id
              );
              if (warningIndex > -1) {
                updatedWarnings = [
                  ...cartItem.item.ticketWarnings.slice(0, warningIndex),
                  ...cartItem.item.ticketWarnings.slice(warningIndex + 1),
                ];
              } else {
                updatedWarnings = cartItem.item.ticketWarnings;
              }
              break;
            case "edit":
              const ticketCount = cartItem.item.ticketData.reduce(
                (acc, ticket) => {
                  acc[ticket.ticketId] = (acc[ticket.ticketId] || 0) + 1;
                  return acc;
                },
                {}
              );

              const filteredWarnings = cartItem.item.ticketWarnings.filter(
                (w) => w.id === null || ticketCount[w.id]
              );

              const warningCount = filteredWarnings.reduce((acc, w) => {
                if (w.id !== null) {
                  acc[w.id] = (acc[w.id] || 0) + 1;
                }
                return acc;
              }, {});

              updatedWarnings = filteredWarnings
                .filter((w) => {
                  if (w.id === null) return true;
                  if (warningCount[w.id] > ticketCount[w.id]) {
                    warningCount[w.id]--;
                    return false;
                  }
                  return true;
                })
                .concat(warning);
              break;
            default:
              updatedWarnings = cartItem.item.ticketWarnings;
          }

          return {
            ...cartItem,
            item: {
              ...cartItem.item,
              ticketWarnings: updatedWarnings,
            },
          };
        }
        return cartItem;
      })
    );
  };

  useEffect(() => {
    if (testTicket) {
      updateTicketWarnings(testTicket.cartItemId, testTicket, "edit");
    }
  }, [testTicket]);

  const updateDisplayAddToCart = (cartItemId, value) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((cartItem) => {
        if (cartItem.item.id === cartItemId) {
          return {
            ...cartItem,
            item: {
              ...cartItem.item,
              displayAddToCart: value,
            },
          };
        }
        return cartItem;
      })
    );
  };

  useEffect(() => {
    cartRef.current = cartItems;
  }, [cartItems]);

  const [ticketInfo, setTicketInfo] = useState([]);
  const getTicketInfo = (name, quantity, taxRate, price, adaValue) => {
    setTicketInfo([
      {
        type: name,
        quantity: quantity,
        taxRate: taxRate,
        price: price,
        adaValue: adaValue,
      },
    ]);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [message, setMessage] = useState(null);
  const [alert, setAlert] = useState({ message: null, type: null });

  const [cartLoading, setCartLoading] = useState(true);

  const [selectedEventType, setSelectedEventType] = useState("");

  const [clearFilters, setClearFilters] = useState(false);

  const handleSelectEventType = (selectedLabel) => {
    const selectedOptionObject = uniqueEventTypes.find(
      (option) => option.label === selectedLabel
    );
    if (selectedOptionObject) {
      setSelectedEventType(selectedOptionObject.value);
      setCartItems([]);
      setCartItemErrors([]);
      setTicketTypeError("");
    }

    setClearFilters(true);
  };

  //Filter Event in select
  const filteredSelectEvents = useMemo(() => {
    if (!selectedEventType || selectedEventType === "All") {
      return events;
    }
    return events.filter(
      (event) => event.event.name.value === selectedEventType
    );
  }, [events, selectedEventType]);

  //Alert messages
  const showAlerts = () => {
    const alerts = [];

    if (iboInfo?.iboName) {
      if (iboInfo.membership?.complimentary) {
        alerts.push(
          <div
            className={styles.alert + " " + styles["alert-danger"]}
            role="alert"
          >
            {iboInfo.iboName} are Complimentary
          </div>
        );
      }
      if (!iboInfo.membership?.premierMember) {
        alerts.push(
          <div
            className={styles.alert + " " + styles["alert-danger"]}
            role="alert"
          >
            {iboInfo.iboName} is NOT a Premier Member
          </div>
        );
      }

      const infoMessages = {
        2: "is a Platinum",
        3: "is an Emerald",
        4: "is a Diamond",
      };
      const pinLevelMessage = infoMessages[iboInfo.loa?.pinLevel];
      if (pinLevelMessage) {
        alerts.push(
          <div
            className={styles.alert + " " + styles["alert-info"]}
            role="alert"
          >
            {iboInfo.iboName} {pinLevelMessage}
          </div>
        );
      }
    }

    return alerts;
  };

  const handleClearCart = async (cartItemId) => {
    try {
      await clearCartPost.mutateAsync({ iboNumber, eventId: cartItemId });
      setRefetchOrderInfoGetData(refetchOrderInfoGetData + 1);
      updateDisplayAddToCart(cartItemId, true);
    } catch (error) {
      console.error("Error Clearing Cart: ", error);
    }
  };

  const handleRemoveCartItem = (cartItemId) => {
    // Remove the cart item
    setCartItems((currentCartItems) =>
      currentCartItems.filter((cartItem) => cartItem.item.id !== cartItemId)
    );

    // Remove the item from the itemTotalPrices array and get the item's total price and tax
    setItemTotalPrices((prevItemTotalPrices) => {
      const itemToRemove = prevItemTotalPrices.find(
        (item) => item.id === cartItemId
      );
      const updatedItemTotalPrices = prevItemTotalPrices.filter(
        (item) => item.id !== cartItemId
      );

      // Recalculate subtotal and tax
      const newSubtotal = updatedItemTotalPrices.reduce(
        (total, item) => total + item.price,
        0
      );
      const newTax = updatedItemTotalPrices.reduce(
        (total, item) => total + item.tax,
        0
      );

      setSubtotal(newSubtotal);
      setTax(newTax);

      return updatedItemTotalPrices;
    });

    // Remove all warnings associated with the cart item and its tickets
    // setTicketWarnings((prevWarnings) =>
    //   prevWarnings.filter((warning) => warning.cartItemId !== cartItemId)
    // );

    // Remove the credit card object with the matching cartItemId
    setCreditCards((prevCreditCards) =>
      prevCreditCards.filter((creditCard) => creditCard.id !== cartItemId)
    );

    // Hide Credit Cards section if there are no more CartItems
    // setCartItems((currentCartItems) => {
    //   if (currentCartItems.length - 1 < 1) {
    //     setShowCreditCards(false);
    //   }
    //   return currentCartItems;
    // });
  };

  const handleAddToCart = async (cartItemId) => {
    const cartItem = cartItems.find((item) => item.item.id === cartItemId);

    const ticketData = cartItem.item.ticketData.flatMap((ticket) => {
      const eventTaxItem = itemTotalPrices.find(
        (item) => item.id === cartItem.item.id
      );
      const eventTaxRate = eventTaxItem.taxRate;

      let eventTax = eventTaxRate * eventTaxItem.price;

      eventTax = Math.round(Math.ceil(eventTax * 1000) / 10) / 100;

      return Array(ticket.quantity || 1)
        .fill()
        .map(() => ({
          ticketId: ticket.ticketId,
          price: ticket.price.toString(),
          adaValues: ticket.adaValues.map((value) => value.toString()),
          taxRate: eventTaxRate,
          tax: eventTax,
          // tax: ticket.price.toString() == 0 ?  "$0" : eventTax,
        }));
      // .map(() => {
      //   const ticketInfo = {
      //     ticketId: ticket.ticketId,
      //     price: ticket.price.toString(),
      //     adaValues: ticket.adaValues.map((value) => value.toString()),
      //     taxRate: eventTaxRate,
      //   };

      //   if (ticket.price !== 0) {
      //     ticketInfo.tax = eventTax;
      //   }

      //   return ticketInfo;
      // });
    });

    try {
      await addToCartPost.mutateAsync({
        iboNumber: iboNumber,
        eventId: cartItemId,
        ticketData: ticketData,
      });
      setRefetchOrderInfoGetData(refetchOrderInfoGetData + 1);
    } catch (error) {
      console.error("Error Adding Cart Item: ", error);
    }
  };

  // //Extract ticketType & ticketPrice
  // const firstCartItem = cartItems[0];
  // const firstTicketOfFirstCartItem = firstCartItem?.item?.tickets[0];
  // const ticketPriceExist = firstTicketOfFirstCartItem?.price;
  // const ticketTypeExist = firstTicketOfFirstCartItem?.type;

  //Filtering events

  const [selectedRuleset, setSelectedRuleset] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);

  const venueOptions = ["Onsite", "Virtual"];

  let eventsOptions = [];

  if (events) {
    const eventsSet = new Set();

    events.forEach((item) => {
      if (
        item.event.name.value &&
        item.event.ruleset &&
        item.event.ruleset.value
      ) {
        const eventName = item.event.name.value.toString().trim();
        const rulesetName = item.event.ruleset.value.toString().trim();
        eventsSet.add(JSON.stringify({ eventName, rulesetName }));
      }
    });
    eventsOptions = Array.from(eventsSet).map((e) => JSON.parse(e));
  }

  const rulesetOptions = [
    ...new Set(eventsOptions.map((event) => event.rulesetName)),
  ];

  const eventNameOptions = selectedRuleset
    ? [
        ...new Set(
          eventsOptions
            .filter((event) => event.rulesetName === selectedRuleset)
            .map((event) => event.eventName)
        ),
      ]
    : [...new Set(eventsOptions.map((event) => event.eventName))];

  function handleFilter(filterObject = {}) {
    if (
      filterObject.ruleset !== undefined ||
      filterObject.eventName !== undefined
    ) {
      setSelectedEventType("All");
    }

    const filtered = events.filter((item) => {
      const marketingTitle = item.marketing.title.value.toLowerCase();
      const rulesetValue = item.event.ruleset?.value.toLowerCase();
      const eventNameValue = item.event.name.value.toLowerCase();
      const locationValue = item.event.location.value.toLowerCase();
      const eventStartDate = new Date(
        item.event.dates.eventStartDate.value
      ).getTime();
      const eventEndDate = item.event.dates.eventEndDate
        ? new Date(item.event.dates.eventEndDate.value).getTime()
        : eventStartDate;
      const filterStartDate = filterObject.startDate
        ? new Date(filterObject.startDate).getTime()
        : null;
      const filterEndDate = filterObject.endDate
        ? new Date(filterObject.endDate).getTime()
        : null;

      return (
        (!filterObject.search ||
          marketingTitle.includes(filterObject.search.toLowerCase())) &&
        (!filterObject.ruleset ||
          (rulesetValue &&
            rulesetValue.includes(filterObject.ruleset.toLowerCase()))) &&
        (!filterObject.eventName ||
          eventNameValue.includes(filterObject.eventName.toLowerCase())) &&
        (!filterObject.venue ||
          locationValue.includes(filterObject.venue.toLowerCase())) &&
        (!filterObject.startDate ||
          (eventStartDate >= filterStartDate && eventEndDate <= filterEndDate))
      );
    });

    setFilteredEvents(filtered);
  }

  const handleCancel = () => {
    setCartItems([]);
    setSelectedEventType("");
    onClose();
  };

  const handlePlaceOrder = async () => {
    setCreatingOrder(true);

    const events = cartItems.map((cartItem) => {
      const eventTaxItem = itemTotalPrices.find(
        (item) => item.id === cartItem.item.id
      );
      const eventTaxRate = eventTaxItem.taxRate;

      let eventTax = eventTaxRate * eventTaxItem.price;

      eventTax = Math.round(Math.ceil(eventTax * 1000) / 10) / 100;

      return {
        eventId: cartItem.item.id,
        taxRate: eventTaxRate,
        tickets: cartItem.item.ticketData.flatMap(
          (ticket) =>
            Array(ticket.quantity || 1)
              .fill()
              .map(() => ({
                ticketId: ticket.ticketId,
                price: ticket.price,
                tax: eventTax,
                // tax: ticket.price == 0 ? "$0" : eventTax,
                adaValues: ticket.adaValues || [],
              }))
          // .map(() => {
          //   const ticketInfo = {
          //     ticketId: ticket.ticketId,
          //     price: ticket.price,
          //     adaValues: ticket.adaValues || [],
          //     taxRate: eventTaxRate,
          //   };

          //   if (ticket.price !== 0) {
          //     ticketInfo.tax = eventTax;
          //   }

          //   return ticketInfo;
          // })
        ),
      };
    });

    const getPrimaryCardName = () => {
      if (creditCards && creditCards[0]) {
        const primaryCard = creditCards[0].creditCards.find(
          (card) => card.isPrimary
        );
        if (primaryCard) {
          return `${primaryCard.cardType} *************${primaryCard.lastFour}`;
        }
      }
      return "";
    };

    const selectedCardName = selectedCreditCard
      ? selectedCreditCard.name
      : getPrimaryCardName();

    const selectedCard = creditCards[0].creditCards.find(
      (card) =>
        `${card.cardType} *************${card.lastFour}` === selectedCardName
    );

    const orderData = {
      functionCreditCardId: selectedCard?.functionCreditCardId,
      events: events,
    };

    try {
      // const response = [
      //   {
      //     eventId: "669576da38a824901b248764",
      //     message: "Error placing order",
      //     error: {
      //       status: 400,
      //       message:
      //         "Tax should be 9.68 for STD_1 ticket on event 669576da38a824901b248764",
      //     },
      //   },
      //   {
      //     eventId: "661efa0df7d3405bf87f45b9",
      //     message: "Order not placed",
      //   },
      //   {
      //     eventId: "66047736679851968ee3cc8b",
      //     message: "Order placed successfully",
      //   },
      // ];
      const response = await createOrderPost.mutateAsync({
        iboNumber: iboNumber,
        orderData: orderData,
      });

      // handleCancel();
      setCartItems([]);
      setSelectedEventType("");
      setOrderResponses(response);

      setCreatingOrder(false);
      await refreshIBOInfo();
    } catch (error) {
      setCreatingOrder(false);
      console.error("Error Creating Order: ", error);
    }
  };

  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [refetchOrderInfoGetData, setRefetchOrderInfoGetData] = useState(1);

  useEffect(() => {
    // Function to calculate the total tax
    const calculateTotalTax = () => {
      const totalTax = itemTotalPrices.reduce((total, item) => {
        let itemTax = item.price * item.taxRate;

        itemTax = Math.round(Math.ceil(itemTax * 1000) / 10) / 100;
        return total + itemTax;
      }, 0);

      setTax(totalTax);
    };

    // Calculate the total tax if there are items in itemTotalPrices
    if (itemTotalPrices.length > 0) {
      calculateTotalTax();
    }
  }, [itemTotalPrices]);

  return (
    <>
      {orderResponses.length < 1 ? (
        <Modal
          title="Create New Order"
          isOpen={true}
          onClose={onClose}
          closeBtn="none"
          className={styles.modal}
          children={
            <>
              {creatingOrder ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : (
                <>
                  {alert.message ? (
                    <>
                      <div
                        className={`${styles.customAlert} ${
                          alert.type === "error"
                            ? styles.alertError
                            : styles.alertSuccess
                        }`}
                        role="alert"
                      >
                        {alert.message}
                      </div>
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button
                          type="button"
                          onClick={onClose}
                          className={styles.closeBtn}
                        >
                          Close
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {eventsDataLoading || isLoadingStates ? (
                        <div className={styles.loader}>
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {uniqueEventTypes.length <= 0 && (
                            <>
                              <div className={styles.noEvents}>
                                There are currently no events available to
                                purchase.
                              </div>
                            </>
                          )}
                          {uniqueEventTypes.length > 0 && (
                            <>
                              <div className={styles.alerts}>
                                {showAlerts()}
                              </div>

                              <div className={styles.filterByText}>
                                Filter By
                              </div>
                              <div className={styles.filterDiv}>
                                <FilterOrderEvent
                                  rulesetOptions={rulesetOptions}
                                  eventNameOptions={eventNameOptions}
                                  venueOptions={venueOptions}
                                  handleFilter={(filterObject) =>
                                    handleFilter(filterObject)
                                  }
                                  clearFilters={clearFilters}
                                  setClearFilters={setClearFilters}
                                  // onRulesetChange={setSelectedRuleset}
                                />
                              </div>

                              <Select
                                placeholder="Select Event Type"
                                options={uniqueEventTypes.map(
                                  (option) => option.label
                                )}
                                selectedOption={selectedEventType}
                                onChange={handleSelectEventType}
                                className={styles.eventOptions}
                              />
                            </>
                          )}
                        </>
                      )}
                      {(selectedEventType ||
                        (filteredEvents && filteredEvents.length > 0)) && (
                        <div className={styles.events}>
                          <div className={styles.flex}>
                            <p>Event Name</p>
                            <p>Event Location</p>
                            <p>Event Dates</p>
                            <p></p>
                          </div>
                          <ul className={styles.list}>
                            {Array.from(
                              new Set(
                                filteredEvents
                                  .map((e) => e.id)
                                  .filter((id) =>
                                    filteredSelectEvents
                                      .map((e) => e.id)
                                      .includes(id)
                                  )
                              )
                            ).map((id) => {
                              const eventItem =
                                filteredEvents.find((e) => e.id === id) ||
                                filteredSelectEvents.find((e) => e.id === id);
                              const { event, tickets } = eventItem;

                              const startDate = new Date(
                                event.dates.eventStartDate.value
                              );
                              const endDate = event.dates.eventEndDate.value
                                ? new Date(event.dates.eventEndDate.value)
                                : null;

                              const formattedStartDate = formatDateWithTimeZone(
                                event.dates.eventStartDate.value,
                                event.dates.eventStartTimeZone.value,
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              );
                              const formattedStartTime =
                                formatDateWithTimeZone(
                                  event.dates.eventStartDate.value,
                                  event.dates.eventStartTimeZone.value,
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                ) + " PT";
                              let formattedEndTime = endDate
                                ? formatDateWithTimeZone(
                                    event.dates.eventEndDate.value,
                                    event.dates.eventEndTimeZone.value,
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  ) + " PT"
                                : "";

                              let dateDisplay;
                              if (
                                endDate &&
                                formattedStartDate !==
                                  formatDateWithTimeZone(
                                    event.dates.eventEndDate.value,
                                    event.dates.eventEndTimeZone.value,
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }
                                  )
                              ) {
                                // Different start and end dates
                                dateDisplay = `${formattedStartDate} - ${formatDateWithTimeZone(
                                  event.dates.eventEndDate.value,
                                  event.dates.eventEndTimeZone.value,
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )}`;
                              } else {
                                // Same start and end date or no end date
                                dateDisplay =
                                  `${formattedStartDate}, ${formattedStartTime}` +
                                  (formattedEndTime
                                    ? ` - ${formattedEndTime}`
                                    : "");
                              }

                              return (
                                <li key={id}>
                                  <EventItem
                                    eventName={
                                      event.name.value || "Unnamed Event"
                                    }
                                    location={`${event.city?.value}, ${
                                      stateIdToNameMap[event.state?.value] || ""
                                    }`}
                                    startDate={dateDisplay}
                                    onClick={() => {
                                      if (
                                        !cartItems.some(
                                          (cartItem) => cartItem.id === id
                                        ) &&
                                        !noSelectedTicketType()
                                      ) {
                                        handleAdd(id, event, dateDisplay);
                                      }
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {!isCartEmpty && (
                        <div className={styles.cart}>
                          <p className={styles.bold}>Cart</p>

                          <div className={styles.flex}>
                            <p>Event</p>
                            <p>Quantity</p>
                            <p>Type of Ticket</p>
                            <p>Price</p>
                          </div>
                          {cartItems.length > 0 ? (
                            <div>
                              <div>
                                <TransitionGroup component="ul">
                                  {cartItems.map(({ item }, index) => {
                                    const id = item.id;

                                    if (!item) {
                                      return null;
                                    }

                                    return (
                                      <CSSTransition
                                        timeout={300}
                                        classNames={{
                                          enterActive:
                                            styles["fade-enter-active"],
                                          enter: styles["fade-enter"],
                                          exit: styles["fade-exit"],
                                          exitActive:
                                            styles["fade-exit-active"],
                                        }}
                                        key={id}
                                      >
                                        <li
                                          className={`${
                                            index !== cartItems.length - 1 &&
                                            styles.itemDivider
                                          } ${
                                            id === loadingCartItemId &&
                                            cartLoading &&
                                            styles.invisible
                                          }`}
                                        >
                                          {item.ticketWarnings &&
                                            item.ticketWarnings.length > 0 && (
                                              <div>
                                                {item.ticketWarnings
                                                  .filter(
                                                    (warning) => warning.message
                                                  )
                                                  .reduce(
                                                    (
                                                      uniqueWarnings,
                                                      warning
                                                    ) => {
                                                      if (
                                                        warning.id === null ||
                                                        !uniqueWarnings.some(
                                                          (w) =>
                                                            w.id === warning.id
                                                        )
                                                      ) {
                                                        uniqueWarnings.push(
                                                          warning
                                                        );
                                                      }
                                                      return uniqueWarnings;
                                                    },
                                                    []
                                                  )
                                                  .map((warning) => (
                                                    <div
                                                      key={warning.index}
                                                      className={`${
                                                        styles.warningAlerts
                                                      } ${
                                                        styles.warningMargin
                                                      } ${
                                                        warning.message.includes(
                                                          "has"
                                                        ) &&
                                                        warning.message.includes(
                                                          "available"
                                                        )
                                                          ? styles.alertBlue
                                                          : styles.alertError
                                                      }`}
                                                      role="alert"
                                                    >
                                                      <div
                                                        className={
                                                          styles.clearCartWarningContainer
                                                        }
                                                      >
                                                        <div>
                                                          {warning.message}
                                                        </div>
                                                        {warning.message.includes(
                                                          "has a ticket in their cart for this event"
                                                        ) && (
                                                          <button
                                                            onClick={() =>
                                                              handleClearCart(
                                                                id
                                                              )
                                                            }
                                                            className={
                                                              styles.clearCart
                                                            }
                                                          >
                                                            Clear Cart
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ))}
                                              </div>
                                            )}
                                          <CartItem
                                            iboNumber={iboNumber}
                                            event={item}
                                            id={id}
                                            onChange={getTicketInfo}
                                            //onRemove={handleRemoveCartItem}
                                            error={
                                              <span
                                                className={styles.itemError}
                                              >
                                                {cartItemErrors &&
                                                  cartItemErrors[index]
                                                    ?.message}
                                              </span>
                                            }
                                            cartLoading={cartLoading}
                                            setCartLoading={setCartLoading}
                                            // setTicketWarnings={
                                            //   setTicketWarnings
                                            // }
                                            // There is a warnings array for the entire cart.
                                            //setCartWarnings={setCartWarnings}
                                            setSubtotal={setSubtotal}
                                            // setTax={setTax}
                                            setTotal={setTotal}
                                            ctr={{ index: index, id: id }}
                                            creditCards={creditCards}
                                            setCreditCards={setCreditCards}
                                            setShowCreditCards={
                                              setShowCreditCards
                                            }
                                            setShowButtons={setShowButtons}
                                            setDisplayAddToCart={
                                              updateDisplayAddToCart
                                            }
                                            itemTotalPrices={itemTotalPrices}
                                            setItemTotalPrices={
                                              setItemTotalPrices
                                            }
                                            doRefetch={refetchOrderInfoGetData}
                                            updateTicketData={updateTicketData}
                                            updateTicketWarnings={
                                              updateTicketWarnings
                                            }
                                            testTicket={testTicket}
                                            setTestTicket={setTestTicket}
                                          />
                                          <div className={styles.buttonGroup}>
                                            <button
                                              onClick={() =>
                                                handleRemoveCartItem(id)
                                              }
                                              className={styles.remove}
                                            >
                                              Remove
                                            </button>
                                            {item.displayAddToCart &&
                                              !item.ticketWarnings.some(
                                                (warning) =>
                                                  warning.message &&
                                                  warning.message.includes(
                                                    "has a ticket in their cart for this event"
                                                  )
                                              ) && (
                                                <button
                                                  onClick={() =>
                                                    handleAddToCart(id)
                                                  }
                                                  className={styles.addToCart}
                                                >
                                                  Add To IBO Cart
                                                </button>
                                              )}
                                          </div>

                                          {/* {cartItems.length > 1 &&
                                      cartItems[index] !== cartItems.length && (
                                        <div className={styles.itemDivider} />
                                      )} */}
                                          {/* {cartLoading &&
                                      cartItems.length > 1 &&
                                      index === cartItems.length - 1 && (
                                        <Loader />
                                      )} */}
                                        </li>
                                      </CSSTransition>
                                    );
                                  })}
                                  <div
                                    className={
                                      !cartLoading ? styles.invisible : ""
                                    }
                                  >
                                    <Loader />
                                  </div>
                                </TransitionGroup>
                              </div>
                            </div>
                          ) : errorMessage ? (
                            <p className={styles.errorMessage}>
                              {errorMessage}
                            </p>
                          ) : (
                            <p></p>
                          )}
                          {ticketTypeError && (
                            <p className={styles.globalError}>
                              {ticketTypeError}
                            </p>
                          )}
                        </div>
                      )}

                      <div
                        className={classNames(styles.payment, {
                          [styles.hidden]: cartItems.length === 0, //||
                          // !cartItems.some(({ item }) =>
                          //   item.tickets.some((ticket) => ticket.type !== null)
                          // ),
                        })}
                      >
                        <div className={styles.flex}>
                          <div>
                            <p>Payment</p>
                          </div>
                          <div>
                            <p className={styles.total}>Total</p>
                          </div>
                        </div>
                        <div className={styles["credit-total"]}>
                          {showCreditCards && subtotal > 0 && (
                            // ticketTypeExist &&
                            <div
                              className={classNames(
                                styles.flex,
                                styles["credit-card-select"]
                              )}
                            >
                              <label>Credit Card:</label>
                              {creditCards.length == 0 ||
                              creditCards[0].creditCards.length == 0 ? (
                                <div>This IBO has no credit cards on file</div>
                              ) : (
                                <div className={styles["credit-card-option"]}>
                                  <Select
                                    options={
                                      creditCards &&
                                      creditCards[0] &&
                                      creditCards[0].creditCards.map(
                                        (card) =>
                                          `${card.cardType} *************${card.lastFour}`
                                      )
                                    }
                                    onChange={(selectedName) => {
                                      const selectedCard =
                                        creditCards[0].creditCards.find(
                                          (card) =>
                                            `${card.cardType} *************${card.lastFour}` ===
                                            selectedName
                                        );
                                      setSelectedCreditCard({
                                        id: selectedCard.id,
                                        name: selectedName,
                                      });
                                    }}
                                    selectedOption={
                                      selectedCreditCard
                                        ? selectedCreditCard.name
                                        : creditCards &&
                                          creditCards[0] &&
                                          creditCards[0].creditCards
                                            .filter((card) => card.isPrimary)
                                            .map(
                                              (card) =>
                                                `${card.cardType} *************${card.lastFour}`
                                            )[0]
                                    }
                                    placeholder="Select a Card"
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <div
                            className={
                              subtotal > 0
                                ? styles["payment-total-amount"]
                                : styles["subtotal-margin"]
                            }
                          >
                            <div className={styles.amount}>
                              <p className={styles.label}>Subtotal</p>
                              <p className={styles.item}>
                                ${subtotal.toFixed(2)}
                              </p>
                            </div>
                            <div className={styles.amount}>
                              <p className={styles.label}>Tax</p>
                              <p className={styles.item}>${tax.toFixed(2)}</p>
                            </div>
                            <div className={styles.amount}>
                              <p className={styles.label}>Total Due</p>
                              <p className={styles.bold}>
                                ${(subtotal + tax).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={styles["button-cancel-create"]}>
                        <div
                          className={styles["cancelBtn"]}
                          type="button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </div>

                        {showCreateOrderButton && (
                          // ticketTypeExist &&
                          // ticketPriceExist >= 0 &&
                          //   (totalDue > 0 ? currentCard : true) &&
                          <button
                            type="button"
                            onClick={handlePlaceOrder}
                            className={styles["placeOrderBtn"]}
                          >
                            Create Order
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          }
        />
      ) : (
        <Modal
          title="Order Responses"
          isOpen={orderResponses.length > 0}
          onClose={() => {
            setOrderResponses([]);
            onClose();
          }}
          closeBtn="none"
          className={styles.modal}
          children={
            <>
              {orderResponses.map((response, index) => {
                const event = filteredEvents.find(
                  (event) => event.id === response.eventId
                );

                if (!event) {
                  return null;
                }

                const endDate = event.event.dates.eventEndDate?.value
                  ? new Date(event.event.dates.eventEndDate.value)
                  : null;

                const formattedStartDate = formatDateWithTimeZone(
                  event.event.dates.eventStartDate.value,
                  event.event.dates.eventStartTimeZone.value,
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                );

                const formattedEndDate = endDate
                  ? formatDateWithTimeZone(
                      event.event.dates.eventEndDate.value,
                      event.event.dates.eventEndTimeZone.value,
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )
                  : "";

                let dateDisplay;
                if (endDate && formattedStartDate !== formattedEndDate) {
                  dateDisplay = `${formattedStartDate} - ${formattedEndDate}`;
                } else {
                  dateDisplay = formattedStartDate;
                }

                const formattedEvent = {
                  name: event.event.name.value,
                  location:
                    event.event.location.value == "onsite"
                      ? `${event.event.city?.value}, ${
                          stateIdToNameMap[event.event.state?.value]
                        }`
                      : `${event.event.city.value}`,
                  dates: dateDisplay,
                };

                return (
                  <React.Fragment key={index}>
                    <div className={styles.responseRow}>
                      {event && (
                        <div className={styles.event}>
                          <p className={styles.eventName}>
                            {formattedEvent.name}
                          </p>
                          <p>{formattedEvent.location}</p>
                          <p>{formattedEvent.dates}</p>
                        </div>
                      )}
                      <div className={styles.response}>
                        <div
                          className={`${styles.responseAlerts} ${
                            styles.warningMargin
                          } ${
                            response.message.includes("Error")
                              ? styles.responseAlertError
                              : response.message.includes("successfully")
                              ? styles.responseAlertSuccess
                              : styles.responseAlertBlue
                          }`}
                          role="alert"
                        >
                          <div>
                            {response.message}
                            {response.error && ` - ${response.error.message}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    {orderResponses.length > 1 &&
                      orderResponses.length - 1 !== index && (
                        <div className={styles.divider} />
                      )}
                  </React.Fragment>
                );
              })}
              <div className={styles["button-cancel-create"]}>
                <div
                  className={styles["cancelBtn"]}
                  type="button"
                  onClick={() => {
                    setOrderResponses([]);
                    onClose();
                  }}
                >
                  Close
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  );
}

NewOrderModal.propTypes = {
  onClose: PropTypes.func,
  iboNumber: PropTypes.string,
};
