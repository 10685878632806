import React from "react";
import { Ok } from "react-ikonate";
import styles from "./index.module.css";
import classNames from "classnames";

/**
 * A basic HTML checkbox with built in styles.
 */
export default function Checkbox({ className, ...props }) {
  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        className={classNames(styles.checkbox, className)}
        {...props}
      />
      <div className={styles.icon}>
        <Ok />
      </div>
    </div>
  );
}
