import React from "react";
import PropTypes from "prop-types";
import { IoIosCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons";

import styles from "./Tag.module.css";

/**
 * Interactive component that contains tags displayed under filter menus
 */
export function Tag({ title, isBtnLight, onClick }) {
  return (
    <>
      <IconContext.Provider
        value={{
          size: "20px",
          className: isBtnLight
            ? styles.tagLightCancelButton
            : styles.tagDarkCancelButton,
        }}
      >
        <div className={styles.tagContainer}>
          <div className={styles.tagText}>{title}</div>
          <IoIosCloseCircle onClick={() => onClick(title)} />
        </div>
      </IconContext.Provider>
    </>
  );
}

Tag.propTypes = {
  /**
   * The title of the tag
   */
  title: PropTypes.string,
  /**
   * Decides if the X icon is dark or light
   */
  isBtnLight: PropTypes.bool,
  /**
   * Function that deletes the tag
   */
  onClick: PropTypes.func,
};
