import { useMutation, useQuery } from "react-query";
import { Auth } from "shared/@auth/Auth";
import toast from "react-hot-toast";
import createNotification from "shared/@utils/createNotification";

export function useEditEventsPage({ id }) {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(["event", id], () =>
    makeAuthedRequest(`v1/events/id/${id}`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  // const eventEditPut = useMutation((eventObject) =>
  //   makeAuthedRequest(`events/event/update/${id}`, {
  //     method: "PUT",
  //     body: JSON.stringify(eventObject),
  //   }).then(async (res) => {
  //     if (res.ok) {
  //       // toast.success("Successfully Updated");

  //       return;
  //     } else {
  //       const body = await res.json();
  //       // toast.error("Something went wrong!");
  //       throw new Error(body.message);
  //     }
  //   })
  // );

  const eventEditPut = useMutation(
    (eventObject) =>
      makeAuthedRequest(`v1/events/event/update/${id}`, {
        method: "PUT",
        body: JSON.stringify(eventObject),
      }),
    {
      onSuccess: () => {
        createNotification({
          type: "success",
          title: "Saved",
          message: "Successfully Updated",
          autoDismiss: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast.error("Something went wrong!");
      },
    }
  );

  const eventId = {
    eventId: id,
  };

  const eventPublish = useMutation(["publishEventsPut"], () =>
    makeAuthedRequest(`v1/events/event/publish`, {
      method: "PUT",
      body: JSON.stringify(eventId),
    }).then(async (res) => {
      if (res.ok) {
        return;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  // TRY
  // console.log("USE DATA :", data);
  // console.log("USE ID:", eventId);

  return {
    data,
    status,
    isLoading,
    refetchEvent: refetch,
    eventEditPut,
    eventPublish,
  };
}
