import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./TableSortItem.module.css";
import { ChevronDown } from "react-ikonate";
/**
 * Clickable header to change table sort order. Toggles between asc and desc.
 */
export function TableSortItem({ value, title, onChange, isBlank  = false }) {
  return (
    <div className={classNames(styles.container)} onClick={onChange}>
      <div className={styles.text}>{title}</div>
      <div
        className={classNames(styles.arrow, value === "asc" ? styles.flip : "")}
      >
        {isBlank ? (
          <svg width="15" height="2" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="100%" fill="black" />
          </svg>
        ) : (
          <ChevronDown fontSize="22px" />
        )}
      </div>
    </div>
  );
}

TableSortItem.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
