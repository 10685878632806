import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DateRangePickerButton.module.css";
import { ChevronDown } from "react-ikonate";

import { useState, useEffect, useRef } from "react";

import { DateRangePicker } from "shared/DateRangePicker/DateRangePicker";

/**
 * Interactive component, displays date and allows user to edit the date
 */
export function DateRangePickerButton({
  startDateString,
  endDateString,
  setStartDate,
  setEndDate,
  allowPastDate = true,
  allowRangeSelection = true,
  disabled,
}) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [currentStartDate, setCurrentStartDate] = useState(startDateString);
  const [currentEndDate, setCurrentEndDate] = useState(endDateString);

  const [placeholder, setPlaceholder] = useState("Select");

  useEffect(() => {
    //Is usually inverted but that doesn't work correctly. - Updated to pass parent setStates and updateLocal based on parent
    setCurrentStartDate(startDateString);
    setCurrentEndDate(endDateString);
    if (!allowRangeSelection) {
      setPlaceholder(currentStartDate ? currentStartDate : "Select");
    }
  }, [startDateString, endDateString]);

  function toggleDatePicker() {
    if (datePickerOpen) {
      setStartDate(currentStartDate);
      setEndDate(currentEndDate);
    }
    setDatePickerOpen(!datePickerOpen);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDatePickerOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className={styles.container} ref={wrapperRef}>
      <button
        className={`${styles.selectDropdown} ${
          disabled ? styles.nonClickable : ""
        }`}
        onClick={() => toggleDatePicker()}
        // disabled={false}
        disabled={disabled}
      >
        <span>{placeholder}</span>
        <span className={styles.selectChevron}>
          <ChevronDown fontSize="1.4em" />
        </span>
      </button>
      {datePickerOpen && (
        <DateRangePicker
          startDateString={currentStartDate}
          endDateString={currentEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          size={"small"}
          allowPastDate={allowPastDate}
          allowRangeSelection={allowRangeSelection}
        />
      )}
    </div>
  );
}

DateRangePickerButton.propTypes = {
  startDateString: PropTypes.string,
  setStartDate: PropTypes.func,
  endDateString: PropTypes.string,
  setEndDate: PropTypes.func,
};
