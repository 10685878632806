import Card from "shared/Card";
import { Column, LabelGroup, Row, Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import { ZonePicker } from "shared/@forms/DateTimePicker/ZonePicker/ZonePicker";
import { useState } from "react";
import { Event } from "Events/Components/useEvent";
import { descendingBy } from "shared/@utils/sort";
import { RECENT_EVENTS_KEY } from "../../OrdersByIBO/index";
import { useMount, useUnmount } from "react-use";

import styles from "../index.module.css";

function appendToRecents(eventID, name) {
  const recents = JSON.parse(sessionStorage.getItem(RECENT_EVENTS_KEY) || "[]");

  // Keep the latest 9 results.
  let newList = recents.filter((item) => item.eventID !== eventID);
  newList.sort(descendingBy("timestamp"));
  newList = newList.slice(0, 9);

  // Prepend this one.
  newList.unshift({
    eventID,
    name,
    timestamp: new Date(),
  });

  sessionStorage.setItem(RECENT_EVENTS_KEY, JSON.stringify(newList));
}

export default function EventInfo({ eventInfo }) {
  const { saveTimeZone } = Event.useContainer();

  const {
    eventId,
    displayName,
    timeZone,
    city,
    strAbbr,
    // company: { address },
    company: { name: companyName, address },
  } = eventInfo;

  useMount(() => {
    appendToRecents(eventId, displayName);
  });

  useUnmount(() => {
    appendToRecents(eventId, displayName);
  });

  const location = `${city}${strAbbr ? `, ${strAbbr}` : ""}`;

  const [isEditing, setIsEditing] = useState(false);

  const [timezone, setTimezone] = useState(timeZone);

  function onSave() {
    //TODO: pop message about changing time after saving timezone

    saveTimeZone.mutate({ eventId: eventId, timeZone: timezone });
    setIsEditing(false);
  }

  function onCancel() {
    setTimezone(timeZone);
    setIsEditing(false);
  }

  var timezoneDisplayName = "";
  switch (timeZone) {
    case "America/Los_Angeles":
      timezoneDisplayName = "Pacific Time";
      break;
    case "America/Boise":
      timezoneDisplayName = "Mountain Time";
      break;
    case "America/Chicago":
      timezoneDisplayName = "Central Time";
      break;
    case "America/New_York":
      timezoneDisplayName = "Eastern Time";
      break;
    case "America/Anchorage":
      timezoneDisplayName = "Alaska Time";
      break;
    case "America/Adak":
      timezoneDisplayName = "Hawaii-Aleutian Time";
      break;
    case "Pacific/Honolulu":
      timezoneDisplayName = "Hawaii-Honolulu Time";
      break;

    default:
      timezoneDisplayName = "Pacific Time";
      break;
  }

  return (
    <Section>
      <header>
        <h3>Event Info</h3>
        <div className={styles.buttonsColumn}>
          {!isEditing && (
            <Button
              size="small"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <>
              <Button
                size="small"
                purpose={ButtonPurpose.save}
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </header>
      <Row>
        <Column>
          <LabelGroup title="Event Type">{displayName}</LabelGroup>
        </Column>
        <Column>
          <Row>
            <LabelGroup title="Location">{location}</LabelGroup>
          </Row>
          <Row>
            <LabelGroup title="Timezone">
              {!isEditing && timezoneDisplayName}
              {isEditing && (
                <ZonePicker
                  value={timeZone}
                  onChange={(value) => {
                    console.log("ZonePicker onchanged ", value);
                    setTimezone(value);
                  }}
                ></ZonePicker>
              )}
            </LabelGroup>
          </Row>
        </Column>
        <Column>
          <LabelGroup title="Venue">
            {companyName} <br />
            {address && (
              <>
                {address.line1 && (
                  <>
                    <span>{address.line1}</span>
                    <br />
                  </>
                )}
                {address.line2 && (
                  <>
                    <span>{address.line2}</span>
                    <br />
                  </>
                )}
                {address.city}, {address.state} {address.zip}
              </>
            )}
          </LabelGroup>
        </Column>
      </Row>
    </Section>
  );
}
