import React from "react";
import PropTypes from "prop-types";
import styles from "./AddButton.module.css";

/**
 * Custom button
 */

export function AddButton({ description, color, onClick, disabled }) {
  return (
    <div className={styles.container}>
      <div
        onClick={!disabled ? onClick : undefined}
        className={styles.plusSymbolContainer}
        style={{ backgroundColor: color }}
      >
        <div className={styles.horizontalCross} />
        <div className={styles.verticalCross} />
      </div>
      <span className={styles.text} style={{ color: color }}>
        {description}
      </span>
    </div>
  );
}

AddButton.propTypes = {
  description: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};
