import PropTypes from "prop-types";
import styles from "./NotesForm.module.css";
import { useState } from "react";
import Card from "shared/Card";

import cardStyles from "../../V1Components/SharedCardStyles.module.css";

/**
 * Area to fill out notes.
 */

export function NotesForm({
  additionalNotes,
  notesToBePublished,
  setAdditionalNotes,
  setNotesToBePublished,
}) {
  const [additionalNotesLocal, setAdditionalNotesLocal] =
    useState(additionalNotes);
  const [notesToBePublishedLocal, setNotesToBePublishedLocal] =
    useState(notesToBePublished);

  function setAdditionalNotesOnChange(note) {
    setAdditionalNotesLocal(note);
    setAdditionalNotes(note);
  }

  function setNotesToBePublishedOnChange(note) {
    setNotesToBePublishedLocal(note);
    setNotesToBePublished(note);
  }

  return (
    <Card
      children={
        <section>
          <div className={styles.cardHeader}>NOTES</div>

          <hr className={styles.hr} />

          <div className={styles.textAreaLabel}>
            Additional Information (internal)
          </div>

          <textarea
            className={styles.textArea}
            placeholder="Click to type"
            onChange={(e) => setAdditionalNotesOnChange(e.target.value)}
            value={additionalNotesLocal}
          />

          <div className={styles.textAreaLabel}>
            Additional Information (Notes To Be Published To WWG App)
          </div>

          <textarea
            className={styles.textArea}
            placeholder="Click to type"
            onChange={(e) => setNotesToBePublishedOnChange(e.target.value)}
            value={notesToBePublished}
          />
        </section>
      }
    />
  );
}

NotesForm.propTypes = {
  additionalNotes: PropTypes.string,
  setAdditionalNotes: PropTypes.func,

  notesToBePublished: PropTypes.string,
  setNotesToBePublished: PropTypes.func,
};
