import PropTypes from "prop-types";
import styles from "./VenueRow.module.css";
import classNames from "classnames";
import { capitalizeCityName } from "shared/@utils/capitalizeCityName";

/**
 * Displays the information of a past venue as well as an edit button.
 */
export function VenueRow({ Venue, onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.gridItem}>{Venue.ruleset}</div>
      <div className={styles.gridItem}>{Venue.name}</div>
      <div className={classNames(styles.gridItem, styles.noMargin)}>
        {`${capitalizeCityName(Venue.city)}`}
      </div>

      <div className={classNames(styles.gridItem, styles.noMargin)}>
        {Venue.state}
      </div>
      <div className={`${styles.editFlexItem}`}>
        <button className={styles.button} onClick={onClick}>
          Edit
        </button>
      </div>
    </div>
  );
}

VenueRow.propTypes = {
  Venue: PropTypes.object,
  onClick: PropTypes.func,
};
