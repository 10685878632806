import styles from "./EditVenueModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { Modal } from "../../../shared/Modal";
import { RadioButtonList } from "../RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

/**
 *  TODO:
 *   - Replace Inputs with InputItem when merged with develop
 *   - Fix inevitable styling errors when doing so
 *   - add placeholder text when InputItem merged
 *   - change modal import to shared modal when Events/Modal is switched to shared folder
 *   - when InputItem is merged, change value to venue prop data
 */

export function EditVenueModal({
  isOpen,
  onCollapse,
  onClick,
  onDelete,
  venue,
}) {
  const venueToUse = venue ?? {};

  const { data: statesData, isLoading: isLoadingStates } = useCurrentRoute();

  const [newName, setNewName] = useState(venueToUse.name ?? "");
  const [newAddress1, setNewAddress1] = useState(venueToUse.street ?? "");
  const [newAddress2, setNewAddress2] = useState(venueToUse.street2 ?? "");
  const [newCity, setNewCity] = useState(venueToUse.city ?? "");
  const [newState, setNewState] = useState(venueToUse.state ?? "");
  const [newZip, setNewZip] = useState(venueToUse.zip ?? "");
  const [newVenueURL, setNewVenueURL] = useState(venueToUse.venueURL ?? "");
  const [venueList, setVenueList] = useState(venueToUse.rooms ?? []);
  const [contactList, setContactList] = useState(venueToUse.contacts ?? []);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (statesData) {
    }
  }, [statesData]);

  useEffect(() => {
    // Update state variables when the venue prop changes
    setNewName(venueToUse.name ?? "");
    setNewAddress1(venueToUse.street ?? "");
    setNewAddress2(venueToUse.street2 ?? "");
    setNewCity(venueToUse.city ?? "");
    setNewState(venueToUse.state ?? "");
    setNewZip(venueToUse.zip ?? "");
    setNewVenueURL(venueToUse.venueURL ?? "");
    setVenueList(venueToUse.rooms ?? []);
    setContactList(venueToUse.contacts ?? []);
  }, [venue]);

  const newVenue = {
    newName,
    newAddress1,
    newAddress2,
    newCity,
    newState,
    newZip,
    newVenueURL,
    venueList,
    contactList,
  };

  const onVenueRemove = (index) => {
    venueList.length > 1 &&
      setVenueList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    venueList.length === 1 && setVenueList(() => [{ room: "", capacity: "" }]);
  };

  const onVenueAdd = () => {
    setVenueList((arr) => [...arr, { name: "", capacity: "" }]);
  };

  const onContactRemove = (index) => {
    contactList.length > 1 &&
      setContactList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    contactList.length === 1 &&
      setContactList(() => [
        { contactName: "", phoneNumber: "", email: "", type: "" },
      ]);
  };
  const [venuePhone, setVenuePhone] = useState("");
  const [venueFax, setVenueFax] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [venueURL, setVenueURL] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("U.S.");
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };
  // const handleFormSubmittal = () => {
  //   const formData = {
  //     venueName: newName,
  //     address1: newAddress1,
  //     address2: newAddress2,
  //     city: newCity,
  //     state: newState,
  //     zip: newZip,
  //     venueURL: newVenueURL,
  //     venueList: venueList,
  //     contactList: contactList,
  //   };
  //   onClick(formData);
  // };

  return (
    <Modal
      title="EDIT VENUE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Name"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 1"
                  placeholder=""
                  value={newAddress1}
                  onChange={(e) => {
                    setNewAddress1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 2"
                  placeholder=""
                  value={newAddress2}
                  onChange={(e) => {
                    setNewAddress2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country </span>
                <span className={styles.radioButtonList}>
                  <RadioButtonList
                    onClick={handleCountrySelect}
                    options={["U.S.", "CDN", "ANZ", "JAMAICA"]}
                    defaultValue={selectedCountry}
                    groupName="venueCountry"
                  />
                </span>
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="State"
                  placeholder=""
                  value={newState}
                  onChange={(e) => {
                    setNewState(e.target.value);
                  }}
                />
                {/* <Select
                  options={stateOptions}
                  placeholder={newState}
                  onChange={(value) => {
                    setNewState(value);
                  }}
                  className={styles.select}
                /> */}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Zip"
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Phone"
                  placeholder=""
                  value={venuePhone}
                  onChange={(e) => {
                    setVenuePhone(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Fax"
                  placeholder=""
                  value={venueFax}
                  onChange={(e) => {
                    setVenueFax(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Email"
                  placeholder=""
                  value={venueEmail}
                  onChange={(e) => {
                    setVenueEmail(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue URL"
                  placeholder=""
                  value={venueURL}
                  onChange={(e) => {
                    setVenueURL(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              {venueList?.map((room, index) => (
                <div className={styles.inputRow} key={index}>
                  <InputItem
                    label="Room"
                    value={room.room}
                    placeholder=""
                    onChange={(e) => {
                      room.room = e.target.value;
                      setVenueList([...venueList]);
                      console.log(e.target.value);
                    }}
                  />
                  <InputItem
                    label="Capacity"
                    value={room.capacity}
                    placeholder=""
                    onChange={(e) => {
                      room.capacity = e.target.value;
                      setVenueList([...venueList]);
                      console.log(e.target.value);
                    }}
                  />
                  <div className={styles.removeBtn}>
                    <Button
                      className={styles.remove}
                      children="Remove"
                      onClick={() => onVenueRemove(index)}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.button}>
                <AddButton
                  color="#9AB5BB"
                  description="Add Another Room"
                  onClick={onVenueAdd}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <>
                    <Button
                      className={styles.delete}
                      children="ARCHIVE"
                      onClick={onDelete}
                    />
                    <Button
                      className={styles.update}
                      children="UPDATE"
                      onClick={
                        () =>
                          onClick({
                            newName,
                            newAddress1,
                            newAddress2,
                            newCity,
                            newState,
                            newZip,
                            newVenueURL,
                            venueList,
                            contactList,
                          })
                        // handleFormSubmittal()
                      }
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

EditVenueModal.propTypes = {
  // Getter to determine if modal is displayed
  isOpen: PropTypes.bool,

  // Callback function for when the modal is closed
  onCollapse: PropTypes.func,

  // Function called when the save venue btn is clicked
  onClick: PropTypes.func,

  // Function called when the delete btn is clicked
  onDelete: PropTypes.func,

  // Object with the venues info
  venue: PropTypes.shape({
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    rooms: PropTypes.array,
    // rooms: PropTypes.arrayOf(PropTypes.string),
    venueURL: PropTypes.string,
    contacts: PropTypes.array,
  }),
};
