import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./ImportRow.module.css";
import Loader from "shared/Loader";

export function ImportRow({ Import, onImport, importData }) {
  const history = useHistory();
  const [isImporting, setIsImporting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleImportClick = async () => {
    setIsImporting(true);
    const startTime = Date.now();

    try {
      await onImport({
        eventId: Import.eventId,
        eventType: Import.eventTypeId,
      });
      const elapsedTime = Date.now() - startTime;
      const delay = 1000 - elapsedTime;

      setTimeout(
        () => {
          setIsSuccess(true);
          setIsImporting(false);
        },
        delay > 0 ? delay : 0
      );
    } catch (error) {
      console.error("Import error:", error);
      setIsImporting(false);
    }
  };

  //Redirect to view page when imported button clicked
  const handleImportedClick = () => {
    const ruleset = importData?.event?.ruleset?.value;
    const eventId = importData?._id;

    if (ruleset === "Major v1.0") {
      history.push(`/events/view/event/majorv1/${eventId}`);
    } else if (ruleset === "Local v1.0") {
      history.push(`/events/view/event/localv1/${eventId}`);
    } else if (ruleset === "LOS v1.0") {
      history.push(`/events/view/event/losv1/${eventId}`);
    } else {
      history.push("/events/events-details");
    }
  };

  return (
    <div className={styles.flexContainer}>
      <div className={styles.nameFlexItem}>{Import.displayName}</div>
      <div className={styles.locationFlexItem}>{Import.location}</div>
      <div className={styles.startsOnFlexItem}>
        {new Date(Import.startsOn).toLocaleDateString()}
      </div>
      <div className={styles.importFlexItem}>
        {isImporting ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : isSuccess ? (
          <button onClick={handleImportedClick} className={styles.importedBtn}>
            Imported
          </button>
        ) : (
          <button
            onClick={handleImportClick}
            disabled={isImporting}
            className={styles.button}
          >
            Import
          </button>
        )}
      </div>
    </div>
  );
}

ImportRow.propTypes = {
  Import: PropTypes.object.isRequired,
  onImport: PropTypes.func.isRequired,
};
