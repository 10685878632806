import { useCallback, useRef } from "react";
import { useMount, useUnmount } from "react-use";
import { createContainer } from "unstated-next";

export const LEVEL_TRACE = 0;
export const LEVEL_INFO = 1;
export const LEVEL_WARN = 2;
export const LEVEL_ERROR = 3;
export const LEVEL_SILENT = 4;

const cacheKey = "wwg.support.loglevel";

const initialLogLevel = parseInt(
  localStorage.getItem(cacheKey) || LEVEL_TRACE,
  10
);

if (!localStorage.getItem(cacheKey)) {
  localStorage.setItem(cacheKey, initialLogLevel);
}

function useLoggerState() {
  const logLevel = useRef(LEVEL_TRACE);
  const nextId = useRef(0);
  const listeners = useRef([]);

  return {
    logLevel,
    nextId,
    listeners,
  };
}

export const LoggerState = createContainer(useLoggerState);

/**
 * Handles component message and error logging.
 * Can be used by various backends (console, App Insights, in-app notifications, etc.)
 *
 * @param {string} prefix - An optional prefix for console logging (component name or other relevant marker info).
 * @param {Function} onPushMessage - A function to be called with each new message logged.
 */
export function useLogger({ prefix = null, onPushMessage = null } = {}) {
  const { logLevel, nextId, listeners } = LoggerState.useContainer();

  useMount(() => {
    if (onPushMessage) {
      listeners.current.push(onPushMessage);
    }
  });

  useUnmount(() => {
    if (onPushMessage) {
      listeners.current = listeners.current.filter(
        (fn) => fn !== onPushMessage
      );
    }
  });

  const pushMessage = useCallback(
    (message) => {
      // Don't do anything unless the message is above the log level threshold.
      if (message.level < logLevel.current) return;

      const id = nextId;

      message = {
        id,
        prefix,
        ...message,
      };

      nextId.current += 1;

      // Call all the message listeners.
      for (let i = 0; i < listeners.current.length; i++) {
        listeners.current[i](message);
      }

      return id;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prefix]
  );

  const logTrace = useCallback(
    (message, data = {}) =>
      pushMessage({
        level: LEVEL_TRACE,
        message,
        data,
      }),
    [pushMessage]
  );

  const logInfo = useCallback(
    (message, data = {}) =>
      pushMessage({
        level: LEVEL_INFO,
        message,
        data,
      }),
    [pushMessage]
  );

  const logWarning = useCallback(
    (message, data = {}) =>
      pushMessage({
        level: LEVEL_WARN,
        message,
        data,
      }),
    [pushMessage]
  );

  const logError = useCallback(
    (error, data = {}) =>
      pushMessage({
        level: LEVEL_ERROR,
        message: typeof error === "string" ? error : error.message,
        error: typeof error === "string" ? new Error(error) : error,
        data,
      }),
    [pushMessage]
  );

  const setLogLevel = useCallback((level) => {
    if (typeof level === "number") {
      if (level >= LEVEL_TRACE && level <= LEVEL_SILENT) {
        logLevel.current = level;
        localStorage.setItem(cacheKey, level);
      } else {
        throw new Error(
          `Unknown log level. If a number, must be between 0 and 4. Got: ${level}`
        );
      }
    } else if (typeof level === "string") {
      const levelString = level.toLowerCase().trim();
      let levelNumber = 0;

      switch (levelString) {
        case "trace":
          levelNumber = LEVEL_TRACE;
          break;
        case "info":
          levelNumber = LEVEL_INFO;
          break;
        case "warn":
          levelNumber = LEVEL_WARN;
          break;
        case "error":
          levelNumber = LEVEL_ERROR;
          break;
        case "silent":
          levelNumber = LEVEL_SILENT;
          break;
        default:
          throw new Error(
            `Unknown log level. If a string, must be one of (trace, info, warn, error, silent). Got: ${level}`
          );
      }

      logLevel.current = levelNumber;
      localStorage.setItem(cacheKey, levelNumber);
    } else {
      throw new Error(
        `Unknown log level. Must be a number or string. Got: ${level} (${typeof level})`
      );
    }
  }, []);

  return {
    logTrace,
    logInfo,
    logWarning,
    logError,

    setLogLevel,
    logLevel,
  };
}
