import styles from "./index.module.css";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// TODO: Refine the color choices on the various notification types.

/**
 * Notification component that defines the style and layout of on-screen notifications.
 */
export default function Notification({ type, title, message }) {
  return (
    <div className={classNames(styles.container, styles[type])}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.message}>{message}</p>
    </div>
  );
}

Notification.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
};
