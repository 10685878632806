import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import { Select } from "shared/Select/Select";
import VenueInfoCard from "Events/Components/VenueInfoCard";
import Checkbox from "shared/@forms/Checkbox";
import Loader from "shared/Loader";
import styles from "./VenueV1Form.module.css";
import { useManageVenuesPage } from "Events/Pages/ManageVenuesPage/useManageVenuesPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import VenueCard from "Events/Components/VenueCard";

//Area to fill out information about the venue with a map displaying the venue location
export function VenueV1Form({ venueInfo, onVenueChange, pageMode }) {
  const { data: venuesData, isLoading } = useManageVenuesPage();
  const { data: statesData } = useCurrentRoute();

  const [venueNames, setVenueNames] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [noVenue, setNoVenue] = useState(false);
  const [userHasSelected, setUserHasSelected] = useState(false);
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);

  const prevSelectedStateRef = useRef();

  useEffect(() => {
    const savedCompany = venueInfo.venue?.company?.saved?.company;
    const valueCompany = venueInfo.venue?.company?.value?.company;

    if (savedCompany === null) {
      setSelectedState("");
      setSelectedCity("");
      setSelectedVenue(valueCompany);
    } else {
      setSelectedVenue(savedCompany || valueCompany || "");
    }
  }, [venueInfo]);

  const specificRuleset = venueInfo?.event?.ruleset?.value;

  //Filter state & city
  useEffect(() => {
    //Filter venues by specificRuleset
    const filteredVenues = venuesData?.filter(
      (venue) =>
        venue.ruleset === specificRuleset &&
        (venue.companyId || venue.company || venue.roomId)
    );

    //Extract unique states
    const uniqueStateIds = [
      ...new Set(filteredVenues?.map((venue) => venue.stateId)),
    ];
    const stateOptions = uniqueStateIds
      ?.map(
        (stateId) =>
          statesData?.find((state) => state.stateId === stateId)?.state
      )
      ?.filter(Boolean)
      .sort((a, b) => a.localeCompare(b));

    setStates([...stateOptions]);

    // Only reset city and venue if the selected state has changed
    if (
      selectedState !== prevSelectedStateRef.current &&
      selectedVenue === null
    ) {
      setSelectedCity("");
      setSelectedVenue("");
    }

    //Filter cities based on the selected state
    const stateId = statesData?.find(
      (state) => state.state === selectedState
    )?.stateId;
    let cities = filteredVenues
      ?.filter((venue) => venue.stateId === stateId)
      .map((venue) => venue.city.trim().toUpperCase())
      .map((city) => (city === "VANCOUVER WA" ? "VANCOUVER" : city))
      .map((city) =>
        city
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
      );

    const uniqueCities = Array.from(new Set(cities)).sort((a, b) =>
      a.localeCompare(b)
    );
    setCities([...uniqueCities]);

    prevSelectedStateRef.current = selectedState;
  }, [venuesData, specificRuleset, statesData, selectedState]);

  useEffect(() => {
    // Define a method to extract venue and room names safely
    const getVenueAndRoom = (venueInfo) => {
      const company =
        venueInfo.venue?.company?.saved?.company ||
        venueInfo.venue?.company?.value?.company ||
        "";
      const room =
        venueInfo.venue?.room?.saved?.room ||
        venueInfo.venue?.room?.value?.room ||
        "No Room";
      return `${company} (${room})`;
    };

    if (
      !userHasSelected &&
      (venueInfo.venue?.company?.saved?.company ||
        venueInfo.venue?.company?.value?.company ||
        venueInfo.venue?.room?.saved?.room ||
        venueInfo.venue?.room?.value?.room)
    ) {
      const initialVenueAndRoom = getVenueAndRoom(venueInfo);
      setSelectedVenue(initialVenueAndRoom);
    }

    let roomList = [];
    if (selectedState && selectedCity) {
      const stateId = statesData.find(
        (state) => state.state.toLowerCase() === selectedState.toLowerCase()
      )?.stateId;

      const filteredVenues = venuesData.filter(
        (venue) =>
          venue.stateId === stateId &&
          venue.city.trim().toLowerCase() ===
            selectedCity.trim().toLowerCase() &&
          venue.ruleset === specificRuleset
      );

      // Populate roomList with venues that match the filter

      filteredVenues.forEach((venue) => {
        if (venue.rooms.length > 0) {
          venue.rooms.forEach((room) => {
            const roomIdentifier = `${venue.company} (${
              room.room ? room.room : "No Room"
            })`;
            if (!roomList.includes(roomIdentifier)) {
              roomList.push(roomIdentifier);
            }
          });
        } else {
          const roomIdentifier = `${venue.company} (No Room)`;
          if (!roomList.includes(roomIdentifier)) {
            roomList.push(roomIdentifier);
          }
        }
      });

      if (
        !userHasSelected &&
        !filteredVenues.find(
          (venue) => getVenueAndRoom({ venue }) === selectedVenue
        )
      ) {
        setSelectedVenue("Select");
      }
    }

    setVenueNames(roomList);
  }, [
    venueInfo,
    selectedState,
    selectedCity,
    venuesData,
    specificRuleset,
    statesData,
    userHasSelected,
  ]);

  const handleVenueChange = (selectedValue) => {
    const [selectedCompany, selectedRoomWithParens] = selectedValue.split(" (");
    const selectedRoom = selectedRoomWithParens.slice(0, -1);

    const venueData = venuesData.find(
      (venue) => venue.company === selectedCompany
    );

    const payload = {
      venue: {
        company: {
          saved: {
            companyId: venueData.companyId,
            company: venueData.company,
            street1: venueData.street1,
            street2: venueData.street2,
            city: venueData.city,
            stateId: venueData.stateId,
            zip: venueData.zip,
            url: venueData.url,
            phone: venueData.phone,
          },
        },
        room:
          venueData.rooms.length > 0
            ? {
                saved: {
                  roomId: venueData.rooms[0].roomId,
                  room: venueData.rooms[0].room,
                  maxCapacity: venueData.rooms[0].maxCapacity,
                },
              }
            : {},
      },
    };

    onVenueChange(payload);
    setSelectedVenue(selectedValue);
    setUserHasSelected(true);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity("");
    setSelectedVenue("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setSelectedVenue("");
  };

  //Update no venue
  const handleNoVenue = async () => {
    setIsLoadingVenue(true);

    const newValue = !noVenue;
    setNoVenue(newValue);

    if (newValue) {
      // setIsLoadingVenue(true);
      const hasSavedVenue =
        venueInfo.venue &&
        venueInfo.venue.company.saved &&
        (venueInfo.venue.company.saved.companyId ||
          venueInfo.venue.company.saved.company);

      if (!hasSavedVenue) {
        setIsLoadingVenue(false);
        return;
      }

      const resetVenue = {
        venue: {
          company: {
            saved: {
              companyId: null,
              company: null,
              street1: null,
              street2: null,
              city: null,
              stateId: null,
              zip: null,
              url: null,
              phone: null,
            },
          },
          room: {
            saved: {
              roomId: null,
              room: null,
              maxCapacity: null,
            },
          },
        },
      };

      try {
        await onVenueChange(resetVenue);
      } catch (error) {
        console.error("Error resetting venue:", error);
      }
    } else {
      setSelectedState("");
      setSelectedCity("");
      setSelectedVenue("");
    }

    setIsLoadingVenue(false);
  };

  const renderVenueCard = () => {
    const companyPermission = venueInfo?.venue?.company?.permission;

    const permission = pageMode === "view" ? "read" : companyPermission;

    if (pageMode === "edit") {
      return (
        <Card
          children={
            <section>
              <div className={styles.title}>VENUE</div>
              <hr className={styles.hr} />

              {isLoading ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>No Event Venue</span>
                      <span className={styles.checkbox}>
                        <Checkbox onChange={handleNoVenue} checked={noVenue} />
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>Filter</span>
                      <span className={styles.filterDropdown}>
                        <Select
                          placeholder={selectedState || "State"}
                          value={selectedState}
                          options={states}
                          // onChange={(value) => setSelectedState(value)}
                          onChange={(value) => handleStateChange(value)}
                          disabled={noVenue}
                          className={noVenue ? styles.disabled : ""}
                        />
                      </span>

                      <span className={styles.filterDropdown}>
                        <Select
                          placeholder={selectedCity || "City"}
                          value={selectedCity}
                          options={cities}
                          // onChange={setSelectedCity}
                          onChange={(value) => {
                            handleCityChange(value);
                          }}
                          disabled={
                            selectedState === "All" || !selectedState || noVenue
                          }
                          className={!selectedState ? styles.disabled : ""}
                        />
                      </span>
                    </div>
                  </div>

                  {isLoadingVenue && !selectedVenue ? (
                    <div className={styles.loader}>
                      <Loader />
                    </div>
                  ) : (
                    <div className={styles.row}>
                      <div className={styles.leftCol}>
                        <span className={styles.label}>Venue *</span>
                        <span className={styles.venueDropdown}>
                          <Select
                            placeholder={selectedVenue || "Select"}
                            value={selectedVenue}
                            options={venueNames}
                            onChange={(value) => {
                              handleVenueChange(value);
                            }}
                            disabled={
                              (!selectedState && !selectedCity) || noVenue
                            }
                            className={!selectedState ? styles.disabled : ""}
                          />
                        </span>
                      </div>
                    </div>
                  )}

                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label} />

                      {venueInfo &&
                      !noVenue &&
                      selectedVenue &&
                      selectedVenue !== "Select" ? (
                        <div className={styles.infoCard}>
                          <VenueInfoCard venueInfo={venueInfo.venue} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </section>
          }
        />
      );
    }

    if (pageMode === "view") {
      return <VenueCard venueInfo={venueInfo} />;
    }

    if (permission === "hidden") {
      return null;
    }
  };

  return <>{renderVenueCard()}</>;
}

VenueV1Form.propTypes = {
  //Value of state select
  state: PropTypes.string,
  //sets the state select
  setState: PropTypes.func,
  //value of city select
  city: PropTypes.string,
  //sets the city select
  setCity: PropTypes.func,
  //value of venue select
  venue: PropTypes.string,
  //sets the venue select
  setVenue: PropTypes.func,
  //Boolean that decides if there is no event venue
  noVenue: PropTypes.bool,
  //sets noVenue to true/false
  setNoVenue: PropTypes.func,
  //Function called when add new venue is clicked
  addNewVenue: PropTypes.func,
};
