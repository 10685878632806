import React from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";

import InputItem from "Events/Components/InputItem";
import Button from "shared/Button";
import AddButton from "Events/Components/AddButton";
import styles from "./ADAV2Form.module.css";

export function ADAV2Form({ entries, setEntries }) {
  const handleForm = (index, field, e, subIndex) => {
    if (field === "question") {
      let newFormValues = [...entries];
      newFormValues[index][field] = e.target.value;
      setEntries(newFormValues);
    }
    if (field === "options") {
      let newFormValues = [...entries];
      newFormValues[index][field][subIndex] = e.target.value;
      setEntries(newFormValues);
    }
  };

  const addQuestion = () => {
    const newEntries = [...entries];
    newEntries.push({
      question: "",
      options: [""],
      id: entries.length + 1,
    });
    setEntries(newEntries);
  };

  const addOption = (id) => {
    const newEntries = entries.map((entry) => {
      if (entry.id === id) {
        return { ...entry, options: [...entry.options, ""] };
      } else return entry;
    });
    setEntries(newEntries);
  };

  const removeQuestion = (index) => {
    let newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  const removeOption = (index, subIndex) => {
    let newOptions = [...entries];
    newOptions[index].options.splice(subIndex, 1);
    setEntries(newOptions);
  };

  return (
    <Card
      children={
        <section className={styles.container}>
          <div className={styles.title}>ADA</div>
          <hr className={styles.hr} />
          {entries.map((entry, index) => (
            <div key={index}>
              <div key={index}>
                <div className={styles.primaryInputItem}>
                  <InputItem
                    label="ADA Question"
                    onChange={(e) => handleForm(index, "question", e)}
                    value={entry.question || ""}
                  />
                  <span />
                  <Button
                    children="Remove"
                    className={styles.removeButton}
                    onClick={() => removeQuestion(index)}
                    size="small"
                  />
                </div>
                {entry.options.map((option, optionIndex) => (
                  <div className={styles.secondaryInputItem} key={optionIndex}>
                    <span />
                    <InputItem
                      label={`Option ${optionIndex + 1}`}
                      onChange={(e) =>
                        handleForm(index, "options", e, optionIndex)
                      }
                      value={option || ""}
                    />
                    <span />
                    <Button
                      children="Remove"
                      className={styles.removeButton}
                      onClick={() => removeOption(index, optionIndex)}
                      size="small"
                    />
                  </div>
                ))}
                <div className={styles.secondaryInputItem}>
                  <span />
                  <AddButton
                    description="Add another Option"
                    color="#9AB5BB"
                    onClick={() => addOption(entry.id)}
                  />
                </div>
              </div>
              <hr className={styles.hr} />
            </div>
          ))}
          <AddButton
            description="Add another ADA question"
            color="#9AB5BB"
            onClick={() => addQuestion()}
          />
        </section>
      }
    />
  );
}

ADAV2Form.propTypes = {
  /**Array of objects in the format:
   * [
   *    {
   *        question: "do you require x y z?",
   *        options: ["yes", "no", "etc"],
   *        id: 1 (unique id per question),
   *    }, and so on
   * ]
   */
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  /**Function to handle setting the entries */
  setEntries: PropTypes.func,
};
