import React from "react";
import PropTypes from "prop-types";
import styles from "./SessionViewInfoCard.module.css";
import InfoItem from "Events/Components/InfoItem";
import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import Card from "shared/Card";

/**
 *  Displays info about a session and is used on the manage invitees page
 */
export function SessionViewInfoCard({ session }) {
  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.cardHeader}>SESSION</div>

        <div className={styles.hr}></div>

        <InfoItem label={"Visibility"} description={session.visibility} />
        <InfoItem label={"Session Name"} description={session.name} />
        <InfoItem label={"Description"} description={session.description} />
        <InfoItem label={"Speaker Name"} description={session.speakerName} />
        <InfoItem label={"Location"} description={session.location} />
        <InfoItem label={"Capacity"} description={session.capacity} />
        <InfoItem label={"Start Date"} description={session.startDate} />
      </div>
    </>
  );

  return <Card children={cardContents} />;
}

SessionViewInfoCard.propTypes = {
  session: PropTypes.object,
};
