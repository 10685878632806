import { useState, useMemo } from "react";

import { Section } from "shared/layout";
import Button, { ButtonPurpose, ButtonSize } from "shared/Button";
import TextInput from "shared/@forms/TextInput";
import Table from "shared/Table";
import DateTimePicker from "shared/@forms/DateTimePicker";

import styles from "../index.module.css";
import { Event } from "Events/Components/useEvent";

export default function Session({ eventId, scanningSessions, formatDateTime }) {
  const { saveSession, deleteSession } = Event.useContainer();
  const [editingId, setEditingId] = useState(null);
  const [tempAdd, setTempAdd] = useState(null);

  function onCancel() {
    setEditingId(null);
    setTempAdd(null);
  }

  function onAddClick() {
    setTempAdd({ id: 0 });
  }

  function onDelete(id) {
    deleteSession.mutate(id);
  }

  function onSave(temp) {
    if (!temp.description || temp.description.length == 0) {
      return;
    }

    temp.eventId = eventId;

    //save local time as is, and display it back as is. Timezone is for end user or IBO apps display
    //Don't need StartsOn right now
    //console.log("session temp", temp)

    saveSession.mutate(temp);
    onCancel(); //clear form
  }

  return (
    <>
      <Section className={styles.sectionTopMargin}>
        <header>
          <h3>Scanning Sessions</h3>
          <div>
            <Button
              size="small"
              onClick={() => {
                onAddClick();
              }}
            >
              Add
            </Button>
          </div>
        </header>
        <Table>
          <thead>
            <tr>
              <th>Edit</th>
              <th>Description</th>
              <th>Total Scans</th>
              <th>Unique Scans</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {scanningSessions &&
              scanningSessions.length > 0 &&
              scanningSessions
                .sort((a, b) => a.id - b.id)
                .map((a) => {
                  if (editingId != a.id)
                    return (
                      <tr key={a.id}>
                        <td width="25px">
                          <Button
                            size="small"
                            onClick={() => setEditingId(a.id)}
                          >
                            Edit
                          </Button>
                        </td>
                        <td>{a.description}</td>
                        <td>{a.total}</td>
                        <td>{a.uniqueTotal}</td>
                        <td className={styles.buttonsColumn}>
                          {a.total === 0 && (
                            <Button
                              size={ButtonSize.small}
                              purpose={ButtonPurpose.delete}
                              onClick={() => onDelete(a.id)}
                            >
                              Remove
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  else
                    return (
                      <SessionEdit
                        session={scanningSessions.find(
                          (s) => s.id == editingId
                        )}
                        onCancel={onCancel}
                        onSave={onSave}
                      ></SessionEdit>
                    );
                })}
            {tempAdd != null && (
              <SessionEdit
                session={tempAdd}
                onCancel={onCancel}
                onSave={onSave}
              ></SessionEdit>
            )}
          </tbody>
        </Table>
      </Section>
    </>
  );
}

function SessionEdit({ session, onCancel, onSave }) {
  const [temp, setTemp] = useState(session);

  return (
    <tr key={session.id}>
      <td width="25px">&nbsp;</td>
      <td className={styles.buttonsColumn} colSpan={3}>
        <TextInput
          placeholder="Description"
          value={temp.description}
          onChange={(e) => setTemp({ ...temp, description: e.target.value })}
        ></TextInput>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.save}
          onClick={() => onSave(temp)}
        >
          Save
        </Button>
        <Button
          size={ButtonSize.small}
          purpose={ButtonPurpose.generic}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </td>
    </tr>
  );
}
