import PropTypes from "prop-types";
import styles from "./HostsForm.module.css";
import { useState } from "react";
import Card from "shared/Card";
import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import HostCard from "Events/Components/HostCard";
import AddButton from "Events/Components/AddButton";
import Button from "shared/Button";
import { useSessionStorage } from "shared/@hooks/useSessionStorage";
import AddSpeakerModal from "Events/Components/AddSpeakerModal";
import DeleteModal from "Events/Components/DeleteModal";

/**
 * Form to add/delete hosts.
 */

export function HostsForm({ event }) {
  const [hostsAdded, setHostsAdded] = useSessionStorage("hosts", [
    {
      iboNum: "00000000",
      name: "First Last",
      username: "username",
      phoneNumber: "000-000-0000",
      email: "email@address.com",
      location: "location",
      lastScheduled: "02/22/2022",
      pinLevel: "Diamond",
    },
  ]);
  const [showSpeakerModal, changeShowSpeakerModal] = useState(false);
  const [btnOpened, setBtnOpened] = useState(false);

  // console.log(hostsAdded);

  const addBtnOnClick = () => {
    setBtnOpened(true);
  };

  const onRemove = (index) => {
    const newArray = [...hostsAdded];

    newArray.splice(index, 1);

    if (newArray.length >= 1) {
      setHostsAdded(newArray);
      changeShowRemoveModal(false);
    }
  };

  const onAddSpeaker = (speakers) => {
    changeShowSpeakerModal(false);
    handleHostsForm(speakers);
  };

  const handleHostsForm = (e) => {
    e.map((host) => {
      const newArray = hostsAdded;

      newArray.push(host);

      setHostsAdded(newArray);
    });
  };

  const [showRemoveModal, changeShowRemoveModal] = useState(false);

  return (
    <Card>
      <AddSpeakerModal
        // speakers={speakers}
        isOpen={showSpeakerModal}
        onCollapse={() => changeShowSpeakerModal(false)}
        onClick={(selection) => onAddSpeaker(selection)}
      />
      <div className={styles.title}>EVENT HOSTS</div>

      <hr className={styles.hr} />

      {hostsAdded.map((host, index) => {
        return (
          <>
            <div className={styles.grid} key={index}>
              <span className={styles.label}> Host </span>
              <div className={styles.hostCard}>
                <HostCard host={host} />
              </div>
              <div>
                <Button
                  className={styles.removeButton}
                  children="Remove"
                  size="small"
                  onClick={
                    hostsAdded.length >= 2
                      ? () => changeShowRemoveModal(true)
                      : ""
                  }
                />
              </div>
            </div>
            <hr className={styles.hr} />
            <DeleteModal
              header={"REMOVE HOST"}
              description={
                "Confirm that you would like to remove " +
                host.name +
                " as a host of [Event Name]"
              }
              isOpen={showRemoveModal}
              onCollapse={() => changeShowRemoveModal(false)}
              onClick={() => onRemove(index)}
              btnName={"REMOVE"}
            >
              <div className={styles.marginTop}></div>
              <HostCard host={host} />
            </DeleteModal>
          </>
        );
      })}
      {btnOpened ? (
        <div className={styles.container}>
          <div className={styles.add}>
            <div className={styles.btn}>
              <span className={styles.label}> Host </span>
            </div>
            <div className={styles.addBtn}>
              <AddButton
                color="#2A3847"
                description="Add Host"
                onClick={() => changeShowSpeakerModal(true)}
              />
            </div>
            <div className={styles.remove}>
              <Button
                className={styles.removeButton}
                children="Remove"
                size="small"
                onClick={() => setBtnOpened(false)}
              />
            </div>
          </div>
          <hr className={styles.hr} />
        </div>
      ) : (
        ""
      )}

      <AddButton
        color="#9AB5BB"
        description="Add Another Host"
        onClick={() => addBtnOnClick()}
      />
    </Card>
  );
}

HostsForm.propTypes = {
  hosts: PropTypes.arrayOf(
    PropTypes.shape({
      iboNum: PropTypes.string,
      name: PropTypes.string,
      username: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      location: PropTypes.string,
    })
  ),
  onRemove: PropTypes.func,
};
