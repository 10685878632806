import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import FilterVenues from "../../Components/FilterVenues";
import CreateNewCard from "Events/Components/CreateNewCard";
import CreateNewVenueModal from "Events/Components/CreateNewVenueModal";
import VenueList from "Events/Components/VenueList";
import Loader from "shared/Loader";
import PagingControls from "shared/PagingControls";
import styles from "./ManageVenuesPage.module.css";
import { useManageVenuesPage } from "./useManageVenuesPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { capitalizeCityName } from "shared/@utils/capitalizeCityName";

// A page under events page in options to manage add and edit the venues.
export function ManageVenuesPage() {
  const {
    data: states,
    isLoading: isLoadingStates,
    venueOptionsData,
    isVenueOptionsLoading,
  } = useCurrentRoute();

  const [venueParams, setVenueParams] = useState({
    ruleset: "all",
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    page: 1,
    limit: 15,
  });
  const { data, isLoading, refetch } = useManageVenuesPage(venueParams);

  const [fetchedVenues, setFetchedVenues] = useState([]);
  const [filters, setFilters] = useState({
    ruleset: "all",
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    limit: 15,
    lastPage: 32,
    page: 1,
    total: 473,
  });
  const [doRefetch, setDoRefetch] = useState(false);

  useEffect(() => {
    if (data) {
      setFetchedVenues(data.data);
      setFilters(data.filters);
    }
  });

  useEffect(() => {
    if (doRefetch) {
      refetch().then(() => setDoRefetch(false));
    }
  }, [venueParams]);

  const changeParams = (paramToChange, changedData) => {
    setVenueParams((prevParams) => ({
      ...prevParams,
      [paramToChange]: changedData,
    }));
    setDoRefetch(true);
  };

  const [stateIdToNameMap, setStateIdToNameMap] = useState({});
  const [stateNameToIdMap, setStateNameToIdMap] = useState({});
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [originalVenues, setOriginalVenues] = useState([]);
  const [venues, setVenues] = useState([]);
  const [selectOptions, setSelectOptions] = useState({});
  const [rulesetOptions, setRulesetOptions] = useState([]);
  const [sort, setSort] = useState("");

  useEffect(() => {
    if (sort && filters.sort !== sort) {
      changeParams("sort", sort);
    }
  }, [sort]);

  useEffect(() => {
    if (filters.sort) {
      setSort(filters.sort)
    }
  }, [filters]);

  useEffect(() => {
    if (venueOptionsData && states) {
      const newStateIdToNameMap = states.reduce((acc, state) => {
        acc[state.stateId.toString()] = state.state;
        return acc;
      }, {});

      const newStateNameToIdMap = states.reduce((acc, state) => {
        acc[state.state] = state.stateId.toString();
        return acc;
      }, {});

      setStateIdToNameMap(newStateIdToNameMap);
      setStateNameToIdMap(newStateNameToIdMap);

      let statesAndCities = {};

      // Process the locations from venueOptionsData
      venueOptionsData.locations.forEach((location) => {
        const stateName =
          newStateIdToNameMap[location.stateId] || "Unknown State";

        // Initialize the state if it doesn't exist, with "All"
        if (!statesAndCities[stateName]) {
          statesAndCities[stateName] = ["All"];
        }

        const formattedCity = capitalizeCityName(location.city);

        // Add city to the state if it's not already included
        if (!statesAndCities[stateName].includes(formattedCity)) {
          statesAndCities[stateName].push(formattedCity);
        }
      });

      // Ensure all cities lists are sorted, keeping "All" at the start
      Object.keys(statesAndCities).forEach((state) => {
        const cities = statesAndCities[state]
          .filter((city) => city !== "All")
          .sort();
        statesAndCities[state] = ["All", ...cities]; // Ensure "All" remains at the start
      });

      // Set ruleset options and select options
      setRulesetOptions([...new Set(venueOptionsData.rulesets)].sort());
      setSelectOptions(statesAndCities);
    }
  }, [venueOptionsData, states]);

  useEffect(() => {
    if (!isLoadingStates && states && !isLoading && fetchedVenues) {
      const transformedAndFilteredVenues = fetchedVenues
        .filter((venue) => venue.company)
        .map((venue) => ({
          name: venue.company,
          street: venue.street1,
          city: venue.city,
          state: venue.stateId.toString(),
          zip: venue.zip,
          rooms: venue.rooms.map((room) => ({
            room: room.room,
            capacity: room.maxCapacity?.toString(),
          })),
          ruleset: venue.ruleset,
        }));

      setOriginalVenues(transformedAndFilteredVenues);
      setVenues(transformedAndFilteredVenues);
    }
  }, [fetchedVenues, isLoading, states, isLoadingStates]);

  const handleCreateNew = () => {
    setShowCreateNew(true);
  };

  const addNewVenue = (formData) => {
    const newVenue = { ...formData, state: formData.state.toString() };
    setOriginalVenues((prev) => [...prev, newVenue]);
    setVenues((prev) => [...prev, newVenue]);
    setShowCreateNew(false);
  };

  const handleFiltering = (filter) => {
    if (filter.state && filter.state !== "All") {
      const stateId = stateNameToIdMap[filter.state];
      if (!isNaN(stateId)) {
        changeParams("stateId", stateId);
      } else {
        changeParams("stateId", "all");
      }
    } else{
      changeParams("stateId", "all");
    }

    if (filter.city && filter.city !== "All") {
      changeParams("city", filter.city);
    } else {
      changeParams("city", "all");
    }

    const ruleset = filter.ruleset && filter.ruleset.toLowerCase();
    if (ruleset.includes("major")) {
      changeParams("ruleset", "major");
    } else if (ruleset.includes("local")) {
      changeParams("ruleset", "local");
    } else {
      changeParams("ruleset", "all");
    }

    if (filter.search && filter.search.trim() !== "") {
      changeParams("search", filter.search.trim());
    } else {
      changeParams("search", "none");
    }

    setDoRefetch(true);
  };

  return (
    <div className={styles.container}>
      {isLoading || isLoadingStates ? (
        <>
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
            <div className={styles.loaderText}>Loading Venues </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <div>
              <span className={styles.backButton}>
                <Link to="/events/events-details">{"< Back"}</Link>
              </span>
              <h1 className={styles.pageTitle}>MANAGE VENUES</h1>
            </div>
            <div className={styles.venueControls}>
              <FilterVenues
                onClick={handleFiltering}
                locations={selectOptions}
                rulesetOptions={rulesetOptions}
                filters={filters}
                stateIdToNameMap={stateIdToNameMap}
              />

              <span className={styles.createNewCard}>
                <CreateNewCard
                  title="ADD VENUE"
                  onClick={() => handleCreateNew()}
                />
              </span>
            </div>
          </div>

          {isLoading || isLoadingStates ? (
            <Loader />
          ) : (
            <div className={styles.venueListContainer}>
              <PagingControls
                page={filters.page}
                lastPage={filters.lastPage}
                limit={filters.limit}
                limitOptions={[15, 25, 50]}
                onPageChange={(value) => changeParams("page", value)}
                onLimitChange={(value) => changeParams("limit", value)}
                disableNext={filters.page == filters.lastPage}
                disabled={isLoading}
              />
              <div className={styles.venueList}>
                <VenueList venueList={venues} sort={sort} setSort={setSort} />
              </div>
            </div>
          )}
        </>
      )}

      <div>
        <CreateNewVenueModal
          isOpen={showCreateNew}
          onCollapse={() => setShowCreateNew(false)}
          onClick={addNewVenue}
        />
      </div>
    </div>
  );
}
