import React, { useState } from "react";
import { Auth } from "shared/@auth/Auth";
import { useMount, useUnmount } from "react-use";
import TabActionBar from "shared/TabActionBar";
import Button from "shared/Button";
import styles from "./index.module.css";
import formatDate from "shared/@utils/formatDate";
import { descendingBy } from "shared/@utils/sort";
import { RECENT_EVENTS_IBOS_KEY } from "../OrdersByIBO/index";

import { Orders } from "../OrdersByIBO/useOrders";
import { ENVIRONMENT_CONFIG } from "shared/globals";
import RefundOrderConfirmationModal from "Events/Components/RefundOrderConfirmationModal";
import RefundConfirmationModal from "Events/Components/RefundConfirmationModal";
import SpecialNeedsModal from "Events/Components/SpecialNeedsModal";
import { useTicketSpecialNeeds } from "./useTicketSpecialNeeds"; // Adjust the import path as needed
import Loader from "shared/Loader";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons";
import { useRefundOrder } from "./useRefundOrder";
import { useRefundTicket } from "./useRefundTicket";
const { coreApiUrl } = ENVIRONMENT_CONFIG;

function appendToRecents(iboNumber, name) {
  const recentEventIbos = JSON.parse(
    sessionStorage.getItem(RECENT_EVENTS_IBOS_KEY) || "[]"
  );

  // Keep the latest 9 results.
  let newList = recentEventIbos.filter((item) => item.iboNumber !== iboNumber);
  newList.sort(descendingBy("timestamp"));
  newList = newList.slice(0, 9);

  // Prepend this one.
  newList.unshift({
    iboNumber,
    name,
    timestamp: new Date(),
  });

  sessionStorage.setItem(RECENT_EVENTS_IBOS_KEY, JSON.stringify(newList));
}

export default function Order({ iboInfo, order }) {
  const { refreshIBOInfo } = Orders.useContainer();
  const [applyFee, setApplyFee] = useState(false);
  const updateTicketAdaValue = (ticketId, newAdaValue) => {
    const updatedOrderData = {
      ...orderData,
      events: orderData.events.map((event) => ({
        ...event,
        tickets: event.tickets.map((ticket) =>
          ticket.ticketId === ticketId
            ? { ...ticket, adaValue: newAdaValue }
            : ticket
        ),
      })),
    };

    setOrderData(updatedOrderData);
  };
  const { iboNumber, displayName } = iboInfo;

  const { hasPermission } = Auth.useContainer();

  useMount(() => {
    appendToRecents(iboNumber, displayName);
  });

  useUnmount(() => {
    appendToRecents(iboNumber, displayName);
  });

  const { revokeOrder, orders } = Orders.useContainer();
  const { orderInfo, events, payments } = order;
  const users = orders.users;

  const [isOpen, setIsOpen] = useState(false);

  function onRevokeOrder() {
    let orderToRevoke = {
      orderId: orderInfo.orderId,
    };

    revokeOrder.mutate(orderToRevoke);
    orderInfo.status = "REVOKED";

    events.forEach((event) => {
      event.tickets.forEach((ticket) => {
        if (ticket.statusId === 1) {
          ticket.statusId = 4;
        }
      });
    });
  }

  const currentYear = formatDate(new Date(), "YY");
  const createdOnYear = formatDate(orderInfo.createdOn, "YY");

  let canRevoke =
    currentYear === createdOnYear && orderInfo.status === "COMPLETED";
  const [isLoading, setIsLoading] = useState(false);
  const [refundCompleted, setRefundCompleted] = useState(false);
  const [refundMessage, setRefundMessage] = useState("");
  const [refundMessageType, setRefundMessageType] = useState("success"); // 'error' as another possible value
  const { mutateAsync: refundOrder } = useRefundOrder();

  const onRefundOrder = async (applyFee) => {
    setIsLoading(true);

    try {
      let refundAmount = 0;
      order.events[0].tickets.forEach((ticket) => {
        if (ticket.statusId === 1) {
          refundAmount += ticket.cost + ticket.tax;
        }
      });

      let tickets = [];
      order.events[0].tickets.forEach((ticket) => {
        if (ticket.statusId === 1) {
          tickets.push(ticket.ticketId);
        }
      });

      const { message, status } = await refundOrder({
        iboNumber: iboInfo.iboNumber,
        orderId: orderInfo.orderId,
        year: orderInfo.year,
        amountPaid: order.payments[0].amount,
        amountRefunding: refundAmount,
        ccId: order.payments[0].ccId,
        ticketIds: tickets,
        addFee: applyFee,
      });

      if (status === 204) {
        setRefundMessage(message);
        setRefundMessageType("success");
      } else {
        setRefundMessage(message);
        setRefundMessageType("error");
      }
      setRefundCompleted(true);
    } catch (error) {
      setRefundMessage("An unexpected error occurred.");
      setRefundMessageType("error");
    } finally {
      await refreshIBOInfo();
      setIsLoading(false);
    }
  };
  const [orderData, setOrderData] = useState(order);
  // useEffect(() => {
  //   // This effect will run whenever the `order` prop changes.
  //   setOrderData(order);
  // }, [order]);
  const handleCloseModal = () => {
    setIsOpen(false);
    if (refundMessage) {
      // window.location.reload();
    }
  };

  return (
    <>
      <div className={styles.sectionTopMargin}>
        <TabActionBar
          style={{
            justifyContent: "space-between",
          }}
        >
          <div className={styles.headerText}>
            {orderInfo.eventName} {orderInfo.year}
          </div>
          {canRevoke && (
            <div className={styles.headerButtonGroup}>
              <Button
                onClick={() => {
                  onRevokeOrder();
                }}
              >
                Revoke
              </Button>{" "}
              {hasPermission("developer.*") && (
                // <div className={styles.refundContainer}>
                <Button
                  className={styles["refund-button"]}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Refund Order
                </Button>
                // </div>
              )}
            </div>
          )}
        </TabActionBar>
        <div className={styles.containerOrderConf}>
          <div className={styles.orderConf}>
            <span className={styles.orderConfTitle}>Order Confirmation</span>
            <span className={styles.confirmationCode}>
              Confirmation Code: {iboNumber}-{orderInfo.orderId}
            </span>
            <span className={styles.purchaseDate}>
              Purchased: {formatDate(orderInfo.createdOn)}
            </span>
            <span>Order Status: {orderInfo.status}</span>
          </div>
        </div>
        <div className="padding-top border-bottom"></div>
        {events &&
          events.map((event) => {
            return (
              <div className={styles.containerTicketSum}>
                <div className={styles.ticketSummary}>
                  <div className={styles.containerEvent}>
                    <div className={styles.containereventId}>
                      {/* Event Name */}
                      <span className={styles.eventId}>
                        {event.city}, {event.state}
                      </span>
                    </div>
                    <div className={styles.containerstartsOn}>
                      {/* Start Date */}
                      <span className={styles.startsOn}>
                        {formatDate(event.startsOn, "MM.DD.YY")}
                      </span>
                    </div>
                    <div className={styles.containerDateSeparator}>
                      {/* Date Seperator */}
                      <span className={styles.dateSeparator}>
                        &nbsp;-&nbsp;
                      </span>
                    </div>
                    <div className={styles.containerendsOn}>
                      {/* End Date */}
                      <span className={styles.endsOn}>
                        {formatDate(event.endsOn, "MM.DD.YY")}
                      </span>
                    </div>
                  </div>
                  <div className={styles.containerTickets}>
                    {event.tickets.map((ticket) => {
                      let ticketColor = styles.ticketActive;
                      if (ticket.statusId === 2 || ticket.statusId === 3) {
                        ticketColor = styles.ticketRefunded;
                      } else if (ticket.statusId === 4) {
                        ticketColor = styles.ticketRevoked;
                      }

                      return (
                        <div
                          key={ticket.ticketId}
                          className={styles.ticket + " " + ticketColor}
                        >
                          <div className={styles.left}>
                            {/* Ticket > ID */}
                            <div className={styles.ticketId}>
                              Ticket #{ticket.ticketId}
                            </div>
                            {/* Ticket > Price */}
                            <div className={styles.ticketPrice}>
                              ${ticket.cost.toFixed(2)}{" "}
                              {ticket.tax !== 0
                                ? `+ $${ticket.tax.toFixed(2)} Tax`
                                : ""}
                            </div>
                            {/* Ticket Type */}
                            <div className={styles.ticketType}>
                              {ticket.priceTypeId === 5 ? "Fee" : ""}
                              {ticket.priceType}
                              {ticket.statusId === 2 && (
                                <span> *** REFUNDED *** </span>
                              )}
                              {ticket.statusId === 3 && (
                                <span> *** CANCELLED *** </span>
                              )}
                              {ticket.statusId === 4 && (
                                <span> *** REVOKED *** </span>
                              )}
                            </div>
                          </div>
                          <div className={styles.right}>
                            {ticket.statusId === 1 &&
                              ticket.priceTypeId !== 5 && (
                                <CheckInPrint
                                  ticket={ticket}
                                  order={order}
                                  iboInfo={iboInfo}
                                ></CheckInPrint>
                              )}
                            {/* <span></span> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        <div className="padding-top border-bottom"></div>
        {payments.map((payment, ctr) => {
          return (
            <div className={styles.containerPaymentConf}>
              <div className={styles.paymentConf}>
                <span className={styles.paymentConfTitle}>
                  Transaction #{ctr + 1}
                </span>
                <span className={styles.paymentConfTitle}>
                  {payment.nickname}
                </span>
                <span className={styles.amountPaid}>
                  Amount Paid:{" "}
                  <span className={styles.amountPaidValue}>
                    ${payment.amount.toFixed(2)}
                  </span>
                </span>
                <span className={styles.paymentMethod}>
                  {payment.cardDisplay}
                </span>
                <span className={styles.paymentMethod}>
                  Status: {payment.status}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="padding-top border-bottom"></div>

      <RefundOrderConfirmationModal
        isOpen={isOpen}
        onCollapse={() => {
          setIsOpen(!isOpen);
        }}
        onClose={handleCloseModal}
        onClick={onRefundOrder}
        isLoading={isLoading}
        refundCompleted={refundCompleted}
        applyFee={applyFee}
        setApplyFee={setApplyFee}
        refundMessage={refundMessage}
        refundMessageType={refundMessageType}
      />
    </>
  );
}

function CheckInPrint({ ticket, order, iboInfo }) {
  const { orders, checkIn, uncheckTicket, refreshIBOInfo } =
    Orders.useContainer();
  const [contactId, setContactId] = useState(ticket.contactId);
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [showADAModal, setShowADAModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { hasPermission } = Auth.useContainer();
  const [refundCompleted, setRefundCompleted] = useState(false);
  const [refundMessage, setRefundMessage] = useState("");
  const [refundMessageType, setRefundMessageType] = useState("success");
  const [applyFee, setApplyFee] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const users = orders.users;
  const { mutateAsync: refundTicket } = useRefundTicket();

  const tickets = orders.orders
    .filter((a) => a.orderInfo.orderId == order.orderInfo.orderId)
    .flatMap((a) => a.events)
    .filter((a) => a.eventId == ticket.eventId)
    .flatMap((a) => a.tickets);

  function onCheckInClicked(u) {
    checkIn
      .mutateAsync({
        ticketId: ticket.ticketId,
        contactId: u.userId,
      })
      .then(() => {
        setContactId(u.userId);
        setIsCheckingIn(false);
      });
  }

  function shouldShowContact(u) {
    return (
      tickets
        .filter((a) => a.contactId != null)
        .map((a) => a.contactId)
        .indexOf(u.userId) < 0
    );
  }

  function printTicket() {
    let base64 = new Buffer(ticket.token).toString("base64");
    let url = coreApiUrl + `/v1/event/print/ticket/${base64}`;
    window.open(url, "_self");
  }

  function onUncheckTicketClicked() {
    uncheckTicket
      .mutateAsync({
        ticketId: ticket.ticketId,
        contactId: null,
      })
      .then(() => {
        setContactId(null);
      });
  }

  function checkedInName(t) {
    const ticket = orders.orders
      .filter((a) => a.orderInfo.orderId == order.orderInfo.orderId)
      .flatMap((a) => a.events)
      .filter((a) => a.eventId == t.eventId)
      .flatMap((a) => a.tickets)
      .find((a) => a.ticketId == t.ticketId);
    let u = ticket.contactId
      ? users.find((u) => u.userId == ticket.contactId)
      : null;
    if (u) {
      return u.strFirst + " " + u.strLast;
    }
    return "";
  }
  // const onRefundConfirm = () => {
  //   alert(`${ticket.ticketId} Ticket Refunded`);
  //   setIsModalOpen(!isModalOpen);
  // };
  const onRefundTicket = async (applyFee) => {
    setIsLoading(true);

    try {
      const { message, status } = await refundTicket({
        iboNumber: iboInfo.iboNumber,
        orderId: order.orderInfo.orderId,
        year: order.orderInfo.year,
        amountPaid: order.payments[0].amount,
        ccId: order.payments[0].ccId,
        ticketId: ticket.ticketId,
        addFee: applyFee,
        amountRefunding: ticket.cost + ticket.tax,
      });

      if (status === 204) {
        setRefundMessage(message);
        setRefundMessageType("success");
      } else {
        setRefundMessage(message);
        setRefundMessageType("error");
      }
      setRefundCompleted(true);
    } catch (error) {
      setRefundMessage("An unexpected error occurred.");
      setRefundMessageType("error");
    } finally {
      await refreshIBOInfo();
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    if (refundMessageType === "error") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const [isSpecialNeedsModalOpen, setIsSpecialNeedsModalOpen] = useState(false);

  const handleAddSpecialNeedsClick = () => {
    setIsSpecialNeedsModalOpen(true);
  };
  const [specialNeedsMessage, setSpecialNeedsMessage] = useState(null);

  const { updateSpecialNeedsMutation } = useTicketSpecialNeeds();
  const [isLoading, setIsLoading] = useState(false);

  const [messageType, setMessageType] = useState("success"); // 'error' as another possible value
  const queryClient = useQueryClient();

  useEffect(() => {
    const refetchData = async () => {
      await queryClient.refetchQueries(
        `v1/events/view/events/sale/ibo/${iboInfo.iboNumber}`
      );
    };

    // Call refetchData function after closing the modal
    if (!isSpecialNeedsModalOpen) {
      refetchData();
    }
  }, [isSpecialNeedsModalOpen, iboInfo.iboNumber, queryClient]);

  const handleSpecialNeedsSave = async (updatedTicket) => {
    setSpecialNeedsMessage("");
    setIsLoading(true);
    try {
      const response = await updateSpecialNeedsMutation.mutateAsync({
        ticketId: updatedTicket.ticketId,
        adaValue: arrayToBitwise(updatedTicket.adaValues),
      });

      if (response.status === 200) {
        setSpecialNeedsMessage("Special needs successfully updated.");
        setMessageType("success");
      } else if (response.status === 409) {
        setSpecialNeedsMessage(
          "Conflict: Your changes conflict with existing data."
        );
        setMessageType("error");
      } else {
        setSpecialNeedsMessage(
          "Error updating special needs. Please try again later."
        );
        setMessageType("error");
      }
    } catch (error) {
      setSpecialNeedsMessage(
        "Error updating special needs. Please try again later."
      );
      setMessageType("error");
      console.error("Error updating special needs:", error);
    } finally {
      await refreshIBOInfo();
      setIsLoading(false);
    }
  };

  const handleSpecialNeedsCancel = () => {
    setSpecialNeedsMessage("");
    setIsSpecialNeedsModalOpen(false);
  };

  function bitwiseToArray(bitwise) {
    if (bitwise == null || bitwise == 0) {
      return [];
    }

    const bitwiseValues = [
      32768, // Accessible Seating
      131072, // Accessible Seating Companion Ticket
      4, // Hearing Amplification Device
      8, // American Sign Language Interpreter
      65536, // Hearing Accessible Seating Companion Ticket
      262144, // Nursing Mother
    ];

    return bitwiseValues.filter((value) => (bitwise & value) !== 0);
  }

  function arrayToBitwise(valuesArray) {
    return valuesArray.reduce((bitwise, value) => bitwise | value, 0);
  }

  return (
    <>
      {contactId != null && (
        <div className={styles.buttonGroups}>
          {checkedInName(ticket)}
          &nbsp;
          <Button onClick={() => onUncheckTicketClicked()}>Un-Check</Button>
          &nbsp;
          <Button onClick={() => printTicket()}>Print</Button>
          &nbsp;
          {ticket.statusId === 1 && (
            <Button onClick={handleAddSpecialNeedsClick}>
              <FontAwesomeIcon icon={faWheelchair} />
            </Button>
          )}
          {isSpecialNeedsModalOpen && (
            <div className="modalBackdrop">
              <SpecialNeedsModal
                ticket={{
                  ...ticket,
                  adaValues: bitwiseToArray(ticket.adaValue),
                }}
                onSave={handleSpecialNeedsSave}
                onCancel={handleSpecialNeedsCancel}
                message={specialNeedsMessage}
                messageType={messageType}
                isLoading={isLoading}
                iboNum={iboInfo.iboNumber}
              />
            </div>
          )}
        </div>
      )}
      {contactId == null && (
        <div className={styles["dropleft"]}>
          <div
            className={
              styles["dropdown-menu"] + " " + (isCheckingIn && styles["show"])
            }
          >
            {users.map((a) => {
              if (shouldShowContact(a)) {
                return (
                  <a
                    key={a.userId}
                    className={styles["dropdown-item"]}
                    href="#"
                    onClick={() => {
                      onCheckInClicked(a);
                    }}
                  >
                    {a.strFirst} {a.strLast}
                  </a>
                );
              }
              return <></>;
            })}
          </div>
          <div className={styles["refund-container"]}>
            {/* <Button
              className={styles["ada-button"]}
              onClick={handleAddSpecialNeedsClick}
            >
              ADA
            </Button> */}
            <Button
              className={styles["dropdown-toggle"]}
              onClick={() => {
                setIsCheckingIn(!isCheckingIn);
              }}
            >
              Check In
            </Button>
            {hasPermission("developer.*") && (
              <Button
                className={styles["refund-button"]}
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                }}
              >
                {ticket.cost <= 0 ? "Cancel" : "Refund"}
              </Button>
            )}
          </div>
        </div>
      )}

      <RefundConfirmationModal
        isOpen={isModalOpen}
        onCollapse={() => {
          setIsModalOpen(!isModalOpen);
        }}
        onClose={handleCloseModal}
        onClick={onRefundTicket}
        isLoading={isLoading}
        refundCompleted={refundCompleted}
        applyFee={applyFee}
        setApplyFee={setApplyFee}
        refundMessage={refundMessage}
        refundMessageType={refundMessageType}
        ticketCost={ticket.cost}
      />

      {/* {isSpecialNeedsModalOpen && (
        <div className="modalBackdrop">
          <SpecialNeedsModal
            ticket={{
              ...ticket,
              adaValues: bitwiseToArray(ticket.adaValue),
            }}
            onSave={handleSpecialNeedsSave}
            onCancel={handleSpecialNeedsCancel}
            message={specialNeedsMessage}
            messageType={messageType}
            isLoading={isLoading}
            iboNum={iboInfo.iboNumber}
          />
        </div>
      )} */}
    </>
  );
}
