import PropTypes from "prop-types";
import styles from "./EventHostsCard.module.css";
import { HostCardInteractive } from "Events/Components/HostCardInteractive/HostCardInteractive";
import Card from "shared/Card";

/**
 *  Displays info about the different hosts
 */
export function EventHostsCard({ hosts, onClick }) {
  // return (
  const cardContents = (
    <>
      <div className={styles.container}>
        {/* <button className={styles.button} onClick={onClick}>
          Add
        </button> */}
        <div className={styles.cardHeader}>EVENT HOST(S)</div>
        <div className={styles.hr} />

        {hosts.map((item, index) => (
          <div className={styles.hostCard} key={index}>
            <HostCardInteractive
              host={{
                number: item.number,
                name: item.name,
                userName: item.userName,
                phoneNumber: item.phoneNumber,
                email: item.email,
                accessLevel: item.accessLevel,
              }}
              onClick={() => alert("Remove Host")}
            />
            <div className={styles.hr} />
          </div>
        ))}
      </div>
    </>
  );

  return <Card children={cardContents} />;
}

EventHostsCard.propTypes = {
  hosts: PropTypes.array,
  onClick: PropTypes.func,
};
