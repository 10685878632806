import React, { useState } from "react";
import SuggestionInput from "shared/@forms/SuggestionInput";
import Link from "shared/Link";
import NoSelection from "./NoSelection";
import { useEventSearch } from "../useEventSearch";
import Details from "../Details";
import styles from "./index.module.css";
import { useRouteMatch } from "react-router-dom";
import { Event, useEvent } from "Events/Components/useEvent";
import LoadingPage from "shared/LoadingPage";

export default function () {
  const { params } = useRouteMatch();
  const { eventId } = params;
  return (
    <Event.Provider initialState={eventId}>
      <LookupByEvent eventId={eventId} />
    </Event.Provider>
  );
}

function LookupByEvent({ eventId }) {
  const { event } = Event.useContainer();
  const [searchTerm, setSearchTerm] = useState("");
  const [editTerm, setEditTerm] = useState("");
  const { searchResults, isLoading } = useEventSearch(searchTerm);

  return (
    <div>
      <SuggestionInput
        value={editTerm}
        autoFocus={true}
        minLength={3}
        debounceTime={500}
        fetchSuggestions={setSearchTerm}
        suggestions={searchResults}
        isLoading={isLoading}
        placeholder="Search Events"
        onChange={(e) => {
          setEditTerm(e.target.value);
        }}
      >
        {(item, closePanel) => {
          const [label, rest] = ["Event", item.display];

          return (
            <div key={item.eventId} className={styles.eventListItem}>
              <div>
                <span>{label}:</span>{" "}
                <Link
                  to={`/events/manage/${item.eventId}`}
                  onClick={() => {
                    closePanel();
                  }}
                >
                  <span>{rest}</span>
                </Link>
              </div>
              <Link
                to={`/events/manage/${item.eventId}`}
                onClick={() => {
                  closePanel();
                }}
              >
                <span>View</span>
              </Link>
            </div>
          );
        }}
      </SuggestionInput>

      <div className={styles.details}>
        <DetailsErrorBoundary>
          {eventId ? (
            event ? (
              <Details eventInfo={event} />
            ) : (
              <LoadingPage text="Loading Event" />
            )
          ) : (
            <div className={styles.noSelection}>
              <NoSelection />
            </div>
          )}
        </DetailsErrorBoundary>
      </div>
    </div>
  );
}

class DetailsErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("caught in error boundary", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <h1>An error occurred when looking up this IBO's info.</h1>;
    }

    return this.props.children;
  }
}
