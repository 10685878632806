import { useLayoutEffect, useRef } from "react";

/**
 * Runs callback function when the element is done being resized.
 */
export function useResizeEnd(ref, callback) {
  const timeout = useRef(null);
  const func = useRef(callback);

  useLayoutEffect(() => {
    const handler = (e) => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }

      timeout.current = window.setTimeout(() => func.current(e), 200);
    };

    ref.current.addEventListener("resize", handler);

    return () => {
      ref.current.removeEventListener("resize", handler);
    };
  }, [ref]);
}
