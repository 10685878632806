import styles from "./index.module.css";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Just a table with some styles. If maxHeight is passed the table will be internally scrollable.
 */
export default function Table({ className, children, maxHeight, ...props }) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.scrollable]: maxHeight != null,
      })}
      style={maxHeight ? { maxHeight } : null}
    >
      <table
        className={classNames(styles.table, className, {
          [styles.scrollable]: maxHeight != null,
        })}
        {...props}
      >
        {children}
      </table>
    </div>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  maxHeight: PropTypes.string,
};
