import styles from "./index.module.css";
import Button from "shared/Button";
import Select from "shared/@forms/Select";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "react-ikonate";
import PropTypes from "prop-types";

/**
 * This component will sets up pagination for your page.
 */

export default function PagingControls({
  page,
  limit,
  limitOptions = [15, 25, 50],
  lastPage,
  onPageChange,
  onLimitChange,
  disableNext,
  disabled,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.limit}>
        {limit != null && (
          <>
            <Select
              disabled={disabled}
              value={limit}
              onChange={(e) => {
                onLimitChange(Number(e.target.value));
              }}
            >
              {limitOptions.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
            <span className={styles.pageSelectLabel}>items per page</span>
          </>
        )}
      </div>

      {page != null && (
        <div className={styles.pages}>
          {lastPage > 2 && (
            <Button
              className={styles.paddedButton}
              size="small"
              disabled={disabled || page <= 1}
              onClick={() => {
                onPageChange(1);
              }}
            >
              <div className={styles.buttonIcon}>
                <ChevronsLeft />
              </div>
            </Button>
          )}

          <Button
            size="small"
            disabled={disabled || page <= 1}
            onClick={() => {
              onPageChange(page - 1);
            }}
          >
            <div className={styles.buttonIcon}>
              <ChevronLeft />
            </div>
          </Button>

          <span className={styles.pageLabel}>
            Page {page}
            {lastPage != null && lastPage != 0 &&` of ${lastPage}`}
          </span>

          <Button
            className={lastPage > 2 && styles.paddedButton}
            size="small"
            disabled={disabled || disableNext}
            onClick={() => {
              onPageChange(page + 1);
            }}
          >
            <div className={styles.buttonIcon}>
              <ChevronRight />
            </div>
          </Button>

          {lastPage > 2 && (
            <Button
              size="small"
              disabled={disabled || disableNext}
              onClick={() => {
                onPageChange(lastPage);
              }}
            >
              <div className={styles.buttonIcon}>
                <ChevronsRight />
              </div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

PagingControls.propTypes = {
  /**
   * Controls what page you're on
   */
  page: PropTypes.number,
  /**
   * Changes the limit of items per page
   */
  limit: PropTypes.number,
  /**
   * Displays total amount of pages
   */
  lastPage: PropTypes.number,
  /**
   * Function to update the page
   */
  onPageChange: PropTypes.func,
  /**
   * Function to Control the limit of items per page
   */
  onLimitChange: PropTypes.func,
  /**
   * Disables next btn if true
   */
  disableNext: PropTypes.bool,
  /**
   * Disables user from choosing items per page and changing pages
   */
  disabled: PropTypes.bool,
  /**
   * Options for items per page
   */
  limitOptions: PropTypes.array,
};
