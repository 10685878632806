import React from "react";
import PropTypes from "prop-types";
import { ChevronLeft } from "react-ikonate";

import styles from "./Header.module.css";

/**
 * A component that displays a customizable header.
 * Children prop is used to pass in components.
 * May pass in multiple components surrounded by React fragments.
 * Back button will only display on non-"EVENTS" pages.
 */

export function Header({ title, onClick, children }) {
  return (
    <>
      <div
        onClick={onClick}
        className={`${styles.back} ${title === "EVENTS" && styles.none}`}
      >
        <ChevronLeft />
        Back
      </div>
      <div className={styles.container}>
        <h1 className={styles.header}> {title} </h1>
        {children && <div className={styles.children}>{children}</div>}
      </div>
    </>
  );
}

Header.propTypes = {
  // string used for header title
  title: PropTypes.string,

  // function called when back button is clicked
  onClick: PropTypes.func,
};
