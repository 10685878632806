import styles from "./CreateNewVenueModal.module.css";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { Modal } from "../../../shared/Modal";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
/**
 * An interactive component that displays the Create Venue Modal
 */

export function CreateNewVenueModal({ isOpen, onCollapse, onClick }) {
  const [newName, setNewName] = useState("");
  const [newAddress1, setNewAddress1] = useState("");
  const [newAddress2, setNewAddress2] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newZip, setNewZip] = useState("");
  const [newVenueURL, setNewVenueURL] = useState("");
  const [venuePhone, setVenuePhone] = useState("");
  const [venueFax, setVenueFax] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [venueURL, setVenueURL] = useState("");
  const [venueList, setVenueList] = useState([{ room: "", capacity: "" }]);
  const [contactList, setContactList] = useState([
    { contactName: "", phoneNumber: "", email: "", type: "" },
  ]);

  const onVenueRemove = (index) => {
    venueList.length > 1 &&
      setVenueList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    venueList.length === 1 && setVenueList(() => [{ room: "", capacity: "" }]);
  };

  const onVenueAdd = () => {
    setVenueList((arr) => [...arr, { name: "", capacity: "" }]);
  };

  const onContactRemove = (index) => {
    contactList.length > 1 &&
      setContactList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    contactList.length === 1 &&
      setContactList(() => [
        { contactName: "", phoneNumber: "", email: "", type: "" },
      ]);
  };

  const onContactAdd = () => {
    setContactList((arr) => [
      ...arr,
      { contactName: "", phoneNumber: "", email: "", type: "" },
    ]);
  };
  const [selectedCountryVenue, setSelectedCountryVenue] = useState("U.S.");
  const handleFormSubmittal = () => {
    const formData = {
      venueName: newName,
      address1: newAddress1,
      address2: newAddress2,
      city: newCity,
      state: newState,
      zip: newZip,
      venueURL: newVenueURL,
      venuePhone: venuePhone,
      venueFax: venueFax,
      venueEmail: venueEmail,
      venueURL: venueURL,
      venueList: venueList,
      contactList: contactList,
    };
    onClick(formData);
  };

  const handleCountrySelectionVenue = (country) => {
    setSelectedCountryVenue(country);
    // ... other logic
  };
  return (
    <Modal
      title="CREATE VENUE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Name"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 1"
                  placeholder=""
                  value={newAddress1}
                  onChange={(e) => {
                    setNewAddress1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 2"
                  placeholder=""
                  value={newAddress2}
                  onChange={(e) => {
                    setNewAddress2(e.target.value);
                  }}
                />
              </div>
              {/* <div className={styles.inputRow}> */}
              <div className={styles.radioCol}>
                <span className={styles.label}>Country </span>
                <span className={styles.radioButtonList}>
                  <RadioButtonList
                    onClick={handleCountrySelectionVenue}
                    options={["U.S.", "CDN", "ANZ", "JAMAICA"]}
                    defaultValue={selectedCountryVenue}
                    groupName="venueCountry"
                  />
                </span>
              </div>
              {/* </div> */}
              <div className={styles.inputRow}>
                <InputItem
                  label="City"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="State"
                  placeholder=""
                  value={newState}
                  onChange={(e) => {
                    setNewState(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Zip"
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Phone"
                  placeholder=""
                  value={venuePhone}
                  onChange={(e) => {
                    setVenuePhone(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Fax"
                  placeholder=""
                  value={venueFax}
                  onChange={(e) => {
                    setVenueFax(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Email"
                  placeholder=""
                  value={venueEmail}
                  onChange={(e) => {
                    setVenueEmail(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue URL"
                  placeholder=""
                  value={venueURL}
                  onChange={(e) => {
                    setVenueURL(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              {venueList.map((room, index) => (
                <div className={styles.inputRow} key={index}>
                  <InputItem
                    label="Room"
                    value={room.room}
                    placeholder=""
                    onChange={(e) => {
                      room.room = e.target.value;
                      setVenueList([...venueList]);
                    }}
                  />
                  <InputItem
                    label="Capacity"
                    value={room.capacity}
                    placeholder=""
                    onChange={(e) => {
                      room.capacity = e.target.value;
                      setVenueList([...venueList]);
                    }}
                  />
                  <div className={styles.removeBtn}>
                    <Button
                      className={styles.remove}
                      children="Remove"
                      onClick={() => onVenueRemove(index)}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.button}>
                <AddButton
                  color="#9AB5BB"
                  description="Add Another Room"
                  onClick={onVenueAdd}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <Button
                    className={styles.save}
                    children="SAVE VENUE"
                    onClick={() => handleFormSubmittal()}
                  />
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

CreateNewVenueModal.propTypes = {
  // Getter to determine if modal is displayed
  isOpen: PropTypes.bool,

  // Callback function for when the modal is closed
  onCollapse: PropTypes.func,

  // Function called when the save venue btn is clicked
  onClick: PropTypes.func,
};
