import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import EditTicketModal from "Events/Components/EditTicketModal";
import TicketGroupItem from "Events/Components/TicketGroupItem";
import Input from "shared/@forms/Input";
import Button from "shared/Button";
import { Select } from "shared/Select/Select";

import styles from "./TicketGroup.module.css";

/**Displays the information for a ticket group and all the ticket options. The user can update the number of tickets available. If edit is clicked in the sub header, then the limit and tickets per will be editable with a select and input box.  */
export function TicketGroup({
  name,
  available,
  setAvailable,
  limit,
  setLimit,
  ticketsPer,
  setTicketsPer,
  tickets,
  updateTickets,
  eventStartDate,
  isMajorV1 = false,
}) {
  const [showEditPurchaseLimits, setShowEditPurchaseLimits] = useState(false);
  const [ticketsPerField, setTicketsPerField] = useState("Tickets Per User");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingTicket, setEditingTicket] = useState({});

  const dateArray = eventStartDate;
  const eventDate = dayjs(dateArray);

  const handleEditOpen = (ticket) => {
    setEditingTicket({
      id: ticket.id,
      name: ticket.name,
      price: ticket.price.toString(),
      startDate: ticket.startDate,
      endDate: ticket.endDate,
      startTime: ticket.startTime,
      endTime: ticket.endTime,

      timeZone: ticket.timeZone,
    });

    setTimeout(() => {
      setShowEditModal(true);
    }, 0);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    setEditingTicket({});
  };

  const handleSaveEdit = (newTicket) => {
    // let startDate = parseStartDate(newTicket);
    // let endDate = parseEndDate(newTicket);

    const updatedTickets = tickets.map((ticket) => {
      if (ticket.id === newTicket.id) {
        return {
          ...ticket,
          name: newTicket.name,
          price: parseInt(newTicket.price),
          startDate: newTicket.startDate,
          startTime: newTicket.startTime,
          endDate: newTicket.endDate,
          endTime: newTicket.endTime,
          timeZone: newTicket.timeZone,
          // startDate: `${startDate.format("MM/DD/YYYY")}`,
          // endDate: `${endDate.format("MM/DD/YYYY")}`,
          //  startTime: `${newTicket.purchaseBegin.time}`,
          //  endTime: `${newTicket.purchaseEnd.time}`,
        };
      }
      return ticket;
    });

    updateTickets(updatedTickets);
    handleEditClose();
  };

  const parseStartDate = (newTicket) => {
    //   // eslint-disable-next-line default-case
    // switch (newTicket.purchaseBegin.length) {
    //   case "Days":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "day"
    //     );
    //   case "Weeks":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "week"
    //     );
    //   case "Months":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "month"
    //     );
    // }
  };

  const parseEndDate = (newTicket) => {
    //   // eslint-disable-next-line default-case
    //   switch (newTicket.purchaseEnd.length) {
    //     case "Days":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "day"
    //       );
    //     case "Weeks":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "week"
    //       );
    //     case "Months":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "month"
    //       );
    //   }
  };

  useEffect(() => {
    switch (limit) {
      case "Limit tickets per customer (IBO)":
        setTicketsPerField("Max Tickets Per Customer");
        break;
      default:
        setTicketsPerField("Tickets Per User");
        break;
    }
  }, [limit]);
  return isMajorV1 ? (
    // Layout for when isMajorV1 is true
    <div className={styles.ticketGroupContainer}>
      <div className={styles.header}>
        <span className={styles.label}>Ticket Group</span>
      </div>
      <div className={styles.body}>
        <div className={styles.ticketTableHeaderV1}>
          <span className={styles.ticketItem}>Ticket ID</span>
          <span>Ticket Name</span>
          <span className={styles.ticketPrice}>Price</span>
        </div>

        <div className={styles.ticketTableBody}>
          {tickets &&
            tickets?.map((ticket, index) => (
              <div className={styles.ticketTableRow} key={index}>
                <TicketGroupItem
                  ticket={ticket}
                  onEdit={handleEditOpen}
                  isMajorV1={isMajorV1}
                />
              </div>
            ))}
          <div className={styles.ticketTableRow}></div>
        </div>
      </div>
    </div>
  ) : (
    // Layout for when isMajorV1 is false
    <div className={styles.ticketGroupContainer}>
      <div className={styles.header}>
        <span className={styles.label}>Ticket Group</span>
        <span className={styles.name}>{name}</span>
        <span className={styles.ticketsAvailable}>Tickets Available</span>
        <span className={styles.ticketsAvailInput}>
          <span className={styles.numberSign}>#</span>
          <Input
            value={available}
            onChange={(e) => setAvailable(parseInt(e.target.value))}
            onFocus={(e) => e.target.select()}
          />
        </span>
      </div>
      <div className={styles.body}>
        {showEditPurchaseLimits ? (
          <div className={styles.purchaseEditContainer}>
            <span className={styles.label}>Purchase Limits</span>
            <span className={styles.purchaseLimitsSelect}>
              <Select
                options={[
                  "Limit tickets per each user",
                  "Limit tickets per customer (IBO)",
                ]}
                placeholder={limit}
                onChange={(value) => setLimit(value)}
              />
            </span>
            <span className={styles.ticketsPerUser}>{ticketsPerField}</span>
            <span className={styles.ticketsPerInput}>
              <Input
                value={ticketsPer}
                onChange={(e) => setTicketsPer(e.target.value)}
                placeholder="#"
              />
            </span>

            <Button
              size="small"
              onClick={() => setShowEditPurchaseLimits(false)}
              className={styles.editButton}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className={styles.purchaseEditContainer}>
            <span className={styles.label}>Purchase Limits</span>
            <span>{limit}</span>
            <span className={styles.ticketsPerUser}>{ticketsPerField}</span>
            <span className={styles.ticketsPerInput}>{ticketsPer}</span>

            <Button
              size="small"
              onClick={() => setShowEditPurchaseLimits(true)}
              className={styles.editButton}
            >
              Edit
            </Button>
          </div>
        )}
        <div className={styles.ticketTableHeader}>
          <span className={styles.ticketItem}>Ticket ID</span>
          <span>Ticket Name</span>
          <span className={styles.ticketPrice}>Price</span>
          <span>Purchase Begin</span>
          <span>Purchase End</span>
          <span>Time Zone</span>
          <span></span>
        </div>
        {editingTicket && (
          <div>
            <EditTicketModal
              isOpen={showEditModal}
              onCollapse={handleEditClose}
              ticketInfo={editingTicket}
              onClick={handleSaveEdit}
            />
          </div>
        )}

        <div className={styles.ticketTableBody}>
          {tickets?.map((ticket, index) => (
            <div className={styles.ticketTableRow} key={index}>
              <TicketGroupItem
                ticket={ticket}
                onEdit={handleEditOpen}
                isMajorV1={isMajorV1}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

TicketGroup.propTypes = {
  /**Displays the name in the header  */
  name: PropTypes.string,
  /**Number of tickets available. A cumulative number for all the tickets within the group */
  available: PropTypes.number,
  /**The user can choose between limiting tickets per user or per customer.  */
  limit: PropTypes.string,
  /**Number of tickets allowed for purchase. This will change between Tickets per user (for limit tickets per each user) and Max tickets per customer (for limit tickets per customer (IBO)).  */
  ticketsPer: PropTypes.string,
  /**Array of ticket objects*/
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      timeZone: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  /**Function to set the number of tickets that are available */
  setAvailable: PropTypes.func,
  /**Function to set the limit option */
  setLimit: PropTypes.func,
  /**Function to set the number of tickets allowed per purchase */
  setTicketsPer: PropTypes.func,
  /**Function that updates the ticket information */
  updateTickets: PropTypes.func,
  /**The date the event starts on */
  eventStartDate: PropTypes.string,
  isMajorV1: PropTypes.bool,
};
