import styles from "./ImportModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Modal } from "../../../shared/Modal/";
import { Select } from "shared/Select/Select";
import ImportList from "Events/Components/ImportList";
import { useImportEvents } from "./useImportEvents";
import Loader from "shared/Loader";
import { useHistory } from "react-router-dom";

export function ImportModal({ isOpen, onCollapse, onClick }) {
  const [selectedEventValue, setSelectedEventValue] = useState("");
  const [selectedEventLabel, setSelectedEventLabel] =
    useState("Select an event");
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const history = useHistory(); // Hook to get access to the history instance

  const onClose = () => {
    onCollapse();
    history.push("/events/events-details");
  };
  const eventOptions = [
    { label: "Spring Leadership", value: "2048" },
    { label: "Free Enterprise", value: "4096" },
    { label: "Family Reunion - Puryear", value: "8192" },
    { label: "Family Reunion - Erickson", value: "16384" },
    { label: "Family Reunion - Duncan", value: "32768" },
    { label: "Family Reunion - Baker", value: "2097152" },
    { label: "Local Functions", value: "131072" },
  ];

  const handleChangeEvent = (label) => {
    const option = eventOptions.find((option) => option.label === label);
    setSelectedEventValue(option?.value || "");
    setSelectedEventLabel(label);
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 8 }, (_, i) =>
    (currentYear - i).toString()
  );
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  const {
    eventsData: importedEvents,
    isLoading,
    refetch,
  } = useImportEvents({
    eventType: selectedEventValue,
    year: selectedYear,
  });
  const handleImportSuccess = () => {
    refetch(); // Refetch events data after successful import
  };

  return (
    <Modal
      title="IMPORT EVENTS"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
    >
      <>
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.flexContainer}>
              <div className={styles.labelSelectGroup}>
                <span className={styles.label}>Event Type</span>
                <Select
                  options={eventOptions.map((option) => option.label)}
                  onChange={handleChangeEvent}
                  placeholder={selectedEventLabel} // Use the updated label state here
                />
              </div>
              <div className={styles.labelSelectGroup}>
                <span className={styles.label}>Year</span>
                <Select
                  options={years}
                  onChange={handleYearChange}
                  placeholder={selectedYear}
                />
              </div>
            </div>
          </div>
          {selectedEventValue &&
            (isLoading ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <ImportList ImportList={importedEvents || []} refetch={refetch} />
            ))}
        </div>
      </>
    </Modal>
  );
}

ImportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};
